import React from 'react';
import {useNavigate} from 'react-router-dom';

function SubCategoryCard(props) {
    const navigate = useNavigate();

    //Redirection vers la sous-catégorie en la passant en params
    const redirectWithParams = (item) => {
        navigate('/resultat', {state:{categoryProduct:item.name}});
    }

    return (
        <>
            <a className="category-card-content" onClick={() => redirectWithParams(props.item)}>
                <div className="category-card-content-block">
                        <img alt={"Location" + props.item.name}
                            className="category-card-content-block-img"
                            src={props.item.image}
                        />
                        <p className="category-card-content-block-text">{props.item.name}</p>
                </div>
            </a>
        </>
    );
}

export default SubCategoryCard;
