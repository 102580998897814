import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from 'react'
import Loader from "../components/Loader";
import { useAuth } from '../ReactApp';
import { Accordion } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import Banner from "../components/Banner";
import WorksiteList from "../components/cards/WorksiteList";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { DateRange } from "react-date-range";
import locale from 'date-fns/locale/fr';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from 'moment';
import Autocomplete from "../components/Autocomplete";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders } from '@fortawesome/free-solid-svg-icons'
import btnClose from '../images/btn-close.jpg';
import { Capacitor } from "@capacitor/core";

const ResultWorksitePage = () => {
    const location = useLocation();
    const { user } = useAuth();

    //Liste de tous les produits
    const [worksiteResponse, setWorksiteResponse] = useState([]);

    //Titre affiché dans la bannière
    const [categoryName, setCategoryName] = useState('engin de chantier');
    const titleBanner = "À la recherche d'une <span class='banner-title-colored'>centrale ou d'un chantier pour rentabiliser</span> votre véhicule pour le béton ?";

    //Listes des produits filtrés
    const [filteredWorksites, setFilteredWorksites] = useState(worksiteResponse);

    //Localisation
    const [filterDistance, setFilterDistance] = useState(30);
    const [selectedCity, setSelectedCity] = useState(null !== location.state && location.state.selectedCity ? location.state.selectedCity : '');
    const [cityCoordinates, setCityCoordinates] = useState(null !== location.state && location.state.cityCoordinates ? location.state.cityCoordinates : ['', '']);
    const [dateState, setDateState] = useState(['']);
    const [filterDate, setFilterDate] = useState([{ key: "selection" }]);

    const [showDateRange, setShowDateRange] = useState(false);
    const dateRangeRef = useRef(null);

    const [showLocalisation, setShowLocalisation] = useState(false);
    const localisationRef = useRef(null);

    const [isOpenFilters, setIsOpenFilters] = useState(false);

    const handleOpenFilters = event => {
        setIsOpenFilters(current => !current);
        if (isOpenFilters) {
            document.body.style.overflow = 'initial';
        } else {
            document.body.style.overflow = 'hidden';
        }
    };

    //Filtres en cours
    const [currentFilters, setCurrentFilters] = useState([]);

    //Type de camion
    const [truckTypes, setTruckTypes] = useState([]);
    const [truckLoaded, setTruckLoaded] = useState(false);

    // Carte
    const [show, setShow] = useState(false);
    const icon = L.icon({
        iconUrl: process.env.REACT_APP_API_URL + "/images/vendor/leaflet/dist/marker-icon.svg",
        iconSize: [26, 34], // size of the icon
        shadowSize: [30, 40], // size of the shadow
        iconAnchor: [26, 34], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 35],  // the same for the shadow
        popupAnchor: [-3, -50] // point from which the popup should open relative to the iconAnchor
    });

    //Nombre de produit affiché
    const [nbDisplayedWorksite, setNbDisplayedWorksite] = useState(0)

    /* Cette fontion est appelé lorsque l'on coche les checkbox des filtres */
    const handleFilterCategory = (category, type, isChild, notEvent = false) => {
        let newArr = currentFilters;
        let filteredWorksite = [];
        let categoryName = "";
        let categoryId = "";

        if (typeof category !== 'undefined'
            && typeof category.target !== 'undefined'
            && typeof category.target.name !== 'undefined') {
                categoryName = category.target.name;
        } else if (typeof category !== 'undefined') {
            categoryName = category;
        }

        if (typeof category !== 'undefined'
            && typeof category.target !== 'undefined'
            && typeof category.target.id !== 'undefined') {
            categoryId = category.target.id;
        } else if (typeof category !== 'undefined') {
            categoryId = category;
        }

        if (notEvent) {
            categoryId = category.id;
            categoryName = category.name;
        }

        if (!newArr[type]) {
            newArr[type] = [];
        }

        if (type == 'camion-eco') {
            let checkbox = document.querySelector(`input[name="Camion écologique"]`);

            if (null !== checkbox) {
                let checkboxBio = document.querySelector(`input[name="Biocarburant"]`);
                let checkboxElec = document.querySelector(`input[name="Électrique"]`);

                if (checkbox.checked) {
                    checkboxBio.checked = true;
                    checkboxElec.checked = true;

                    // Vérifiez si newArr['fuels'] est déjà un tableau
                    if (!Array.isArray(newArr['fuels'])) {
                        // S'il ne s'agit pas d'un tableau, initialisez-le comme un tableau vide
                        newArr['fuels'] = [];
                    }

                    newArr['fuels'].push({ id: 1, name: 'Électrique' });
                    newArr['fuels'].push({ id: 3, name: 'Biocarburant' });
                } else {
                    checkboxBio.checked = false;
                    checkboxElec.checked = false;

                    for (var i = newArr['fuels'].length - 1; i >= 0; i--) {
                        if (newArr['fuels'][i].name === 'Électrique' || newArr['fuels'][i].name === 'Biocarburant') {
                          newArr['fuels'].splice(i, 1);
                        }
                    }
                }
            }
        } else {
            //On vérifie si il s'agit d'un élément parent ou enfant
            if (isChild) {
                const parent = truckTypes.find(item => item.subcateg.some(subitem => subitem.name === categoryName));
                let index = newArr[type].findIndex(object => object.name === parent.name);

                //On coche ou décoche un enfant
                if (typeof newArr[type][index] !== 'undefined') {
                    let found = false;
                    for (let i = 0; i < newArr[type].length; i++) {
                        let subcateg = newArr[type][i].subcateg;
                        for (let j = 0; j < subcateg.length; j++) {
                            if (subcateg[j].name === categoryName) {
                                let checkbox = document.querySelector(`input[name="` + categoryName + `"]`);
                                checkbox.checked = false;
                                subcateg.splice(j, 1);
                                found = true;
                                break;
                            }
                        }
                    }

                    //Aucun enfant est coché donc il faut décocher le parent
                    if (newArr[type][index].subcateg.length === 0) {
                        let checkbox = document.querySelector(`input[name="` + parent.name + `"]`);
                        checkbox.checked = false;
                        newArr[type].splice(index, 1);
                    }

                    //On coche l'enfant (pas de else car déjà décoché au dessus)
                    if (!found) {
                        newArr[type][index].subcateg.push({ id: categoryId, name: categoryName });
                    }

                    if (typeof newArr[type][index] !== 'undefined'
                        && parent.subcateg.length === newArr[type][index].subcateg.length) {
                        newArr[type][index].partial = false;
                        let checkbox = document.querySelector(`input[name="` + parent.name + `"]`);

                        if (null !== checkbox) {
                            checkbox.style.setProperty('--displaybefore', 'none');
                            checkbox.style.setProperty('--displayafter', 'block');
                        }
                    } else if (typeof newArr[type][index] !== 'undefined') {
                        newArr[type][index].partial = true;
                        let checkbox = document.querySelector(`input[name="` + parent.name + `"]`);

                        if (null !== checkbox) {
                            checkbox.style.setProperty('--displaybefore', 'block');
                            checkbox.style.setProperty('--displayafter', 'none');
                        }
                    }
                }
                //On coche un enfant sans que le parent ne soit coché
                else {
                    let checkbox = document.querySelector(`input[name="` + parent.name + `"]`);

                    if (null !== checkbox) {
                        checkbox.checked = true;
                        checkbox.style.setProperty('--displaybefore', 'block');
                        checkbox.style.setProperty('--displayafter', 'none');
                    }

                    let checkbox2 = document.querySelector(`input[name="` + categoryName + `"]`);

                    if (null !== checkbox2) {
                        checkbox2.checked = true;
                    }

                    newArr[type].push({ id: parent.id, name: parent.name, partial: true, subcateg: [] });
                    let index = newArr[type].findIndex(object => object.name === parent.name);

                    newArr[type][index].subcateg.push({ id: categoryId, name: categoryName });
                }
            }
            //On coche ou décoche un parent
            else {
                let index = newArr[type].findIndex(object => object.name === categoryName);
                const parent = truckTypes.find(item => item.name === categoryName);

                //index !== -1 indique que l'on décoche une case
                if (index !== -1) {
                    //on supprime la case que l'on vient de décocher du tableau
                    newArr[type].splice(index, 1);

                    //On s'assure que la case soit décochée
                    let checkbox = document.querySelector(`input[name="` + categoryName + `"]`);

                    if (null !== checkbox) {
                        checkbox.checked = false;
                    }

                    if (parent) {
                        //Si le parent contient des enfants alors on les décoches
                        parent.subcateg.map(function (item) {
                            let checkbox = document.querySelector(`input[name="` + item.name + `"]`);
    
                            if (null !== checkbox) {
                                checkbox.checked = false;
                            }
                        });
                    }
                }
                // Ici on coche une case parent
                else {
                    newArr[type].push({ id: categoryId, name: categoryName, partial: false, subcateg: [] });

                    if (parent) {
                        /* On coche tous les enfants */
                        parent.subcateg.map(function (item, i) {
                            let index = newArr[type].findIndex(object => object.name === categoryName);

                            let checkbox = document.querySelector(`input[name="` + item.name + `"]`);

                            if (null !== checkbox) {
                                checkbox.checked = true;
                            }

                            newArr[type][index].subcateg.push({ id: item.id, name: item.name });
                        });
                    }
    
                    //On écrase les éventuelles coches partielles
                    let checkbox = document.querySelector(`input[name="` + categoryName + `"]`);

                    if (null !== checkbox) {
                        checkbox.checked = true;
                        checkbox.style.setProperty('--displaybefore', 'none');
                        checkbox.style.setProperty('--displayafter', 'block');
                    }
                }
            }
        }

        //on ajoute le nouveau tableau aux filtres en cours
        setCurrentFilters(newArr);

        // Produits filtrés
        let listFilteredWorksite = getFilteredWorksite(filteredWorksite, worksiteResponse, currentFilters)

        localStorage.setItem('worksites', JSON.stringify(listFilteredWorksite));
    }

    /* Compare tous les produits avec les filtres en cours pour retourner les produits filtrés */
    const getFilteredWorksite = (filteredWorksite, worksiteResponse, currentFilters) => {
        for (let i = 0; i < worksiteResponse.length; i++) {
            let objTab = worksiteResponse[i];
            let categoryFound = false;

            if (typeof currentFilters.categories !== 'undefined') {
                for (let j = 0; j < currentFilters.categories.length; j++) {
                    let category = currentFilters.categories[j];
                    let trucks = JSON.parse(objTab.trucks);

                    for (let j = 0; j < Object.keys(trucks).length; j++) {
                        // Si c'est la catégorie et que l'annonce possède + que 0 véhicules
                        if (parseInt(Object.entries(trucks)[j][0]) == parseInt(category.id) &&
                            parseInt(Object.entries(trucks)[j][1]) > 0) {
                            categoryFound = true;
                            break;
                        }
                    }
                }

                if (currentFilters.categories.length == 0) {
                    categoryFound = true;
                }
            } else {
                // if categories filters are not present, consider all worksites in the category
                categoryFound = true;
            }

            if (categoryFound) {
                filteredWorksite.push(objTab);
            }
        }

        //Nombre de filtres
        let nbFilters = getNames(currentFilters).length;

        //Si il n'y a pas de filtres alors on affiche tous les produits
        if (nbFilters === 0 ||
            (nbFilters === 1 && /\d{2}\/\d{2}\/\d{4}/.test(getNames(currentFilters))) ||
            (nbFilters === 1 && getNames(currentFilters).some(item => item.includes('selectorCity')))
        ) {
            setFilteredWorksites(worksiteResponse);
        } else {
            setFilteredWorksites(filteredWorksite);
        }

        localStorage.setItem('nbFilters', JSON.stringify(nbFilters));

        return filteredWorksite;
    }

    /* Permet de récupérer l'id, le nom et le type en fonction du nom de l'objet */
    const getIdAndNameAndType = (name, obj) => {
        for (const category of obj.categories) {
            if (category.name === name) {
                return { id: category.id, name: category.name, type: 'categories' };
            }
            for (const subcateg of category.subcateg) {
                if (subcateg.name === name) {
                    return { id: subcateg.id, name: subcateg.name, type: 'categories' };
                }
            }
        }

        return null;
    }

    /* Cette fonction est appelé lorsque l'on clique sur la croix d'un filtre */
    const removeFilter = (e, item) => {
        if (/\d{2}\/\d{2}\/\d{4}/.test(item)) {
            setFilterDate([
                {
                    key: "selection",
                },
            ]);

            setCurrentFilters((currentFilters) => {
                const updatedFilters = { ...currentFilters };
                delete updatedFilters.date;
                return updatedFilters;
            });
        } else if (item.includes('selectorCity')) {
            setSelectedCity('');
            setFilterDistance(30);
            setShowLocalisation(false);
            setCurrentFilters((currentFilters) => {
                const updatedFilters = { ...currentFilters };
                delete updatedFilters.city;
                return updatedFilters;
            });
        } else {
            let isChild = isChildElement(item);

            item = getIdAndNameAndType(item, currentFilters)

            handleFilterCategory(item, item.type, isChild, true)
        }

    }

    /* Retire tous les filtres */
    const removeAllFilters = (e) => {
        getAllWorksites();

        setFilterDate([
            {
                key: "selection",
            },
        ]);

        setSelectedCity('');
        setFilterDistance(30);
        setShowLocalisation(false);

        let checkboxes = document.querySelectorAll(`input[type="checkbox"]`);

        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });

        setCurrentFilters([]);
    }

    /* Cette fonction est appelé lorsque l'on clique sur la croix d'une localisation */
    const removeCity = () => {
        setSelectedCity('')
        setFilterDistance(30);
        setShowLocalisation(false);
    }

    const validateCity = () => {
        setShowLocalisation(false);

        setCurrentFilters(currentFilters => {
            return {
                ...currentFilters,
                city: {
                    name: `<span style="display: none;">selectorCity</span>${selectedCity} ${filterDistance > 0 ? ` - ${filterDistance}Km` : ''}`,
                    __html: `<span style="display: none;">selectorCity</span>${selectedCity} ${filterDistance > 0 ? ` - ${filterDistance}Km` : ''}`
                }
            };
        });
    }

    const toggleDateRange = (event) => {
        event.stopPropagation();
        setShowDateRange(!showDateRange);
    };

    const handleDistanceChange = (value) => {
        setFilterDistance(value);
    };

    const handleLocalisationChange = (value) => {
        setSelectedCity(value.label);
        setCityCoordinates(value.geometry.coordinates);
        setShowLocalisation(true);
    };

    //Récupère les noms des filtres en cours (checkbox)
    const getNames = (obj) => {
        let names = [];

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (key === "name") {
                    names.push(obj[key]);
                } else if (typeof obj[key] === "object") {
                    names = names.concat(getNames(obj[key]));
                }
            }
        }

        //RETOURNER LES ID AUSSI
        return names;
    }

    const handleDisplayedWorksite = nbDisplayedWorksite => {
        setNbDisplayedWorksite(nbDisplayedWorksite);
    };

    // Récupération de tous les produits
    const getAllWorksites = async () => {
        await axios.post(process.env.REACT_APP_API_URL + "/v1/worksites", null, {})
            .then((response) => {
                if (response.data) {
                    setWorksiteResponse(response.data);
                    setFilteredWorksites(response.data);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (dateState != "") {
            setFilterDate(dateState)

            let nameFilter = (moment(dateState[0].startDate).isSame(dateState[0].endDate, 'day') ?
                `Le ${moment(dateState[0].startDate).format('DD/MM/YYYY')}` :
                `Du ${moment(dateState[0].startDate).format('DD/MM/YYYY')} au ${moment(dateState[0].endDate).format('DD/MM/YYYY')}`
            )

            setCurrentFilters(currentFilters => {
                return { ...currentFilters, date: { name: nameFilter } };
            });
        }
    }, [dateState]);

    //Permet de mettre la ville choisie dans les filtres depuis la homepage
    useEffect(() => {
        if (null !== location.state && location.state.selectedCity) {
            validateCity()
        }
    }, []);

    useEffect(() => {
        /*  BEGIN Récupération de tous les produits */
        getAllWorksites();
        /*  END Récupération de tous les produits */

        const getAllTruckTypes = async () => {
            axios.get(process.env.REACT_APP_API_URL + "/v1/getAllTruckTypes")
                .then((response) => {
                    setTruckTypes(response.data);
                    setTruckLoaded(true);
                }).catch((error) => {
                })
        };
        getAllTruckTypes();
    }, []);

    /* Cache la date au click en dehors du calendrier */
    useEffect(() => {
        function handleClickOutsideDate(event) {
            if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
                setShowDateRange(false);
            }
        }
        document.addEventListener('click', handleClickOutsideDate);
        return () => {
            document.removeEventListener('click', handleClickOutsideDate);
        };
    }, [dateRangeRef]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (localisationRef.current && !localisationRef.current.contains(event.target)) {
                setSelectedCity('')
                setFilterDistance(30);
                setShowLocalisation(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [localisationRef]);

    /* Détermine si il s'agit d'une catégorie enfant ou non */
    const isChildElement = (value) => {
        let found = false;

        for (const item of truckTypes) {
            for (const subitem of item.subcateg) {
                if (subitem.name === value) {
                    found = true;
                    break;
                }
            }
        }

        return found;
    }

    //Permet d'afficher les produits en fonction de la catégorie dans le state
    useEffect(() => {
        if (null !== location.state && location.state.categoryWorksite && truckLoaded) {
            let isChild = isChildElement(location.state.categoryWorksite);

            let item = { id: location.state.dataId, name: location.state.categoryWorksite, type: 'categories' };

            if (isChild) {
                handleFilterCategory(item, item.type, true, true)
            } else {
                handleFilterCategory(item, item.type, false, true)
            }
        }
    }, [truckLoaded]);

    return (
        <>
            <Banner titleBanner={titleBanner}></Banner>

            <div onClick={handleOpenFilters} className={isOpenFilters ? "search-result-page-bg-filters search-result-page-bg-filters-active" : "search-result-page-bg-filters"}>
            </div>

            <div className="mediumcontainer search-result-page">
                <div className="row justify-content-center mt-3">
                    <div
                        className={isOpenFilters ? 'col-xl-3 search-result-page-menu-lateral search-result-page-menu-lateral-actif' : 'col-xl-3 search-result-page-menu-lateral'}>
                        {/* Filtre en cours */}
                        <div>
                            <Link onClick={handleOpenFilters} className="btnClose d-xl-none" to="#">
                                <img src={btnClose} alt="" />
                            </Link>

                            <p className="title-filter" ><FontAwesomeIcon icon={faSliders} /> Filtres <span className="badge badge-filter">{getNames(currentFilters).length}</span></p>

                            <ul className="filter-current-list">
                                {getNames(currentFilters) ?
                                    getNames(currentFilters).map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                                <span
                                                    className="filter-current-list-cross"
                                                    onClick={(e) => removeFilter(e, item)}
                                                >
                                                    X
                                                </span>
                                            </li>
                                        );
                                    })
                                    : ''
                                }
                            </ul>
                            {getNames(currentFilters).length > 0 &&
                                <span className="filter-removeall" onClick={(e) => removeAllFilters(e)}>Tout désélectionner</span>
                            }
                        </div>

                        {/* Localisation */}
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="mb-0">Localisation</Accordion.Header>
                                <Accordion.Body className="filter-localisation-wrapper">
                                    <Autocomplete
                                        onLocalisationChange={handleLocalisationChange}
                                        name="cities"
                                        value={selectedCity}
                                        longitude={cityCoordinates[0]}
                                        latitude={cityCoordinates[1]}
                                    />
                                    {showLocalisation && (
                                        <div className="localisation-card" ref={localisationRef}>
                                            <span className="localisation-card-city">
                                                {selectedCity} {filterDistance > 0 ? ` - ${filterDistance}Km` : ''}
                                                <span
                                                    className="filter-current-list-cross"
                                                    onClick={() => removeCity()}
                                                >
                                                    X
                                                </span>
                                            </span>

                                            <p className="localisation-card-label">Dans un rayon de </p>
                                            <InputRange
                                                className="localisationè-card-range"
                                                step={10}
                                                maxValue={200}
                                                minValue={0}
                                                value={filterDistance}
                                                onChange={handleDistanceChange}
                                            />
                                            <div className="localisation-card-buttons">
                                                <div>
                                                    <span className="localisation-card-button erase" onClick={() => removeCity()}>
                                                        Effacer
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="localisation-card-button validate" onClick={() => validateCity()}>
                                                        Valider
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        {/* Date */}
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="mb-0">Date</Accordion.Header>
                                <Accordion.Body className="filter-date-wrapper">
                                    <input type="text"
                                        className="form-control date-text"
                                        onClick={toggleDateRange}
                                        value={filterDate[0].startDate ?
                                            (moment(filterDate[0].startDate).isSame(filterDate[0].endDate, 'day') ?
                                                `Le ${moment(filterDate[0].startDate).format('DD/MM/YYYY')}` :
                                                `Du ${moment(filterDate[0].startDate).format('DD/MM/YYYY')} au ${moment(filterDate[0].endDate).format('DD/MM/YYYY')}`
                                            )
                                            : "Choisir une date"
                                        }
                                    />
                                    {showDateRange && (
                                        <div ref={dateRangeRef}>
                                            <DateRange
                                                editableDateInputs={true}
                                                onChange={(item) => {
                                                    setFilterDate([item.selection]);
                                                    let nameFilter = (moment(item.selection.startDate).isSame(item.selection.endDate, 'day') ?
                                                        `Le ${moment(item.selection.startDate).format('DD/MM/YYYY')}` :
                                                        `Du ${moment(item.selection.startDate).format('DD/MM/YYYY')} au ${moment(item.selection.endDate).format('DD/MM/YYYY')}`
                                                    )

                                                    setCurrentFilters(currentFilters => {
                                                        return { ...currentFilters, date: { name: nameFilter } };
                                                    });

                                                }}
                                                moveRangeOnFirstSelection={false}
                                                ranges={filterDate[0] ? filterDate : []}
                                                locale={locale}
                                                className="date"
                                                minDate={new Date()}
                                            />
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        {/* Besoin du chantier */}
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="mb-0">Besoins du chantier</Accordion.Header>
                                <Accordion.Body>
                                    {truckTypes ?
                                        truckTypes.map((item, index) => {
                                            return (
                                                <div className="filter-checkbox parent" key={index}>
                                                    <input type="checkbox" id={item.id} name={item.name} onChange={(e) => handleFilterCategory(e, 'categories', false)} />
                                                    <label htmlFor={item.slug}>{item.name}</label>
                                                </div>
                                            );
                                        })
                                        : <Loader />
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                    <div className="col-xl-9">
                        <div className="search-result">
                            {nbDisplayedWorksite != 0 ?
                                (nbDisplayedWorksite == 1 ?
                                    <h1 className="text-result">Recherche
                                        : <br className="hide-desktop" />{nbDisplayedWorksite} résultat trouvé</h1>
                                    : <h1 className="text-result">Recherche
                                        : <br className="hide-desktop" />{nbDisplayedWorksite} résultats trouvés</h1>)
                                :
                                <h1 className="text-result">Aucun résultat pour votre recherche.</h1>
                            }
                            {/* <button type="submit" className="btn-maps" onClick={handleShow}>Voir la carte</button> */}
                        </div>
                        <p className="title-filter d-xl-none">
                            <div onClick={handleOpenFilters}>
                                <FontAwesomeIcon icon={faSliders} /> Filtres <span className="badge badge-filter">{getNames(currentFilters).length}</span>
                            </div>
                        </p>
                        {user ?
                            <>
                                <div className="row">
                                    <WorksiteList selectedCity={selectedCity}
                                        cityCoordinates={cityCoordinates && cityCoordinates}
                                        filterDate={filterDate}
                                        filterDistance={filterDistance}
                                        worksites={filteredWorksites}
                                        handleDisplayedWorksite={handleDisplayedWorksite}
                                        nbDisplayedWorksite={nbDisplayedWorksite}
                                    />
                                </div>
                            </>
                            :
                            <>

                                <div className="ads-need-connection">
                                    <div className="ads-need-connection-container">
                                        <p>
                                            {nbDisplayedWorksite <= 1 ? nbDisplayedWorksite + ' résultat correspondent à votre recherche'
                                                : nbDisplayedWorksite + ' résultats correspondent à votre recherche'}<br />
                                            Connectez-vous vite pour y accéder.
                                        </p>
                                        <div>
                                            {Capacitor.getPlatform() != 'ios' &&
                                                <a className="solu-btn solu-btn-primary" href='/inscription'>Je m'inscris</a>
                                            }
                                            <a className="links" href='/connexion'>Je me connecte</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row product-list-blur">
                                    <WorksiteList blur={true} selectedCity={selectedCity && selectedCity}
                                        cityCoordinates={cityCoordinates && cityCoordinates}
                                        filterDate={filterDate}
                                        filterDistance={filterDistance}
                                        worksites={filteredWorksites}
                                        handleDisplayedWorksite={handleDisplayedWorksite}
                                        nbDisplayedWorksite={nbDisplayedWorksite}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResultWorksitePage;
