import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage, useField, FieldArray } from "formik";
import { useAxios } from '../tools/useAxios';
import Autocomplete from "../Autocomplete";
import ImageUploading from 'react-images-uploading';
import { Calendar } from 'react-multi-date-picker';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import 'leaflet/dist/leaflet.css';
import AddPicture from '../../images/add-picture.png';
import lang_fr from "../calendar/lang_fr";
import { Button, Modal } from 'react-bootstrap';
import { useAuth } from '../../ReactApp';
import Worksites from './Worksites';

function WorksiteForm({ initialValues, validationSchema, props, isCreate, setBlockChild }) {
    const { errorLoc } = useState('');
    const [position, setPosition] = useState(props.worksite ? [props.worksite.latitude, props.worksite.longitude] : false);
    const [datesEdit, setDatesEdit] = useState(props.worksite ? JSON.parse(props.worksite.dates) : false);
    const [trucksEdit, setTrucksEdit] = useState(props.worksite ? JSON.parse(props.worksite.trucks) : false);
    const navigate = useNavigate();
    const { user } = useAuth();
    const [loadCategories, categories] = useAxios(process.env.REACT_APP_API_URL + "/v1/categories", 'post', {});

    const [showPopupDelete, setShowPopupDelete] = useState(false);
    const handlePopupDeleteClose = () => setShowPopupDelete(false);
    const handlePopupDeleteShow = () => setShowPopupDelete(true);

    const [images, setImages] = useState(props.worksite ? JSON.parse(props.worksite.image) : []);

    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const [selectedDates, setSelectedDates] = useState([]);

    const handleDateSelection = (dates) => {
        setSelectedDates(dates);
    };
    
    const [isChecked, setIsChecked] = useState(isCreate ? 1 : props.worksite.enabled);

    function handleCheckboxChange() {
        if (isCreate) {
            setIsChecked(!isChecked);
        } else {
            let axios_params = {
                'worksite_id': props.worksite.id
            };

            axios.post(process.env.REACT_APP_API_URL + "/v1/worksite/enable", axios_params)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data === 'not_confirmed') {
                            if (isChecked == 0) {
                                toast.warning("Votre chantier ne peut pas être en ligne tant que votre compte n'est pas activé.", {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                });
                            } else {
                                setIsChecked(0)
                            }
                        } else {
                            setIsChecked(!isChecked);
                        }
                    }
                });
        }
    }

    const icon = L.icon({
        iconUrl: process.env.REACT_APP_API_URL + "/images/vendor/leaflet/dist/marker-icon.svg",
        iconSize: [26, 34], // size of the icon
        shadowSize: [30, 40], // size of the shadow
        iconAnchor: [26, 34], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 35],  // the same for the shadow
        popupAnchor: [-3, -50] // point from which the popup should open relative to the iconAnchor
    });

    const handleSubmit = (values, action) => {
        const inputs = document.querySelectorAll('input[id^="trucktype-"]');
        const truckTypeValues = {};

        inputs.forEach(input => {
            const id = input.id;
            const valueAfterPrefix = id.split('trucktype-')[1];
            const value = parseInt(input.value, 10); // Convertit la valeur en nombre (int)
          
            if (!isNaN(value)) {
              truckTypeValues[valueAfterPrefix] = value;
            }
        });

        const jsonString = JSON.stringify(truckTypeValues);

        values = { ...values, user_id: props.user_id };

        const images_to_send = new Array();

        images.map((image) => (
            images_to_send.push(image.file)
        ));

        let data = new FormData();

        Object.keys(values).map(function (key, index) {
            if (key === "images") {
                for (let i = 0; i <= images_to_send.length; i++) {
                    data.append(`images` + [i], images_to_send[i])
                }
            } else if (key == "cities") {
                data.append('cities', document.getElementsByName("cities")[0].getAttribute('coordinates'))
            } else if (key == "dates") {
                data.append('dates', JSON.stringify(selectedDates))
            } else {
                data.append(key, values[key])
            }
        });
        
        // Nombre d'image pour faire une boucle dans le WorksiteController
        data.append('nbimages', images_to_send.length);
        data.append('enabled', isChecked == true ? 1 : 0);
        data.append('trucks', jsonString);
        data.append('user_id', user._id);

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        axios.post(process.env.REACT_APP_API_URL + "/v1/worksite/create", data, config)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    toast.success('Votre annonce a été ajoutée !', {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });

                    props.setBlockChild(<Worksites />)
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }

    const handleEditSubmit = (values, action) => {
        values = { ...values, user_id: props.user_id };

        const images_to_send = new Array();

        images.map((image) => (
            images_to_send.push(image.file ? image.file : image)
        ));

        let data = new FormData();

        Object.keys(values).map(function (key, index) {
            if (key === "images") {
                for (let i = 0; i <= images_to_send.length; i++) {
                    data.append(`images` + [i], images_to_send[i])
                }
            } else if (key == "cities") {
                data.append('cities', document.getElementsByName("cities")[0].getAttribute('coordinates'))
            } else if (key == "dates") {
                data.append('dates', JSON.stringify(selectedDates))
            }
        });

        // Nombre d'image pour faire une boucle dans le WorksiteController
        data.append('nbimages', images_to_send.length)

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        axios.post(process.env.REACT_APP_API_URL + "/v1/worksite/update/" + props.worksite.id, data, config)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    toast.success('Votre annonce a bien été modifiée !', {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        navigate("/profil", { state: { blockToDisplay: 'worksites' } })
    }

    const handleDeleteWorksite = (worksite) => (event) => {
        event.preventDefault();

        let axios_params = {
            'worksite_id': worksite.id
        };

        axios.post(process.env.REACT_APP_API_URL + "/v1/worksite/delete", axios_params)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    toast.success('Votre annonce est supprimée !', {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        handlePopupDeleteClose();

        let blockToDisplay = 'worksites';

        navigate("/profil", { state: { blockToDisplay } });
    }

    function handleChildCoordinates(childState) {
        const output = childState.map(num => num).reverse();

        setPosition(output);
    }

    const MyTextArea = ({label, ...props}) => {
        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
        // which we can spread on <input> and alse replace ErrorMessage entirely.
        const [field, meta] = useField(props);
        return (
            <>
                <label htmlFor={props.id || props.name}>{label}</label>
                <textarea className="text-area" {...field} {...props} />
                {meta.touched && meta.error ? (
                    <div className="error">{meta.error}</div>
                ) : null}
            </>
        );
    };


    return (
        <>
            <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={isCreate ? handleSubmit : handleEditSubmit}
            >
                {
                    ({ errors, touched, setFieldValue }) => {
                        return <Form>
                            <div className="row">
                                {errorLoc && <div className="text-danger"><small>{errorLoc}</small></div>}

                                <div className="form-group location-form-category">
                                    <h3 className='location-form-title-green'>Titre de l'annonce</h3>
                                </div>

                                <div className="col-12 col-md-3">
                                    <div className="form-group location-form-title">
                                        <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} disabled={isCreate ? false : true} />
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <hr />
                                </div>

                                <div className='col-12'>
                                    <div className="form-group location-form-category">
                                        <h3 className='location-form-title-green'>Type de camion souhaité</h3>
                                        <p className='location-form-text'>Pensez à mettre à jour votre annonce lorsque vous recherchez plusieurs camions et que l’un de vos besoins a été satisfait.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="row">
                                        {trucksEdit ?
                                            categories.map((item, index) =>
                                                <div key={index} className="col-6">
                                                    <div className="worksite-form-type">
                                                        <div className="worksite-form-type-truck">
                                                            <img src={item.image} />
                                                            <p className="worksite-form-type-truck-text">{item.name}</p>
                                                        </div>
                                                        <input className="worksite-form-type-input" value={trucksEdit[item.id]} type="number" id={'trucktype-' + item.id} name={'trucktype-' + item.id} min="0" max="100" />
                                                    </div>
                                                </div>
                                            )
                                        :
                                            categories.map((item, index) =>
                                                <div key={index} className="col-6">
                                                    <div className="worksite-form-type">
                                                        <div className="worksite-form-type-truck">
                                                            <img src={item.image} />
                                                            <p className="worksite-form-type-truck-text">{item.name}</p>
                                                        </div>
                                                        <input className="worksite-form-type-input" type="number" id={'trucktype-' + item.id} name={'trucktype-' + item.id} min="0" max="100" />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6"></div>

                                <div className="col-12">
                                    <hr />
                                </div>

                                <div className='col-12'>
                                    <div className="form-group location-form-category">
                                        <h3 className='location-form-title-green'>Date</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <Calendar
                                                range
                                                numberOfMonths={1}
                                                minDate={new Date()}
                                                locale={lang_fr}
                                                disableMonthPicker
                                                disableYearPicker
                                                weekStartDayIndex={1}
                                                name='dates'
                                                onChange={handleDateSelection}
                                                value={datesEdit ? [new Date(datesEdit[0]), new Date(datesEdit[1])]: false}
                                                className="worksiteCalendar"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <hr />
                                    <h3 className='location-form-title-green'>Localisation</h3>
                                    <p className='location-form-text'>Par défaut, l’adresse de votre entreprise est sélectionnée. Vous pouvez la modifier si ce n’est pas le cas.</p>

                                    <div className="location-form-map form-group">
                                        {isCreate ?
                                            <Autocomplete name="cities" coordinates={handleChildCoordinates} setFieldValue={setFieldValue} className={(errors.cities && touched.cities ? 'is-invalid' : '')} />
                                            :
                                            <Autocomplete name="cities" coordinates={handleChildCoordinates} setFieldValue={setFieldValue} latitude={props.worksite.latitude} longitude={props.worksite.longitude} className={(errors.cities && touched.cities ? 'is-invalid' : '')} />
                                        }
                                        <ErrorMessage name="cities" component="div" className="invalid-feedback" />
                                    </div>

                                    {position &&
                                        <MapContainer className="location-form-osmap" style={{ height: "400px" }} center={position} zoom='13' scrollWheelZoom={false}>
                                            <TileLayer
                                                url="http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                                                attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                minZoom="0"
                                                maxZoom="20"
                                            />

                                            <Marker position={position} icon={icon}></Marker>
                                        </MapContainer>
                                    }

                                    <hr />
                                    <div className="form-group worksite-form-description">
                                        <h3 className='location-form-title-green'>Descriptif</h3>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <MyTextArea name="description" type="textarea" rows="6" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} placeholder="Descriptif de l'annonce" disabled={isCreate ? false : true} />
                                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <hr />
                                    <h3 className='location-form-title-green'>Ajouter des photos</h3>

                                    <ImageUploading
                                        multiple
                                        value={images}
                                        onChange={onChange}
                                        maxNumber={maxNumber}
                                        dataURLKey="data_url"
                                        className='location-form-upload'
                                        capture="environment"
                                        accept="image/*"
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps
                                        }) => (
                                            // write your building UI
                                            <div className="location-form-upload">
                                                <button
                                                    className='btnActionUpload'
                                                    type="button" style={isDragging ? { color: "red" } : null}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                >
                                                    <img src={AddPicture} alt="" width="50" />
                                                </button>
                                                {/*
                                            <button className="btn btn-outline-danger" type="button" onClick={onImageRemoveAll}>Retirer toutes les images</button> -->
                                            */}
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <img src={image.data_url ? image.data_url : process.env.REACT_APP_API_URL + image} alt="" />
                                                        <div className="image-item__btn-wrapper">
                                                            <button className='btn btn-outline-secondary btn-sm' type="button" onClick={() => onImageUpdate(index)}>Modifier</button>
                                                            <button className='btn btn-outline-danger btn-sm' type="button" onClick={() => onImageRemove(index)}>Supprimer</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </div>

                            </div>
                            <div className='form-inputsgroup'>

                                <div className='container'>
                                    {isCreate == false ?
                                        <button type="submit" className='btn btn-primary' value="publish">Modifier l'annonce</button>
                                        :
                                        <button type="submit" className='btn btn-primary' value="publish">Enregistrer l'annonce</button>
                                    }

                                    <div className="form-check form-switch">
                                        <input className="form-check-input" checked={isChecked}
                                            onChange={handleCheckboxChange} type="checkbox" role="switch"
                                            name="enabled" id="flexSwitchCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Mettre en ligne</label>
                                    </div>

                                    {isCreate == false ?
                                        <span onClick={handlePopupDeleteShow} className='btn btn-outline-danger' value="delete">Supprimer l'annonce</span>
                                    :
                                        ''
                                    }
                                </div>

                            </div>
                        </Form>
                    }
                }
            </Formik>

            <Modal show={showPopupDelete} onHide={handlePopupDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Êtes-vous sûr de vouloir supprimer le chantier ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handlePopupDeleteClose}>
                        Annuler
                    </Button>
                    <Button type="submit" variant="primary" onClick={handleDeleteWorksite(props.worksite)}>
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WorksiteForm;