import React, { useEffect, useRef, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useAxios } from '../tools/useAxios';
import Autocomplete from "../Autocomplete";
import ImageUploading from 'react-images-uploading';
import { Calendar } from 'react-multi-date-picker';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import 'leaflet/dist/leaflet.css';
import AddPicture from '../../images/add-picture.png';
import AddFile from '../../images/add-file.png';
import lang_fr from "./../../components/calendar/lang_fr";
import { Button, Modal } from 'react-bootstrap';
import { useAuth } from '../../ReactApp';
import Products from './Products';
import '@splidejs/react-splide/css';

function TruckForm({ initialValues, validationSchema, props, isCreate, setBlockChild }) {
    const { errorLoc } = useState('');
    const [position, setPosition] = useState(props.product ? [props.product.latitude, props.product.longitude] : false);
    const navigate = useNavigate();
    const { user } = useAuth();
    const [isSmallScreen, setIsSmallScreen] = useState(true);
    const [uniqueKey, setUniqueKey] = useState(isCreate ? user.company_key + '-XXX' : (props.product ? props.product.name : ''));

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 768) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [showPopupDelete, setShowPopupDelete] = useState(false);
    const handlePopupDeleteClose = () => setShowPopupDelete(false);
    const handlePopupDeleteShow = () => setShowPopupDelete(true);

    const [images, setImages] = useState(props.product ? JSON.parse(props.product.image) : []);
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const [docs, setDocs] = useState(props.product ? JSON.parse(props.product.document) : []);
    const onChangeDocs = (docList, addUpdateIndex) => {
        // data for submit
        console.log(docList, addUpdateIndex);
        setDocs(docList);
    };

    let axios_params = { 'all': true };
    const [loadCategories, categories] = useAxios(process.env.REACT_APP_API_URL + "/v1/categories", 'post', axios_params);
    const [loadBrands, brands] = useAxios(process.env.REACT_APP_API_URL + "/v1/brands", 'post');

    const maxNumber = 69;

    const [selectedDates, setSelectedDates] = useState([]);

    const handleDateSelection = (dates) => {
        setSelectedDates(dates);
    };

    useEffect(() => {
        let params = { 'user_id': user._id };

        if (isCreate == false) {
            // Si c'est de l'édition de produit, récupération des reservations cochées 
            axios.get(process.env.REACT_APP_API_URL + '/v1/products/reservations/' + props.product.slug, params)
                .then((response) => {
                    const resp = response.data
                    const arrayDates = []
                    resp.map((item, index) =>
                        item.dates.map((mapItem, mapIndex) => {
                            if (!arrayDates.includes(mapItem)) {
                                arrayDates.push(mapItem)
                            }
                        })
                    );

                    setSelectedDates(arrayDates)
                }).catch((error) => {
                    console.log(error)
                })
        }

    }, [props.product, user])
    

    const [isChecked, setIsChecked] = useState(isCreate ? 1 : props.product.enabled);

    function handleCheckboxChange() {
        if (isCreate) {
            setIsChecked(!isChecked);
        } else {
            let axios_params = {
                'product_id': props.product.id
            };

            axios.post(process.env.REACT_APP_API_URL + "/v1/product/enable", axios_params)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data === 'not_confirmed') {
                            if (isChecked == 0) {
                                toast.warning("Votre camion ne peut pas être en ligne tant que votre compte n'est pas activé.", {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                });
                            } else {
                                setIsChecked(0)
                            }
                        } else if (response.data === 'error_quota') {
                            if (isChecked == 0) {
                                toast.warning("Vous avez atteint la limite d'ajout de véhicule pour votre pack !", {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                });
                            } else {
                                setIsChecked(0)
                            }
                        } else {
                            setIsChecked(!isChecked);
                        }
                    }
                });
        }
    }

    const icon = L.icon({
        iconUrl: process.env.REACT_APP_API_URL + "/images/vendor/leaflet/dist/marker-icon.svg",
        iconSize: [26, 34], // size of the icon
        shadowSize: [30, 40], // size of the shadow
        iconAnchor: [26, 34], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 35],  // the same for the shadow
        popupAnchor: [-3, -50] // point from which the popup should open relative to the iconAnchor
    });

    //Tableau qui contient l'id et le nom de la marque
    let brandsPresenter = [];
    brands.map((item, index) =>
        brandsPresenter.push({
            id: item.id,
            name: item.name
        })
    );

    //Tableau qui contient l'id, le nom et l'image de la categ pour l'autocomplete
    let categoriesPresenter = [];
    categories.map((item, index) =>
        categoriesPresenter.push({
            id: item.id,
            name: item.name,
            image: item.image,
            parent_id: item.id_parent
        })
    );

    let fuels = [
        {
            id: 1,
            title: 'Diesel'
        },
        {
            id: 2,
            title: 'Électrique'
        },
        {
            id: 3,
            title: 'Biocarburant'
        },
        {
            id: 4,
            title: 'Gaz'
        }
    ]

    const handleSubmit = (values, action) => {
        values = { ...values, user_id: props.user_id };

        const images_to_send = new Array();

        images.map((image) => (
            images_to_send.push(image.file)
        ));

        const docs_to_send = new Array();

        docs.map((doc) => (
            docs_to_send.push(doc.file)
        ));

        let data = new FormData();

        Object.keys(values).map(function (key, index) {
            if (key === "images") {
                for (let i = 0; i <= images_to_send.length; i++) {
                    data.append(`images` + [i], images_to_send[i])
                }
            } else if (key === "docs") {
                for (let i = 0; i <= docs_to_send.length; i++) {
                    data.append(`docs` + [i], docs_to_send[i])
                }
            } else if (key == "cities") {
                data.append('cities', document.getElementsByName("cities")[0].getAttribute('coordinates'))
            } else if (key == "dates") {
                data.append('dates', JSON.stringify(selectedDates))
            } else if (key == "enabled") {
                data.append('enabled', isChecked == true ? 1 : 0)
            } else if (key == "name") {
                data.append('name', uniqueKey)
            } else {
                data.append(key, values[key])
            }
        });

        // Nombre d'image pour faire une boucle dans le ProductController
        data.append('nbimages', images_to_send.length)
        data.append('nbdocs', docs_to_send.length)
        data.append('user_id', user._id);
        data.append('slug', user.uniqueKey);


        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        axios.post(process.env.REACT_APP_API_URL + "/v1/product/create", data, config)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    if (response.data.message == 'error_registration_exist') {
                        toast.warning("Cette immatriculation existe déjà dans notre base de données.", {
                            position: "top-center",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    } else {
                        toast.success('Votre produit a bien été ajouté !', {
                            position: "top-center",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });

                        navigate("/profil", { state: { blockToDisplay: 'product' } })
                    }
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        setBlockChild(<Products setBlockChild={setBlockChild} />)
    }

    const handleEditSubmit = (values, action) => {

        values = { ...values, user_id: props.user_id };

        const images_to_send = new Array();

        images.map((image) => (
            images_to_send.push(image.file ? image.file : image)
        ));

        const docs_to_send = new Array();

        docs.map((doc) => (
            docs_to_send.push(doc.file ? doc.file : doc)
        ));

        let data = new FormData();

        Object.keys(values).map(function (key, index) {
            if (key === "images") {
                for (let i = 0; i <= images_to_send.length; i++) {
                    data.append(`images` + [i], images_to_send[i])
                }
            } else if (key === "docs") {
                for (let i = 0; i <= docs_to_send.length; i++) {
                    data.append(`docs` + [i], docs_to_send[i])
                }
            } else if (key == "cities") {
                data.append('cities', document.getElementsByName("cities")[0].getAttribute('coordinates'))
            } else if (key == "dates") {
                data.append('dates', JSON.stringify(selectedDates))
            } else {
                data.append(key, values[key])
            }
        });

        // Nombre d'image pour faire une boucle dans le ProductController
        data.append('nbimages', images_to_send.length)
        data.append('nbdocs', docs_to_send.length)

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        axios.post(process.env.REACT_APP_API_URL + "/v1/product/update/" + props.product.id, data, config)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    if (response.data.message == 'error_quota_product') {
                        toast.warning("Vous avez atteint la limite d'ajout de véhicule pour votre pack !", {
                            position: "top-center",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.success('Votre produit a bien été modifié !', {
                            position: "top-center",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        navigate("/profil", { state: { blockToDisplay: 'product' } })
    }

    const handleDeleteProduct = (product) => (event) => {
        event.preventDefault();

        let axios_params = {
            'product_id': product.id
        };

        axios.post(process.env.REACT_APP_API_URL + "/v1/product/delete", axios_params)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    toast.success('Votre produit a bien été supprimé !', {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        handlePopupDeleteClose();

        let blockToDisplay = 'productDetail';

        navigate("/profil", { state: { blockToDisplay } });
    }

    const [subCategories, setSubCategories] = useState(false);

    const handleClickCategory = (id_categ) => {
        let params = { 'category_id': id_categ };

        axios.post(process.env.REACT_APP_API_URL + "/v1/categories", params)
            .then((response) => {
                if (response.data) {
                    let subCategoriesPresenter = [];

                    response.data.map((item, index) =>
                        subCategoriesPresenter.push({
                            id: item.id,
                            name: item.name,
                            image: item.image,
                            parent_id: item.id_parent
                        })
                    );

                    setSubCategories(subCategoriesPresenter);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    function handleChildCoordinates(childState) {
        const output = childState.map(num => num).reverse();

        setPosition(output);
    }


    return (
        <>
            <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={isCreate ? handleSubmit : handleEditSubmit}
            >
                {
                    ({ values, errors, status, touched, setFieldValue }) => {
                        return <Form>
                            {errorLoc && <div className="text-danger"><small>{errorLoc}</small></div>}

                            <div className="form-group location-form-category">
                                <h3 className='location-form-title-green'>Type de camion</h3>
                                <div className='location-form-category-elements'>
                                    {categoriesPresenter.map((item, index) =>
                                        (item.parent_id == 0) ?
                                            <div key={index}>
                                                {isCreate ?
                                                    <Field id={'truck_' + index} key={index} type="radio" name="category" value={'category_' + item.id}
                                                        {...initialValues.category_parent === 'category_' + item.id ? 'checked' : ''}
                                                        onClick={() => handleClickCategory(item.id)} />
                                                :
                                                    <Field id={'truck_' + index} key={index} type="radio" name="category" value={'category_' + item.id}
                                                        checked={initialValues.category_parent === 'category_' + item.id ? true : false}
                                                        disabled />
                                                }
                                                <label className='location-form-category-block' htmlFor={'truck_' + index}>
                                                    <div className='location-form-category-block-image'>
                                                        <img src={item.image} />
                                                    </div>
                                                    <p className='location-form-category-block-text'>{item.name}</p>
                                                </label>
                                            </div>
                                        :
                                            ''
                                    )}
                                </div>
                                <ErrorMessage name="category" component="div" className="invalid-feedback" />
                            </div>
                            <div className="clear"></div>
                            {errors.category ? <p className="text-danger">{errors.category}</p> : '' }

                            <h3 className='location-form-category-infos'>Dites-nous en plus !</h3>

                            {(subCategories && subCategories.length > 0) && (
                                <div className="form-group location-form-specificity">
                                    <label className='location-form-title' htmlFor="specificity">Spécificités</label>

                                    {subCategories.map((item, index) =>
                                        (item.parent_id != 0) ?
                                            <label key={index} className='location-form-specificity-choice' htmlFor={'specificity_' + item.id}>
                                                <Field id={'specificity_' + item.id} key={index} type="radio" name="specificity" value={"specificity_" + item.id}
                                                    {...initialValues.specificity === 'specificity_' + item.id ? 'checked' : ''} disabled={isCreate ? false : true} />
                                                {item.name}
                                            </label>
                                            : ''
                                    )}

                                    <ErrorMessage name="specificity" component="div" className="invalid-feedback" />
                                </div>
                            )}

                            <div className={'form-group location-form-fuel' + (errors.fuel && touched.fuel ? ' is-invalid' : '')}>
                                <label className='location-form-title' htmlFor="fuel">Carburant {initialValues.fuel ? ': ' + initialValues.fuel : ''}</label>
                                {isCreate &&
                                    (fuels.map((item, index) =>
                                        <label key={index} className='location-form-fuel-choice' htmlFor={'fuel_' + index}>
                                            <Field id={'fuel_' + index} key={index} type="radio" name="fuel" value={"fuel_" + item.id}
                                                {...initialValues.fuel === item.title ? 'checked' : ''}
                                                // disabled={isCreate ? false : true} 
                                                />
                                            {item.title}
                                        </label>
                                    ))
                                }
                            </div>
                            {errors.fuel ? <p className="text-danger">{errors.fuel}</p> : '' }

                            <div className="form-group location-form-brand">
                                <label className='location-form-title' htmlFor="brand">Marque</label>
                                <Field className={'form-select location-form-brand-list' + (errors.brand && touched.brand ? ' is-invalid' : '')} name="brand" as="select" placeholder="First Name" disabled={isCreate ? false : true}>
                                    <option value="" disabled selected>Sélectionner une marque</option>
                                    {brandsPresenter.map((item, index) =>
                                        <option key={"brand_" + index} value={item.id}
                                            {...initialValues.brand === item.id ? 'selected' : ''} >{item.name}</option>
                                    )}
                                </Field>
                                <ErrorMessage name="category" component="div" className="invalid-feedback" />
                            </div>

                            {errors.brand ? <p className="text-danger">{errors.brand}</p> : '' }

                            <hr />

                            <h3 className='location-form-title-green'>Identité du camion</h3>

                            <div className="form-group location-form-registration">
                                <label className='location-form-title' htmlFor="registration">Immatriculation</label>
                                <Field name="registration" type="text" className={'form-control' + (errors.registration && touched.registration ? ' is-invalid' : '')} placeholder="AA-000-AA" disabled={isCreate ? false : true} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>

                            {errors.registration ? <p className="text-danger">{errors.registration}</p> : '' }

                            <div className="form-group location-form-registration">
                                <Field name="name" type="hidden" 
                                className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                                value={isCreate ? uniqueKey : initialValues.name} disabled />
                            </div>

                            <hr />

                            <h3 className='location-form-title-green'>Disponibilité</h3>

                            <div className="form-group location-form-calendar">
                                <p className='location-form-text'>Par défaut, votre camion est disponible tout le temps. Sélectionnez vos dates pour ajouter des indisponibilités.</p>

                                {isSmallScreen ?
                                    <Calendar
                                    numberOfMonths={1}
                                    multiple
                                    minDate={new Date()}
                                    locale={lang_fr}
                                    disableMonthPicker
                                    disableYearPicker
                                    initialFocusedDate={new Date()}
                                    name='dates-mobile'
                                    onChange={handleDateSelection}
                                    value={selectedDates}
                                    className="dispoCalendar"
                                    />
                                :
                                    <Calendar
                                        fullYear
                                        multiple
                                        minDate={new Date()}
                                        locale={lang_fr}
                                        disableMonthPicker
                                        disableYearPicker
                                        weekStartDayIndex={1}
                                        name='dates'
                                        onChange={handleDateSelection}
                                        value={selectedDates}
                                        className="dispoCalendar"
                                    />
                                }
                            </div>

                            <hr />

                            <h3 className='location-form-title-green'>Quel est votre prix ?</h3>
                            <div className="location-form-price">
                                <div className="form-group">
                                    <Field name="price" type="number" min="0" placeholder="Prix" className={'form-control' + (errors.price && touched.price ? ' is-invalid' : '')} />
                                    <p>€/jour</p>
                                </div>
                                <ErrorMessage name="price" component="div" className="invalid-feedback" />
                            </div>
                            <p className='location-form-text'>Celui-ci doit être indiqué par jour.</p>

                            <hr />

                            <h3 className='location-form-title-green'>Localisation</h3>
                            <p className='location-form-text'>Par défaut, l’adresse de votre entreprise est sélectionnée. Vous pouvez la modifier si ce n’est pas le cas.</p>

                            <div className="location-form-map form-group">
                                {isCreate ?
                                    <Autocomplete name="cities" coordinates={handleChildCoordinates} setFieldValue={setFieldValue} className={(errors.cities && touched.cities ? 'is-invalid' : '')} />
                                    :
                                    <Autocomplete name="cities" coordinates={handleChildCoordinates} setFieldValue={setFieldValue} latitude={props.product.latitude} longitude={props.product.longitude} className={(errors.cities && touched.cities ? 'is-invalid' : '')} />
                                }
                                <ErrorMessage name="cities" component="div" className="invalid-feedback" />
                            </div>

                            {!position ? <p className="text-danger">La localisation est requise</p> : '' }

                            {position &&
                                <MapContainer className="location-form-osmap" style={{ height: "400px" }} center={position} zoom='13' scrollWheelZoom={false}>
                                    <TileLayer
                                        url="http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                                        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        minZoom="0"
                                        maxZoom="20"
                                    />

                                    <Marker position={position} icon={icon}></Marker>
                                </MapContainer>
                            }

                            <hr />

                            <h3 className='location-form-title-green'>Ajouter des photos</h3>

                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                                className='location-form-upload'
                                capture="environment"
                                accept="image/*"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps
                                }) => (
                                    // write your building UI
                                    <div className="location-form-upload">
                                        <button
                                            className='btnActionUpload'
                                            type="button" style={isDragging ? { color: "red" } : null}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                            <img src={AddPicture} alt="" width="50" />
                                        </button>
                                        {/*
                                    <button className="btn btn-outline-danger" type="button" onClick={onImageRemoveAll}>Retirer toutes les images</button> -->
                                    */}
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                                <img src={image.data_url ? image.data_url : process.env.REACT_APP_API_URL + image} alt="" />
                                                <div className="image-item__btn-wrapper">
                                                    <button className='btn btn-outline-secondary btn-sm' type="button" onClick={() => onImageUpdate(index)}>Modifier</button>
                                                    <button className='btn btn-outline-danger btn-sm' type="button" onClick={() => onImageRemove(index)}>Supprimer</button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>

                            <hr />

                            <h3 className='location-form-title-green' htmlFor="category">Documents administratifs</h3>
                            <p className='location-form-text'>
                                Nous vous invitons à nous transmettre les photocopies recto/verso de la carte grise du véhicule.<br/>
                                <strong>Vos documents ne seront pas diffusés sur la plateforme.</strong>
                            </p>

                            <ImageUploading
                                multiple
                                value={docs}
                                onChange={onChangeDocs}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                                accept="image/*,.pdf,.docx,.doc"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps
                                }) => (
                                    // write your building UI
                                    <div className="location-form-upload">
                                        <button
                                            className='btnActionUpload'
                                            type="button" style={isDragging ? { color: "red" } : null}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                        <img src={AddFile} alt="" width="50" />
                                    </button>
                                        {/*
                                        <button className="btn btn-outline-danger" type="button" onClick={onImageRemoveAll}>Retirer tous les documents</button>
                                        */}
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                                <img src={image.data_url ? image.data_url : process.env.REACT_APP_API_URL + image} alt="" />
                                                <div className="image-item__btn-wrapper">
                                                    <button className='btn btn-outline-secondary btn-sm' type="button" onClick={() => onImageUpdate(index)}>Modifier</button>
                                                    <button className='btn btn-outline-danger btn-sm' type="button" onClick={() => onImageRemove(index)}>Supprimer</button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>

                            <div className='form-inputsgroup'>

                                <div className='container'>
                                    {isCreate == false ?
                                        <button type="submit" className='btn btn-primary' value="publish">Modifier le véhicule</button>
                                        :
                                        <button type="submit" className='btn btn-primary' value="publish">Ajouter le véhicule</button>
                                    }

                                    <div className="form-check form-switch">
                                        <input className="form-check-input" checked={isChecked}
                                            onChange={handleCheckboxChange} type="checkbox" role="switch"
                                            name="enabled" id="flexSwitchCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Mettre en ligne</label>
                                    </div>

                                    {isCreate == false ?
                                        <span onClick={handlePopupDeleteShow} className='btn btn-outline-danger' value="delete">Supprimer le véhicule</span>
                                    :
                                        ''
                                    }
                                </div>

                            </div>
                        </Form>
                    }
                }
            </Formik>

            <Modal show={showPopupDelete} onHide={handlePopupDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Êtes-vous sûr de vouloir supprimer le produit ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Les réservations confirmées et les demandes d'offres seront toujours actives.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handlePopupDeleteClose}>
                        Annuler
                    </Button>
                    <Button type="submit" variant="primary" onClick={handleDeleteProduct(props.product)}>
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TruckForm;