import React from "react";
import headerBackground from '../images/header-page.png';

const Banner = (props) => {
    function replaceWithBr(text) {
        return text.replace(/\n/g, "<br />")
    }

    return (
        <>
            <div className={"banner-wrapper " + (props.bigBanner === true ? 'banner-wrapper-large' : '')} style={{backgroundImage: `url(` + headerBackground + `)`}}>
                <div className="container">
                    <h1 className="banner-title"
                        dangerouslySetInnerHTML={{__html: replaceWithBr(props.titleBanner)}}></h1>
                </div>
            </div>
        </>
    );
};

export default Banner;
