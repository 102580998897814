import React, { useState } from "react";
import { getDistance } from "../../services/CalculDistance";
import { Col } from "react-bootstrap";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import defaultTruck from '../../images/default-trucks/defaut-camion-malaxeur.png';
import { useAuth } from "../../ReactApp";
import axios from "axios";

const ProductList = props => {
    const { products, selectedCity, filterDistance, cityCoordinates, blur, filterPrice, filterCapacity, filterDate, handleDisplayedProduct } = props;
    let flagNbProduct = 0;
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        handleDisplayedProduct(flagNbProduct);
    }, [products, filterPrice, filterCapacity, filterDate, selectedCity, filterDistance])

    const handleClickToProduct = (date, product_path, blur) => {
        if (typeof blur != 'undefined') {
            navigate("#");
        } else {
            navigate(product_path, { state: { date } });
        }
    }

    const loadCity = async (latitude, longitude) => {
        try {
          const response = await axios.get(`https://api-adresse.data.gouv.fr/reverse/?lon=${longitude}&lat=${latitude}&type=municipality`);
          if (response.data.features[0].properties.label) {
            return response.data.features[0].properties.label;
          }
        } catch (error) {
          console.log(error);
        }
    };

    const renderProducts = products.map(({ name, price, registration, volume, latitude, longitude, image, product_path, date_unavailable, category_name, enabled }, index) => {
        const distance = getDistance(cityCoordinates[1], cityCoordinates[0], latitude, longitude);
        const imageProduct = image && JSON.parse(image).length != 0 ? process.env.REACT_APP_API_URL + JSON.parse(image)[0] : defaultTruck;

        if (enabled) {
            if (selectedCity == "" || (parseFloat(distance) <= filterDistance)) {
                if (filterPrice.min <= price && filterPrice.max >= price) {
                    if (filterCapacity.min <= volume && filterCapacity.max >= volume) {

                        // Vérifier si une des dates indisponibles est comprise entre filterDate[0].startDate et filterDate[0].endDate
                        for (let i = 0; i < date_unavailable.length; i++) {
                            const currentDate = new Date(date_unavailable[i]).toLocaleDateString('fr-FR', { month: 'short', day: 'numeric', year: 'numeric' });
                            const startDate = filterDate[0] ? new Date(filterDate[0].startDate).toLocaleDateString('fr-FR', { month: 'short', day: 'numeric', year: 'numeric' }) : false;
                            const endDate = filterDate[0] ? new Date(filterDate[0].endDate).toLocaleDateString('fr-FR', { month: 'short', day: 'numeric', year: 'numeric' }) : false;

                            if (startDate && currentDate >= startDate && endDate && currentDate <= endDate) {
                                return false;
                            }
                        }

                        flagNbProduct = flagNbProduct + 1;

                        return (
                            <Col xs={12} sm={12} md={6} lg={4} key={index}>
                                <div className='text-decoration-none search-result-page-cardcontainer' onClick={() => handleClickToProduct(filterDate, product_path, blur)} >
                                    <div className="card card-product">
                                        <div className="card-product-pic">
                                            <img src={blur ? defaultTruck : imageProduct} alt={name} />
                                        </div>
                                        <div className="card-product-body">
                                            <h5 className="card-product-name">{blur ? '***********' : name}</h5>
                                            <p className="card-product-category">
                                                {blur ? '***********' : category_name} - <span>{registration}</span>
                                            </p>
                                            {selectedCity ?
                                                <p className="card-product-localisation">
                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                    {distance > 0 ? ` ${distance}km de ` : ''} {selectedCity}
                                                </p>
                                             : ''}
                                            <div className="card-product-separation">
                                                <p className="card-product-presentation">À partir de <br /><span
                                                    className="card-product-price">{blur ? '***' : price}€/j</span></p>
                                                    {user && user.role == 'user' ?
                                                        <Link to={blur ? "#" : product_path} className="solu-btn solu-btn-sm solu-btn-primary">Je réserve</Link>
                                                    : '' }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        );
                    }
                }
            }

            if (index === products.length - 1) {
                handleDisplayedProduct(flagNbProduct);
            }
        }

    });

    return <>{renderProducts}</>;
};

export default ProductList;
