import React from 'react';
import { useAxios } from '../tools/useAxios';
import Loader from '../Loader';
import { useAuth } from '../../ReactApp';
import { Link } from 'react-router-dom';
import WorksiteCard from './cards/WorksiteCard';

const Worksites = (props) => {
    const { user } = useAuth();

    let axios_params = { 'user_id': user._id };

    const [loading, worksites] = useAxios(process.env.REACT_APP_API_URL + "/v1/worksites", 'post', axios_params);

    return (
        <div className="my-trucks">
            <div className="mediumcontainer">
                <div className="row">
                    <div className="col-12">
                        <div className="my-trucks-head">
                            <h2>Mes annonces</h2>
                            {user.role == 'user' &&
                                <Link   to="/profil"
                                        state={{ blockToDisplay: 'create_worksite' }}
                                        className="solu-btn solu-btn-primary solu-btn-add"
                                >Ajouter une annonce</Link>
                            }
                        </div>
                        <div className="row no-gutters">
                            {loading ? (<Loader />) :
                                (worksites && worksites.length > 0 ?
                                    worksites.map((item, index) =>
                                        <WorksiteCard key={index} item={item} setBlockChild={props.setBlockChild} />
                                    )
                                    :
                                    <div className='container'>
                                        <p>Vous n'avez actuellement aucune annonce.</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Worksites;
