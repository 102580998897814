import React from "react";
import Container from "react-bootstrap/Container";
import Banner from "../components/Banner";
import { useAxios } from "../components/tools/useAxios";
import Loader from "../components/Loader";
import CategoryCard from "../components/cards/CategoryCard";

function CataloguePage() {
    const [loading, categories] = useAxios(process.env.REACT_APP_API_URL + "/v1/categories");
    const titleBanner = "Camion malaxeur ou pompe à béton&nbsp;? <br/><span class='banner-title-colored'>Trouvez le véhicule idéal pour votre chantier.</span>";

    return (
        <>
            <Banner titleBanner={titleBanner}></Banner>
            <div className="bigcontainer">
                <div className="catalogue-section-truck">
                    <Container>
                        <div className="catalogue-section-truck-content">
                            {loading ? (<Loader />) :
                                (categories.map((item, index) =>
                                    <CategoryCard key={index} item={item} />
                                ))
                            }
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CataloguePage;
