import Banner from "../components/Banner";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import VisuelApplication from "../images/discover/VISUEL_MOCKUP_APP_SOLUSPOTS.png";
import PictoOptimisation from "../images/discover/PICTO_OPTIMISATION_SOLUSPOTS.svg";
import PictoAssurance from "../images/discover/PICTO_ASSURANCE_SOLUSPOTS.svg";
import PictoAppliSimple from "../images/discover/PICTO_APPLI_SIMPLE_SOLUSPOTS.svg";
import VisuelContact from "../images/discover/VISUEL_ILLU_CONTACT_LOUEUR_SOLUSPOTS.png";
import VisuelProfil from "../images/discover/VISUEL_ILLU_PROFIL_SOLUSPOTS.png";
import VisuelGererAnnonce from "../images/discover/VISUEL_ILLU_GERER_ANNONCE_SOLUSPOTS.png";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisuelPlusPopulaire from "../images/discover/VISUEL_LE_PLUS_POPULAIRE.png";

function DiscoverPage() {

    const titleBanner = "Vous avez des <span class='banner-title-colored'>camions</span> à mettre <span class='banner-title-colored'>à disposition ?</span> <br/> <span class='banner-title-colored'>Optimiser votre flotte !</span>";

    return (
        <>
            <Banner titleBanner={titleBanner} bigBanner={true}></Banner>

            <div className="mediumcontainer">
                <div className="discover-section-optimize">
                    <div className="discover-section-optimize-item">
                        <img className="discover-section-optimize-item-image" src={PictoOptimisation}
                             alt=""/>
                        <p className="discover-section-optimize-item-title"><span
                            className="discover-section-optimize-item-title-colored">Optimiser</span> votre flotte&nbsp;de&nbsp;camions
                        </p>
                    </div>
                    <div className="discover-section-optimize-separator"/>
                    <div className="discover-section-optimize-item">
                        <img className="discover-section-optimize-item-image" src={PictoAssurance} alt=""/>
                        <p className="discover-section-optimize-item-title"><span
                            className="discover-section-optimize-item-title-colored">Louez en toute tranquillité</span><br/> et
                            bénéficier d'avantages</p>
                    </div>
                    <div className="discover-section-optimize-separator"/>
                    <div className="discover-section-optimize-item">
                        <img className="discover-section-optimize-item-image" src={PictoAppliSimple}
                             alt=""/>
                        <p className="discover-section-optimize-item-title">Une application <span
                            className="discover-section-optimize-item-title-colored">simple</span> <br/> <span
                            className="discover-section-optimize-item-title-colored">d'utilisation</span></p>
                    </div>
                </div>
            </div>

            <div className="bigcontainer">
                <div className="discover-section-steps">
                    <Container>
                        <Row>
                            <div className="discover-section-steps-presentation">
                                <h2>Une solution <span className="discover-section-steps-presentation-title-colored">tout-en-un</span> pour piloter <br/> votre flotte de camions</h2>
                                <p>
                                  SoluSpots est une solution complète pour gérer votre flotte de camions de manière efficace et rentable.<br/>
                                  Planifiez, suivez et contrôlez votre flotte, sans avoir à recourir à plusieurs outils.
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} xl={4}>
                                <div className="discover-section-steps-item">
                                    <img className="discover-section-steps-item-image"
                                         src={VisuelProfil} alt=""/>
                                    <p className="discover-section-steps-item-title">Créez un profil <br/> facilement
                                    </p>
                                    <p className="discover-section-steps-item-subtitle"> Votre profil sera votre porte d’entrée pour <br /> toutes
                                      les fonctionnalités de notre <br /> plateforme.
                                    </p>
                                </div>
                            </Col>
                            <Col sm={12} md={6} xl={4}>
                                <div className="discover-section-steps-item">
                                    <img className="discover-section-steps-item-image"
                                         src={VisuelGererAnnonce} alt=""/>
                                    <p className="discover-section-steps-item-title">Gérez vos annonces
                                        depuis <br/> votre tableau de bord</p>
                                    <p className="discover-section-steps-item-subtitle">Publiez de nouvelles annonces, mettez à <br /> jour les
                                      annonces existantes, suivez les <br /> demandes de
                                      location et les réservations <br /> en temps réel.
                                    </p>
                                </div>
                            </Col>
                            <Col sm={12} md={12} xl={4}>
                                <div className="discover-section-steps-item">
                                    <img className="discover-section-steps-item-image"
                                         src={VisuelContact} alt=""/>
                                    <p className="discover-section-steps-item-title">Louez vos camions simplement <br/>
                                        et rapidement</p>
                                    <p className="discover-section-steps-item-subtitle">Avec notre système de messagerie <br /> intégré, vous
                                      pouvez facilement gérer les <br /> communications et les
                                      demandes de <br /> location.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="discover-section-rental">
                    <Container>
                        <Row>
                            <Col md={12} xl={6} className='align-self-end order-2 order-xl-1'>
                                <img src={VisuelApplication} alt="Camion loueurs - SoluSpot"/>
                            </Col>
                            <Col md={12} xl={6} className='order-1 order-xl-2'>
                                <div className="discover-section-rental-content">
                                    <h2 className="discover-section-rental-content-title"><span className="discover-section-rental-content-title-colored">Rentabilisez</span> votre flotte de camions dès la première utilisation&nbsp;!</h2>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} /> Mettez à disposition vos camions disponibles</p>
                                        <p className="discover-section-rental-content-block-text">Publiez facilement une annonce pour chacun de vos
                                          véhicules disponibles. Facilitez leur identification grâce à un pseudo.
                                        </p>
                                    </div>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} /> Choisissez votre prix</p>
                                        <p className="discover-section-rental-content-block-text">
                                            Déterminez le prix que vous souhaitez fixer pour la location de vos camions.
                                        </p>
                                    </div>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} /> Gagnez du temps</p>
                                        <p className="discover-section-rental-content-block-text">
                                            Avec son interface intuitive, la plateforme SoluSpots vous permet de gérer de manière efficace et rapide la location de vos véhicules.
                                        </p>
                                    </div>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} /> Pilotez tout depuis votre smartphone avec l'application</p>
                                        <p className="discover-section-rental-content-block-text">Contrôlez totalement votre flotte de véhicules, où que vous soyez : annonces, demandes de location, communication avec vos clients.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default DiscoverPage;