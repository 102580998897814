import React from 'react';
import { useAuth } from '../ReactApp';
import headerBackground from '../images/concrete-background-soluspots.png';
import LogoHeader from '../images/logos/LOGO_SOLUSPOTS_HEADER.png';
import { Link, useNavigate } from 'react-router-dom';
import ResetPasswordForm from '../components/forms/ResetPasswordForm';

function LoginPage(props) {
    const { onLogIn } = useAuth();
    const navigate = useNavigate();

    return (
        <>
            <div className='login-top' style={{backgroundImage: `url(` + headerBackground + `)`}}>
                <div className="login-top-image">
                    <Link to="/"><img src={LogoHeader} alt="" /></Link>
                </div>
            </div>
            <ResetPasswordForm {...props} create={true} navigate={navigate} logIn={onLogIn}/>
        </>
    );
}

export default LoginPage;
