import React from 'react';
import { useAxios } from '../tools/useAxios';
import Loader from '../Loader';
import ProductCard from './cards/ProductCard';
import { useAuth } from '../../ReactApp';
import { Link } from 'react-router-dom';

const Products = (props) => {
    const { user } = useAuth();

    let axios_params = { 'user_id': user._id };

    const [loading, products] = useAxios(process.env.REACT_APP_API_URL + "/v1/products", 'post', axios_params);


    return (
        <div className="my-trucks">
            <div className="mediumcontainer">
                <div className="row">
                    <div className="col-12">
                        <div className="my-trucks-head">
                            <h2>Mes camions</h2>
                            {user.role !== 'user' &&
                                <Link   to="/profil"
                                        state={{ blockToDisplay: 'create_product' }}
                                        className="solu-btn solu-btn-primary solu-btn-add"
                                >Ajouter un camion</Link>
                            }
                        </div>
                        <div className="row no-gutters">
                            {loading ? (<Loader />) :
                                (products && products.length > 0 ?
                                    products.map((item, index) =>
                                        <ProductCard key={index} item={item} setBlockChild={props.setBlockChild} />
                                    )
                                    :
                                    <div className='container'>
                                        <p>Vous n'avez ajouté aucun camion.</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
