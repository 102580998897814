import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import 'leaflet/dist/leaflet.css';

import WorksiteForm from './WorksiteForm';


function CreateWorksite(props) {

    return (
        <div className="create-product">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Mes annonces - Ajouter une annonce</h2>

                        <WorksiteForm
                            initialValues={{
                                name: '',
                                images: {},
                                cities: {},
                                dates: {},
                                enabled: ''
                            }}
                            validationSchema={Yup.object().shape({
                                name : Yup.string()
                                .required('Le nom de l\'annonce est requis.'),
                                cities: Yup.object()
                                    .required('La ville de l\'annonce est requise.'),
                            })}
                            props={props}
                            isCreate={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateWorksite;
