import React, { useState } from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import ContactForm from "../components/forms/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Capacitor } from "@capacitor/core";


function OffersPage() {
    const [searchTerm, setSearchTerm] = useState('');
    const [questionsCollapse, setQuestionsCollapse] = useState([false, false, false, false, false]); // Etat initial des collapse

    const questions = [
        { question: 'SoluSpots, c’est quoi ?', answer: 'SoluSpots simplifie la location de véhicule et la gestion de chantier.<br/>La plateforme offre un large choix de camions malaxeurs et pompes à béton disponibles à la location pour répondre aux besoins de chantier. Elle facilite la mise en relation des différents acteurs du secteur du béton.' },
        { question: 'Comment se connecter sur SoluSpots ?', answer: ' Une fois que vous êtes inscrit et que votre inscription a été confirmée, vous pouvez vous connecter sur soluspots.fr.<br/><br/>Pour cela :<ul><li>Rendez-vous sur la page d’accueil du site</li><li>Entrez votre identifiant et votre mot de passe</li><li>Puis cliquez sur Connexion</li></ul><br/>Astuce : assurez-vous de ne laisser aucun espace vide lorsque vous inscrivez ou copiez votre identifiant et/ou votre mot de passe.<br/>Si vous venez de vous inscrire et/ou que vous n’avez pas encore reçu d\'email de confirmation, vous ne pourrez pas vous connecter.'},
        { question: 'Comment modifier les informations de mon compte ?', answer: ' Connectez-vous et rendez-vous sur votre page "Mon compte" : vous aurez alors la possibilité de mettre à jour les informations communiquées lors de votre inscription.'},
        { question: 'Quels véhicules peuvent être mis en ligne sur SoluSpots ?', answer: 'Les différents types de camion liés au transporteur de béton :<ul><li>les camions malaxeurs,</li><li>les malaxeurs avec tapis,</li><li>les mixopompes,</li><li>les malaxeurs semi-remorques,</li><li>les camions écologiques (électrique/biocarburant/gaz).</li></ul>'},
        { question: 'Puis-je mettre plusieurs véhicules en ligne ?', answer: 'SoluSpots propose plusieurs niveaux d’abonnement pour répondre au mieux à vos besoins.<br/>Si vous souhaitez louer plusieurs véhicules, les formules premium et VIP sont faites pour vous ! <a href="/transporteur">Découvrez les ici !</a>'}
    ];

    // Filtre selon recherche de mots clés
    const filteredData = questions.filter(item =>
        item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Collapse ou non une question
    const toggleQuestion = (index) => {
        const updatedCollapseState = [...questionsCollapse];
        updatedCollapseState[index] = !updatedCollapseState[index];
        setQuestionsCollapse(updatedCollapseState);
    };

    return (
        <>
            <div className="container">
                <h1 className="contact-title">
                    Bienvenue sur la plateforme SoluSpots <br />
                    <span className="contact-title-colored">Nous répondons à toutes vos questions</span>
                </h1>

                <input className="contact-question-faq" type="text" placeholder="Que recherchez-vous ?" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

                {filteredData.map((item, index) => (
                    <div key={index}>
                        <h2 onClick={() => toggleQuestion(index)} className='contact-question-h2 product-h2-icon'>
                            {item.question} <span>{questionsCollapse[index] ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
                        </h2>
                        <Collapse in={questionsCollapse[index]}>
                        <span dangerouslySetInnerHTML={{ __html: item.answer }} />
                        </Collapse>
                    </div>
                ))}
            </div>

            <div className="bigcontainer">
                <div className="contact-section-form">
                    <Container>
                        <Row className="g-4">
                            <Col md={12} lg={6} xl={{'span':5, 'offset':1}}>
                                <div className="contact-section-form-content">

                                <h2 className="contact-section-form-content-title">
                                    Vous avez une question&nbsp;?<br />
                                        <span className="contact-section-form-content-title-colored">Écrivez-nous !</span>
                                    </h2>
                                    <div className="contact-section-form-content-block">
                                        <p className="contact-section-form-content-block-subtitle">
                                            Ou appelez-nous au 03 44 71 43 84
                                        </p>
                                        <p className="contact-section-form-content-block-text">
                                            En semaine de 9h à 18h - Appel gratuit
                                        </p>
                                    </div>
                                    <div className="contact-section-form-content-block">
                                        <p className="contact-section-form-content-block-subtitle">
                                            N’hésitez pas à nous suivre<br />
                                            sur les réseaux
                                        </p>
                                        <p className="contact-section-form-content-block-text">
                                            <a href="https://www.facebook.com/profile.php?id=100092581599269" target="_blank" className="contact-section-form-content-block-text-social">
                                                <FontAwesomeIcon icon={faFacebookF} />
                                            </a>
                                            <a href="https://www.linkedin.com/company/soluspots/" target="_blank" className="contact-section-form-content-block-text-social">
                                                <FontAwesomeIcon icon={faLinkedinIn} />
                                            </a>
                                            <a href="https://www.youtube.com/@soluspots" target="_blank" className="contact-section-form-content-block-text-social">
                                                <FontAwesomeIcon icon={faYoutube} />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} lg={6} xl={5}>
                                <ContactForm></ContactForm>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default OffersPage;
