import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from 'axios';

class Autocomplete extends Component {

  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      dataId: "",
      coordinates: (this.props.longitude && this.props.latitude) ? this.props.longitude + ',' + this.props.latitude : "",
      currentCity: ""
    };

  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ userInput: this.props.value });
    }
  }

  componentDidMount() {

    if (this.props.longitude && this.props.latitude) {
      axios.get("https://api-adresse.data.gouv.fr/reverse/?lon=" + this.props.longitude + "&lat=" + this.props.latitude + "&type=municipality")
        .then((response) => {
          if (response.data.features[0].properties.label) {
            this.setState({
              currentCity: response.data.features[0].properties.label
            });
          }
        }).catch((error) => {
          console.log(error)
        })
    }

    //Permet de fermer les suggestions lorsque l'on clique sur la page
    document.body.addEventListener('click', (e) => {
      let container = document.getElementsByClassName('suggestions');
    
      if (container[0] && !container[0].contains(e.target)) {
        this.setState(prevState => ({...prevState, showSuggestions: false}));
      }
    }, true);
  }

  // Récupération des villes pour remplir les options du select à partir de 3 caractères
  getCities(city) {
    if (city !== undefined && city.length >= 3) {
      axios.get("https://api-adresse.data.gouv.fr/search/?q=" + city + "&type=municipality")
        .then((response) => {
          response.data.features.forEach(function (obj) {
            obj.label = obj.properties.label + ' (' + obj.properties.postcode.slice(0, 2) + ')';
          });

          this.setState({
            filteredSuggestions: response.data.features,
            showSuggestions: true,
          });

        }).catch((error) => {
        })
    }
  }

  onChange = e => {
    if (this.props.name === "cities") {
      this.getCities(e.currentTarget.value);

      this.setState({
        userInput: e.currentTarget.value
      });
    } else {
      const { suggestions } = this.props;

      const userInput = e.currentTarget.value;

      // Filter our suggestions that don't contain the user's input
      const filteredSuggestions = suggestions.filter(
        suggestion =>
          suggestion[2].toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      this.setState({
        filteredSuggestions,
        showSuggestions: true,
        userInput: e.currentTarget.value
      });
    }
  };

  onClick = (event, city) => {
    this.setState({
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: event.currentTarget.innerText,
      dataId: event.currentTarget.getAttribute("data-id"),
      coordinates: city ? city.geometry.coordinates : ''
    });

    if (this.props.coordinates) {
      this.props.coordinates(city ? city.geometry.coordinates : '');
    }

    if (this.props.onLocalisationChange) {
      this.props.onLocalisationChange(city);
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        filteredSuggestions,
        showSuggestions,
        userInput,
        dataId,
        coordinates,
        currentCity
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              return (
                typeof this.props.name !== 'undefined' && this.props.name === "cities"
                  ?
                  (
                    <li key={suggestion.label} onClick={event => onClick(event, suggestion)}>
                      {suggestion.label}
                    </li>
                  )
                  :
                  (
                    <li data-id={suggestion[0]} key={suggestion[2]} onClick={onClick}>
                      {suggestion[2]}
                    </li>
                  )
              )
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>Aucun résultat</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input
          name={this.props.name}
          type="text"
          autoComplete="off"
          placeholder=
          {typeof this.props.name !== 'undefined' && this.props.name === "cities" ?
            (currentCity ? currentCity : "Rechercher une ville")
            :
            "Recherche"
          }
          className={'form-control ' + (this.props.className ? this.props.className : '')}
          onChange={onChange}
          onClick={() => {
            if (this.props.name === 'categories') {
              const { suggestions } = this.props;

              // Filter our suggestions that don't contain the user's input
              const filteredSuggestions = suggestions.filter(
                suggestion =>
                  suggestion[2]
              );

              this.setState({
                filteredSuggestions,
                showSuggestions: true,
                userInput: 'CHAINE_SECRETE'
              });
            }
          }
          }
          onKeyDown={onKeyDown}
          value={userInput !== 'CHAINE_SECRETE' ? userInput : ''}
          data-id={dataId}
          coordinates={coordinates}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
