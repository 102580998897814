import Banner from "../components/Banner";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import VisuelGagnezTemps from "../images/discover/SOLUSPOTS_GAGNEZ_TEMPS.png";
import VisuelDeposezAnnonces from "../images/discover/SOLUSPOTS_DEPOSEZ_ANNONCES_CENTRALES.png";
import PictoOptimisation from "../images/discover/trouvez_facilement_camion.png";
import PictoAssurance from "../images/discover/PICTO_ASSURANCE_SOLUSPOTS.svg";
import PictoAppliSimple from "../images/discover/PICTO_APPLI_SIMPLE_SOLUSPOTS.svg";
import VisuelContact from "../images/discover/SOLUSPOTS_BETONNIER_CONTACT.png";
import VisuelProfil from "../images/discover/SOLUSPOTS_BETONNIER_PROFIL.png";
import VisuelGererAnnonce from "../images/discover/SOLUSPOTS_BETONNIER_SPOTS.png";
import MacaronGratuit from "../images/soluspots-gratuit.svg";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Capacitor } from "@capacitor/core";

function BetonPage() {

    const titleBanner = "Vous recherchez un <span class='banner-title-colored'>camion malaxeur</span> pour votre centrale ou votre chantier ? ...<br/><span class='banner-title-colored'>Rien de plus simple ! Et tout est gratuit pour vous !</span>";

    return (
        <>
            <Banner titleBanner={titleBanner} bigBanner={true}></Banner>

            <div className="mediumcontainer">
                <div className="discover-section-optimize">
                    <div className="discover-section-optimize-item">
                        <img className="discover-section-optimize-item-image" src={PictoOptimisation}
                             alt=""/>
                        <p className="discover-section-optimize-item-title"><span
                            className="discover-section-optimize-item-title-colored">Trouvez rapidement</span><br/> des camions pour vos chantiers
                        </p>
                    </div>
                    <div className="discover-section-optimize-separator"/>
                    <div className="discover-section-optimize-item">
                        <img className="discover-section-optimize-item-image" src={PictoAssurance} alt=""/>
                        <p className="discover-section-optimize-item-title"><span
                            className="discover-section-optimize-item-title-colored">Louez </span><br/> en toute sécurité</p>
                    </div>
                    <div className="discover-section-optimize-separator"/>
                    <div className="discover-section-optimize-item">
                        <img className="discover-section-optimize-item-image" src={PictoAppliSimple}
                             alt=""/>
                        <p className="discover-section-optimize-item-title">Profitez d'une application<br/> <span
                            className="discover-section-optimize-item-title-colored">simple et intuitive</span></p>
                    </div>
                </div>
            </div>

            <div className="bigcontainer">
                <div className="discover-section-steps">
                    <Container>
                        <Row>
                            <div className="discover-section-steps-presentation">
                                <h2>Une solution <span className="discover-section-steps-presentation-title-colored">tout-en-un</span> pour trouver vos spots en béton</h2>
                                <p>
                                SoluSpots offre une solution complète, efficace et économique pour la recherche de véhicules<br/> adaptés au béton. Anticipez, planifiez et échangez facilement, sans avoir recours à plusieurs outils.</p>
                                {Capacitor.getPlatform() != 'ios' ?
                                    <a className="solu-btn solu-btn-primary mt-4 mb-4" href="/inscription" bis_skin_checked="1">J'en profite !</a>
                                : ''}
                            </div>
                        </Row>
                    </Container>
                </div>

                <div className="home-section-steps" style={{textAlign: 'center'}}>
                    <Container>
                        <h2><span>Trouver un spot</span> devient un jeu d'enfant</h2>
                        <Row className="mt-5 gx-5 justify-content-center">
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="discover-section-cards-second">
                                    <img className="discover-section-cards-second-free" src={MacaronGratuit} width="100" />
                                    <span className="discover-section-cards-second-number">01</span>
                                    <img className="discover-section-cards-second-img" src={VisuelProfil} />
                                    <p className="discover-section-steps-item-title">Créez un profil <br/> bétonnier facilement
                                    </p>
                                    <p className="discover-section-steps-item-subtitle">
                                        Votre profil sera votre porte d’entrée pour toutes les fonctionnalités de notre plateforme.<br/>&nbsp;
                                    </p>
                                </div>
                            </Col>
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="discover-section-cards-second">
                                    <img className="discover-section-cards-second-free" src={MacaronGratuit} width="100" />
                                    <span className="discover-section-cards-second-number">02</span>
                                    <img className="discover-section-cards-second-img" src={VisuelGererAnnonce} />
                                    <p className="discover-section-steps-item-title">Trouvez des spots pour vos chantiers</p>
                                    <p className="discover-section-steps-item-subtitle">
                                        Effectuez une recherche personnalisée grâce aux filtres et trouvez les véhicules adaptés à vos chantiers
                                    </p>
                                </div>
                            </Col>
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="discover-section-cards-second">
                                    <img className="discover-section-cards-second-free" src={MacaronGratuit} width="100" />
                                    <span className="discover-section-cards-second-number">03</span>
                                    <img className="discover-section-cards-second-img" src={VisuelContact} />
                                    <p className="discover-section-steps-item-title">Prenez contact avec les transporteurs</p>
                                    <p className="discover-section-steps-item-subtitle">
                                        Grâce à notre système de messagerie intégré, échangez facilement avec les transporteurs pour vos besoins de location.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        {Capacitor.getPlatform() != 'ios' ?
                            <a className="solu-btn solu-btn-primary mt-4" href="/inscription" bis_skin_checked="1">J'en profite !</a>
                        : ''}
                    </Container>
                </div>

                <div className="bigcontainer">
                    <div className="home-section-video">
                        <Container>
                            <h2><span>Comment</span> ça marche ?</h2>
                            <iframe src="https://www.youtube.com/embed/EdjtYBm8VMw?rel=0" title="SoluSpots - La plateforme qui facilite la vie de chantier !" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            {Capacitor.getPlatform() != 'ios' ?
                                <a className="solu-btn solu-btn-primary mt-4" href="/inscription" bis_skin_checked="1">J'en profite !</a>
                            : ''}
                        </Container>
                    </div>
                </div>

                <div className="discover-section-rental">
                    <Container>
                        <Row>
                            <Col md={12} xl={6} className='align-self-end order-2 order-xl-1'>
                                <img src={VisuelGagnezTemps} alt="Camion loueurs - SoluSpot"/>
                            </Col>
                            <Col md={12} xl={6} className='order-1 order-xl-2'>
                                <div className="discover-section-rental-content">
                                    <h2 className="discover-section-rental-content-title"><span className="discover-section-rental-content-title-colored">Gagnez du temps</span> pour le transport de votre béton</h2>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} /> Gagnez du temps pour le transport de votre béton</p>
                                        <p className="discover-section-rental-content-block-text">Affinez votre recherche de véhicule pour le béton grâce aux filtres avancés : type de véhicule, capacité de charge, lieux, disponibilité, tarifs, etc. Le moyen de vous assurer de disposer de l’équipement adapté à vos besoins.
                                        </p>
                                    </div>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} /> Anticipez vos besoins</p>
                                        <p className="discover-section-rental-content-block-text">
                                            Planifiez pour optimiser vos coûts, pour prendre en compte les contraintes logistiques et répondre rapidement à tout imprévu.
                                        </p>
                                    </div>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} /> Bénéficiez d’un large choix de véhicule pour votre béton</p>
                                        <p className="discover-section-rental-content-block-text">
                                            Camions toupies, malaxeurs, pompes à béton ou autres équipements spécialisés, découvrez un large choix de véhicules pour vos coulages.
                                        </p>
                                    </div>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} /> Pilotez tout depuis votre smartphone avec l’application</p>
                                        <p className="discover-section-rental-content-block-text">Contrôlez totalement votre flotte de véhicules, où que vous soyez : annonces, demandes de location, échanges avec vos clients.
                                        </p>
                                    </div>

                                    <a className="solu-btn solu-btn-primary mt-4 mb-4" href="/resultat" bis_skin_checked="1">J'en profite !</a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container className="py-5">
                    <Row>
                        <Col md={12} xl={6}>
                            <div className="discover-section-rental-content">
                                <img src={MacaronGratuit} width="100" />
                                <h2>Créez également <span className="discover-section-rental-content-title-colored">des annonces de centrales ou de chantiers !</span></h2>
                                <p className="home-section-steps-text">Grâce à notre plateforme dédiée, publiez des annonces sur mesure pour vos chantiers de construction.</p>
                                <p className="home-section-desc-content-block-text">Attirez l’attention des transporteurs, qui pourront vous proposer leurs services en fonction de vos critères. Vous trouverez ainsi rapidement des partenaires compétents, vous optimiserez la logistique de vos projets et garantirez la réussite de vos opérations de construction en béton.</p>
                                {Capacitor.getPlatform() === 'ios' ?
                                    <a className="solu-btn solu-btn-primary mt-4" href="/connexion" bis_skin_checked="1">Me connecter</a>
                                : ''}
                            </div>
                        </Col>
                        <Col md={12} xl={6} className='align-self-end order-2 order-xl-1'>
                            <img src={VisuelDeposezAnnonces} alt="Camion loueurs - SoluSpot"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default BetonPage;