import React,  { Component } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

class RequestForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorRequest: "",
            showPassword: false,
        };

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm (values, action) {
        axios.post(process.env.REACT_APP_API_URL + '/v1/request-register', values)
        .then((response) => {
            if (response.data && response.data.status === 'success') {
                toast.success("Vérifiez votre boîte mail ! Demande d'inscription envoyée.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            } else {
                this.setState({
                    errorRequest : response.data.message
                });
            }
        }).catch((error) => {
            this.setState({
                errorRequest : 'Erreur : Votre e-mail est invalide.'
            });
        })
    }

    render() {
        return (
            <div className='login-form'>
                <Link to={'/'} className='text-decoration-none d-xl-none d-lg-none d-md-none'>← Revenir à l'accueil</Link>
                <h1 className='login-title'>Inscription</h1>
                <Formik initialValues={{ email: '' }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email('L\'e-mail est invalide.')
                                .required('L\'e-mail est requis.'),
                        })}
                        onSubmit={(values, action) => {this.submitForm(values, action)}}
                >
                    {
                        ({ errors, status, touched, setFieldValue }) => {
                            return <Form>
                                {this.state.errorRequest && <div className="text-danger"><small>{this.state.errorRequest}</small></div>}
                                <div className="form-group mb-3">
                                    <Field id="email" name="email" type="text" placeholder="Adresse mail" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </div>
                                
                                <div className="form-group mt-3">
                                    <button type="submit" className="btn btn-primary w-100">Demande d'inscription</button>
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </div>
        );
    }
}

export default RequestForm;
