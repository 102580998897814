import React from "react";
import Container from 'react-bootstrap/Container';
import {Link} from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';
import RentalProduct from '../components/cards/RentalProduct';
import { useAuth } from '../ReactApp'

function OffersPage() {
    const { user } = useAuth();

    return (
        <>
            <Container className="p-5">
                <h1>Dernières offres de locations</h1>
                <div className="mb-3">
                    <Link to="#"><small>Faites votre demande +</small></Link>
                </div>

                <RentalProduct />

            </Container>
        </>
    );
}

export default OffersPage;
