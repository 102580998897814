const lang_fr = {
    name: "lang_fr",
    months: [
      ["Janvier", "Jan."],
      ["Février", "Fév."],
      ["Mars", "Mars"],
      ["Avril", "Avr.	"],
      ["Mai", "Mai"],
      ["Juin", "Juin"],
      ["Juillet", "Jui."],
      ["Août", "Août"],
      ["Septembre", "Sep."],
      ["Octobre", "Oct."],
      ["Novembre", "Nov."],
      ["Décembre", "Déc."],
    ],
    weekDays: [
      ["Samedi", "Sam"],
      ["Dimanche", "Dim"],
      ["Lundi", "Lun"],
      ["Mardi", "Mar"],
      ["Mercredi", "Mer"],
      ["Jeudi", "Jeu"],
      ["Vendredi", "Ven"]
    ],
    digits: ["0", "1", "2" ,"3", "4", "5", "6", "7", "8", "9"],
    meridiems:[
      ["AM","am"],
      ["PM","pm"]
    ]
  };
  
  export default lang_fr