import React, { useState, useCallback, useEffect  } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';

import './MessageBox.css';
import ErrorIcon from '../assets/errorIcon.svg';
import { labels } from '../labels';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const MessageBox = (props) => {
  // Tip: to identify if message is yours or not, use `left`. `left` means it's not your message
  const { type, timestamp, timestampFormat, buttons, left, author, hasError, text, authorFor, labels, detailsResa, index } = props;

  // FAIRE EN SORTE D'AFFICHER LA POPUP SELON KEY
  const [showDetails, setShowDetails] = useState({});
  const [acceptOfferPopup, setAcceptOfferPopup] = useState({});
  const [declineOfferPopup, setDeclineOfferPopup] = useState({});

  const handleDetailsClose = useCallback((index) => {
    setShowDetails({ ...showDetails, [index]: false });
  }, [showDetails]);

  const handleDetailsShow = useCallback((index) => {
    setShowDetails({ ...showDetails, [index]: true });
  }, [showDetails]);

  const handleDeclineClose = useCallback((index) => {
    setDeclineOfferPopup({ ...declineOfferPopup, [index]: false });
  }, [declineOfferPopup]);

  const handleDeclineShow = useCallback((index) => {
    setDeclineOfferPopup({ ...declineOfferPopup, [index]: true });
  }, [declineOfferPopup]);

  const handleAcceptClose = useCallback((index) => {
    setAcceptOfferPopup({ ...acceptOfferPopup, [index]: false });
  }, [acceptOfferPopup]);

  const handleAcceptShow = useCallback((index) => {
    setAcceptOfferPopup({ ...acceptOfferPopup, [index]: true });
  }, [acceptOfferPopup]);

  const handleResponseOffer = (status) => (event) => {
    event.preventDefault();

    let axios_params = {
      'id_resa' : detailsResa.id_resa,
      'status' : status
    };

    axios.post(process.env.REACT_APP_API_URL + "/v1/response-offer", axios_params)
        .then((response) => {
            if(response.status === 200 || response.status === 204) {
                toast.info(`L'offre a bien été ${status == "accepted" ? "acceptée" : "refusée"}`, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch((error) => {
            toast.error(error, {
                position: "top-center",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
        });
    });

    if (status === 'accepted') {
      handleAcceptClose(index);
    } else {
      handleDeclineClose(index);
    }
  }

  const [datesSorted, setDatesSorted] = useState(false);
    
  useEffect(()=> {
      if (detailsResa) {
        afficher_dates_consecutives(detailsResa);
      }
  }, [detailsResa])

  const afficher_dates_consecutives = (detailsResa) => {
      let result = "";
    
      const dates = detailsResa.dates;
      const nbDates = dates.length;
      dates.sort(); // Tri pour faciliter le parcours
      let dateStart = dates[0];
      let dateEnd = dates[0];
    
      for (let i = 1; i < nbDates; i++) {
        if (dates[i] === new Date(new Date(dateEnd).setDate(new Date(dateEnd).getDate() + 1)).toISOString().substring(0, 10)) {
          dateEnd = dates[i];
        } else {
          if (dateStart === dateEnd) {
              const formattedDateStart = new Date(dateStart);
              result += formattedDateStart.toLocaleDateString("fr") + ", ";
          } else {
              const formattedDateStart = new Date(dateStart);
              const formattedDateEnd = new Date(dateEnd);
              result += "Du " + formattedDateStart.toLocaleDateString("fr") + " au " + formattedDateEnd.toLocaleDateString("fr") + ", ";
          }
          dateStart = dates[i];
          dateEnd = dates[i];
        }
      }

      if (dateStart === dateEnd) {
          const formattedDateStart = new Date(dateStart);
          result += formattedDateStart.toLocaleDateString("fr") + ", ";
      } else {
          const formattedDateStart = new Date(dateStart);
          const formattedDateEnd = new Date(dateEnd);
          result += "Du " + formattedDateStart.toLocaleDateString("fr") + " au " + formattedDateEnd.toLocaleDateString("fr") + ", ";
      }
      
      if (result.endsWith(', ')) {
          result = result.slice(0, -2); // Suppression de la virgule et de l'espace
      }
    
      setDatesSorted(result);
  }


  if (type === 'text' || type === 'indicator' || type === 'offer' || type === 'file') {
    let time;
    if (timestamp) {
      if (timestampFormat === 'calendar') {
        time = moment(timestamp).calendar();
      } else if (timestampFormat === 'fromNow') {
        time = moment(timestamp).fromNow();
      } else {
        time = moment(timestamp).format(timestampFormat);
      }
    }

    const _buttons = buttons ?
      buttons.map((button, idx) => {
          if (button.type === 'URL') {
            return (
              <button
                key={idx}
                className="react-chat-message-button"
                onClick={() => props.onMessageButtonClick(button.payload)}
              >
                {button.title}
              </button>
            );
          } else if (button.type === 'ACCEPT-OFFER') {
            return (
              <button
                key={idx}
                className="react-chat-message-button message-button-accept"
                onClick={() => handleAcceptShow(index)}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
            );
          } else if (button.type === 'DECLINE-OFFER') {
            return (
              <button
                key={idx}
                className="react-chat-message-button message-button-decline"
                onClick={() => handleDeclineShow(index)}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            );
          }
        })
      : [];

    return (
      <>
        <div className={`react-chat-messageBox ${left ? 'react-chat-messageBoxLeft' : 'react-chat-messageBoxRight'}`} >
          {
            !author.avatarUrl ? null :
              <img alt="avater img" src={author.avatarUrl} className={`react-chat-avatar ${left ? 'react-chat-avatarLeft' : 'react-chat-avatarRight'}`} />
          }
          <div className={`react-chat-message ${left ? 'react-chat-messageLeft' : 'react-chat-messageRight'}`}>

            <div className="react-chat-additional">
              {author.username}
              {
                ((left === false) && authorFor) ?
                  <>{` ${labels.to?.toLowerCase() ?? '-'} ${authorFor.username}`}</>
                  : null
              }
              {
                authorFor &&
                <b id="direct-message">
                  {` (${labels.private})`}
                </b>
              }
            </div>

            <div className={`react-chat-bubble ${left ? 'react-chat-leftBubble' : 'react-chat-rightBubble'} ${hasError ? 'react-chat-bubbleWithError' : ''}`}>

              {type === 'indicator' && (
                <div className="react-chat-typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              )}

              {type ? (() => {
                if (type === 'file') {
                  if (text.endsWith(".jpg") || text.endsWith(".jpeg") || text.endsWith(".png")) {
                    return <img src={process.env.REACT_APP_API_URL + '/storage/' + text} />
                  } else {
                    return <button onClick={() => window.open(process.env.REACT_APP_API_URL + '/storage/' + text)}>Télécharger le document</button>;
                  }
                } else if(type === 'offer') {
                  return (
                    <>
                      {text} <strike><small>{detailsResa ? detailsResa.starting_price * detailsResa.dates.length + '€' : ''}</small></strike>
                    </>
                  );
                } else {
                  return (
                    <>
                      {text}
                    </>
                  );
                }
              })() : ''}


              {detailsResa ? (() => {
                switch (detailsResa.reservation_status) {
                  case 'pending':
                    return _buttons.length > 0 && (
                      <>
                        {detailsResa ? <><br /><small style={{cursor: 'pointer'}} onClick={() => handleDetailsShow(index)}>Voir le détail</small></> : ''}
                        <div className={left ? 'react-chat-message-buttonGroupLeft' : 'react-chat-message-buttonGroupRight'}>
                          {_buttons}
                        </div>
                      </>
                    )
                  case 'accepted':
                    return ' Votre location de véhicule est validée, envoyez votre RIB pour le règlement.'
                  case 'refused':
                    return ' Offre refusée'
                  default:
                    return ''
                }
              })() : ''}

              {hasError && <ErrorIcon className={`${left ? 'react-chat-errorLeft' : 'react-chat-errorRight'} react-chat-error`} />}

            </div>

            <div className="react-chat-additional">{time !== null && time}</div>

          </div>
        </div>

        <Modal show={showDetails[index]} onHide={() => handleDetailsClose(index)}>
          <Modal.Header closeButton>
            <Modal.Title>Offre pour le camion {detailsResa ? detailsResa.product : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Offre de réservation de {text} au lieu de <strike><small>{detailsResa ? detailsResa.starting_price * detailsResa.dates.length + '€' : ''}</small></strike>.<br/>
            Remise demandée : {detailsResa ? detailsResa.starting_price * detailsResa.dates.length - parseInt(text) + '€' : ''}.</p>
            <h4>Dates demandées :</h4>
            <p>{datesSorted ? datesSorted : ''}</p>
          </Modal.Body>
        </Modal>

        <Modal show={declineOfferPopup[index]} onHide={() => handleDeclineClose(index)}>
          <Modal.Header closeButton>
            <Modal.Title>Souhaitez-vous décliner l'offre ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Offre de réservation de {text} au lieu de <strike><small>{detailsResa ? detailsResa.starting_price * detailsResa.dates.length + '€' : ''}</small></strike>.<br/>
            Remise demandée : {detailsResa ? detailsResa.starting_price * detailsResa.dates.length - parseInt(text) + '€' : ''}.</p>
            <h4>Dates demandées :</h4>
            <p>{datesSorted ? datesSorted : ''}</p>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="danger" onClick={() => handleDeclineClose(index)}>
                Annuler
            </Button> */}
            <Button type="submit" variant="danger" onClick={handleResponseOffer('refused')}>
                Refuser l'offre
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={acceptOfferPopup[index]} onHide={() => handleAcceptClose(index)}>
          <Modal.Header closeButton>
            <Modal.Title>Souhaitez-vous accepter l'offre ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Offre de réservation de {text} au lieu de <strike><small>{detailsResa ? detailsResa.starting_price * detailsResa.dates.length + '€' : ''}</small></strike>.<br/>
            Remise demandée : {detailsResa ? detailsResa.starting_price * detailsResa.dates.length - parseInt(text) + '€' : ''}.</p>
            <h4>Dates demandées :</h4>
            <p>{datesSorted ? datesSorted : ''}</p>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="danger" onClick={() => handleAcceptClose(index)}>
                Annuler
            </Button> */}
            <Button type="submit" variant="primary" onClick={handleResponseOffer('accepted')}>
                Accepter l'offre
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  else if (type === 'notification') {
    return (
      <div className="text-center text-secondary react-chat-notification">
        {text}
      </div>
    );
  }
}

MessageBox.propTypes = {
  labels: PropTypes.object.isRequired
};

MessageBox.defaultProps = {
  labels: labels
}

export default MessageBox;
