import React, { useEffect } from 'react';
import axios from 'axios';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {Link} from 'react-router-dom';
import Loader from '../Loader';
import { useAuth } from '../../ReactApp';
import { useAxios } from '../tools/useAxios';

function RentalProduct(props) {
    const { user } = useAuth();
    const [loading, rentalProduct] = useAxios(process.env.REACT_APP_API_URL + "/v1/products", 'post', {'limit' : props.limit ? props.limit : ''});

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Premium</Popover.Header>
            <Popover.Body>
                Devenez <Link to="/premium/">premium</Link> et découvrez le tarif.
            </Popover.Body>
        </Popover>
    );

    return (
        <Row xs={1} md={2} className="g-4 pb-4"
        style={{
            minHeight: loading ? '220px' : 'initial'
          }}>
        {loading ? (
            <Loader />) :
            (rentalProduct ? (rentalProduct.map((item, index) =>
                <Col key={index}>
                    <Card>
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            {user ?
                                <div>
                                    <Card.Text>{item.price} € / 30min</Card.Text>
                                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                        <Link to="#"><Card.Text>Réserver un créneau</Card.Text></Link>
                                    </OverlayTrigger>
                                </div>
                            :
                                <div>
                                    <Card.Text>*** € / 30min</Card.Text>
                                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                        <Link to="#"><Card.Text>Réserver un créneau</Card.Text></Link>
                                    </OverlayTrigger>
                                </div>
                            }
                            <Card.Text>
                                {item.description}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                ))
                :
                ''
            )
        }
        </Row>
    );
}

export default RentalProduct;
