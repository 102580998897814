import React, { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import { useAxios } from '../components/tools/useAxios';
import defaultTruck from '../images/default-trucks/defaut-camion-malaxeur.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleUser, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import headerBackground from '../images/header-page.png';
import axios from "axios";

const CompanyPage = () => {
    const params = useParams();

    const [loadCompany, company] = useAxios(process.env.REACT_APP_API_URL + "/v1/company", 'get', params.id);
    const [loadProducts, products] = useAxios(process.env.REACT_APP_API_URL + "/v1/products/company", 'get', params.id);
    const [city, setCity] = useState(false);

    useEffect(() => {
        if (company.longitude && company.latitude) {
            axios.get("https://api-adresse.data.gouv.fr/reverse/?lon=" + company.longitude + "&lat=" + company.latitude + "&type=municipality")
              .then((response) => {
                if (response.data.features[0].properties.label) {
                  setCity(response.data.features[0].properties.label)
                }
              }).catch((error) => {
                console.log(error)
              })
          }
    }, [company])

    return (
        <>
            <div className="company-banner" style={{backgroundImage: `url(` + headerBackground + `)`}}>
                <div className="bigcontainer">
                    <div className="company-section">
                        <div className="company-section-explanations">
                            <img className='company-section-explanations-img' src={company.photo ? process.env.REACT_APP_API_URL + company.photo : ''} />
                            <div className="company-section-info">
                                <p className="company-section-explanations-name">{loadCompany ? (<Loader/>) : company.name}</p>
                                {city ?
                                    <p className="company-section-explanations-place"><FontAwesomeIcon icon={faLocationDot} /> {city}</p>
                                : ''}
                            </div>
                        </div>
                        <div className="company-section-check">
                            <div className="company-section-check-text">
                                <p><FontAwesomeIcon icon={faCheck}/> Transporteur vérifié</p>
                            </div>
                            <div className="company-section-check-text">
                                <p><FontAwesomeIcon icon={faBookmark}/> Membre depuis février 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bigcontainer">
                <h2>{loadProducts ? 0 : products.length} annonces</h2>
                <Row>
                    {loadProducts ? (<Loader />) :
                        (products.map((item, index) =>
                            <Col xs={12} sm={6} lg={4} xl={3} key={index}>
                                <Link className='text-decoration-none' to={item.slug}>
                                    <div className="card card-product">
                                        <div className="card-product-pic">
                                        <img src={item.image && JSON.parse(item.image).length != 0 ?
                                            process.env.REACT_APP_API_URL + JSON.parse(item.image)[0] : defaultTruck}
                                            alt={item.name} />
                                        </div>
                                        <div className="card-product-body">
                                            <h5 className="card-product-name">{item.name}</h5>
                                            <p className="card-product-category">
                                                {item.category_name}
                                            </p>
                                            <div className="card-product-separation">
                                                <p className="card-product-presentation">À partir de <br /><span
                                                    className="card-product-price">{item.price}€/j</span></p>
                                                <Link to={item.slug} className="solu-btn solu-btn-sm solu-btn-primary">Je réserve</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            )
                        )
                    }
                </Row>
            </div>
        </>
    );
};

export default CompanyPage;
