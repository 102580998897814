import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import 'leaflet/dist/leaflet.css';
import TruckForm from './TruckForm';
import { useAuth } from '../../ReactApp';
import axios from 'axios';


function UpdateProduct(props) {
    const {user} = useAuth();

    let initialData = { 
        name: props.product.name, 
        registration: props.product.registration,
        category: 'category_' + props.product.category_id,
        category_parent: 'category_' + props.product.category_id_parent,
        volume: 'volume_' + props.product.volume,
        fuel: props.product.fuel,
        brand: props.product.brand_id,
        price: props.product.price,
        images: props.product.image,
        docs: props.product.documents,
        cities: {},
        dates: {}
    }

    useEffect(() => {
        if (props.product.longitude && props.product.latitude) {
            axios.get("https://api-adresse.data.gouv.fr/reverse/?lon=" + props.product.longitude + "&lat=" + props.product.latitude + "&type=municipality")
            .then((response) => {
                initialData = { cities: response.data.features[0].properties.label}
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [props.product])


    return (
        <>
            <div className="create-product">
                <div className="container">
                    <h2>Mes camions - Modifier un camion</h2>

                    <TruckForm
                        initialValues={initialData}
                        validationSchema={Yup.object().shape({
                            // price: Yup.number()
                            //     .required('Le prix est requis.'),
                            // volume: Yup.string()
                            //     .required('Le volume est requis.'),
                            // fuel: Yup.string()
                            //     .required('Le carburant est requis.'),
                            // category: Yup.string()
                            //     .required('La catégorie est requise.'),
                            // cities: Yup.object()
                            //     .required('La ville du produit est requise.'),
                        })}
                        props={props}
                        setBlockChild={props.setBlockChild}
                        isCreate={false}
                    />
                </div>
            </div>
        </>
    );
}

export default UpdateProduct;
