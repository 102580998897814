import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/tools/ScrollToTop';
import { ReactApp } from './ReactApp';
import AuthProvider from "./context/AuthProvider";
import './sass/app.scss';

getComputedStyle(document.documentElement).getPropertyValue("--sat")

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Fragment>
    <Router>
      <AuthProvider> {/* Assurez-vous que AuthProvider englobe ReactApp */}
        <ScrollToTop />
        <ReactApp />
      </AuthProvider>
    </Router>
  </Fragment>
);
