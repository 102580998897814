import React from "react";
import Profil from "../images/discover/VISUEL_ILLU_PROFIL_SOLUSPOTS.png";
import {Link} from "react-router-dom";

function NotFoundPage() {
    return (
        <div className="row justify-content-center mt-3">
            <div className="col-md-12 text-center">
                <div className="container not-found">
                    <img className="not-found-img" src={Profil} alt="404"/>
                    <h1 className="not-found-title">404</h1>
                    <h2 className="not-found-subtitle">Page introuvable</h2>
                    <p className="not-found-text">La page que vous recherchez n'existe pas ou a été déplacée.</p>
                    <Link className="not-found-link btn btn-primary" to="/">Retour à l'accueil</Link>
                </div>
            </div>
        </div>
    );
}

export default NotFoundPage;
