import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

const ResetPasswordForm = (props) => {
    const [errorLogin, setErrorLogin] = useState();
    const navigate = useNavigate();

    const submitForm = (values, action) => {
        axios.post(process.env.REACT_APP_API_URL + '/v1/password/email', {
            email: values.email,
            create: true
          })
        .then((response) => {
            toast.success("E-mail de confirmation envoyé ! Vérifiez votre boite mail.", {
                position: toast.POSITION.BOTTOM_RIGHT
            })

            navigate('/connexion');
        }).catch((error) => {
            this.setState({
                errorLogin : 'Erreur : Votre e-mail et/ou votre mot de passe est invalide.'
            });
        })
    }

    return (
        <div className='login-form'>
                {props.create ?
                <>
                    <h1 className='login-title'>Création du mot de passe</h1>
                    <p className='login-text'>Confirmez votre e-mail afin de créer un nouveau mot de passe.</p>
                </>
                :
                    <>
                        <h1 className='login-title'>Changement du mot de passe</h1>
                        <p className='login-text'>Pas de souci, nous allons vous envoyer un mail pour le réinitialiser&nbsp;!</p>
                    </>
                }
            <Formik initialValues={{ email: '' }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('L\'e-mail est invalide.')
                            .required('L\'e-mail est requis.'),
                    })}
                    onSubmit={(values, action) => {submitForm(values, action)}}
            >
                {
                    ({ errors, status, touched, setFieldValue }) => {
                        return <Form>
                                {errorLogin && <div className="text-danger"><small>{errorLogin}</small></div>}
                                <div className="form-group mb-3">
                                    <Field id="email" name="email" type="text" placeholder="Votre adresse mail" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </div>
                                {props.create ?
                                    <div className="form-group mt-3">
                                        <button type="submit" className="btn btn-primary w-100">Créer un mot de passe</button>
                                    </div>
                                    :
                                    <>
                                        <div className="form-group mt-3">
                                            <button type="submit" className="btn btn-primary w-100">Réinitialiser le mot de passe</button>
                                        </div>
                                        <div className="form-group mt-3 bloc-inscription">
                                            <h3>Vous avez un compte&nbsp;?</h3>
                                            <Link to="/connexion"><button className="btn btn-outline-primary w-100">Connectez-vous maintenant</button></Link>
                                        </div>
                                    </>
                                }
                            </Form>
                    }
                }
            </Formik>
        </div>
    );
}

export default ResetPasswordForm;
