import { faLocationDot, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UpdateProduct from '../UpdateProduct';
import defaultWorksite from '../../../images/default-worksite/default-worksite.png';
import axios from 'axios';
import UpdateWorksite from '../UpdateWorksite';

function WorksiteCard(props) {
    const [city, setCity] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');

    useEffect(() => {
        if (JSON.parse(props.item.dates)[0] && JSON.parse(props.item.dates)[1]) {
            const startDate = new Date(JSON.parse(props.item.dates)[0]);
            const endDate = new Date(JSON.parse(props.item.dates)[1]);
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            };

            setDateStart(startDate.toLocaleString(undefined, options));
            setDateEnd(endDate.toLocaleString(undefined, options));
        }

        if (props.item.longitude && props.item.latitude) {
            axios.get("https://api-adresse.data.gouv.fr/reverse/?lon=" + props.item.longitude + "&lat=" + props.item.latitude + "&type=municipality")
              .then((response) => {
                if (response.data.features[0].properties.label) {
                  setCity(response.data.features[0].properties.label)
                }
              }).catch((error) => {
                console.log(error)
              })
          }
    }, [props])

    return (
        <>
            <div className="col-12 col-md-6 col-lg-4">
                <Link className='text-decoration-none' onClick={() => props.setBlockChild(<UpdateWorksite setBlockChild={props.setBlockChild} worksite={props.item} />)}>
                    <div className="card card-product">
                        <div className="card-product-pic">
                        <img src={props.item.image && JSON.parse(props.item.image).length != 0 ?
                            process.env.REACT_APP_API_URL + JSON.parse(props.item.image)[0] : defaultWorksite}
                            alt={props.item.name} />
                        </div>
                        <div className="card-product-body">
                            <h5 className="card-product-name">{props.item.name}</h5>
                            <span className="card-worksite-localisation">
                                <FontAwesomeIcon icon={faLocationDot} /> {city}
                            </span>
                            <p className="card-worksite-date">
                                <FontAwesomeIcon icon={faCalendar} /> {dateStart} - {dateEnd}
                            </p>
                            <div className="card-product-separation">
                                <Link   onClick={() => props.setBlockChild(<UpdateProduct setBlockChild={props.setBlockChild} product={props.item} />)}
                                        className="btn btn-sm btn-primary">Je modifie</Link>
                            </div>
                        </div>
                        {props.item.enabled ? '' :
                            <div className='card-product-unpublished'>
                                <div>
                                    <Link onClick={() => props.setBlockChild(<UpdateProduct setBlockChild={props.setBlockChild} product={props.item} />)}
                                            className="btn btn-secondary">Non publié</Link>
                                </div>
                                <div>
                                    <Link onClick={() => props.setBlockChild(<UpdateProduct setBlockChild={props.setBlockChild} product={props.item} />)}
                                            className="btn btn-sm btn-primary">Je modifie</Link>
                                </div>
                            </div>
                        }
                    </div>
                </Link>
            </div>
        </>
    );
}

export default WorksiteCard;
