import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UpdateProduct from '../UpdateProduct';
import defaultTruck from '../../../images/default-truck.png';
import axios from 'axios';

function ProductCard(props) {

    const [imagePrimary, setImagePrimary] = useState('');
    const [city, setCity] = useState('');

    useEffect(() => {
        if (props.item.image && JSON.parse(props.item.image)[0]) {
            setImagePrimary(process.env.REACT_APP_API_URL + JSON.parse(props.item.image)[0])
        } else {
            setImagePrimary(props.item.category_image);
        }
    }, []);

    useEffect(() => {
        if (props.item.longitude && props.item.latitude) {
            axios.get("https://api-adresse.data.gouv.fr/reverse/?lon=" + props.item.longitude + "&lat=" + props.item.latitude + "&type=municipality")
              .then((response) => {
                if (response.data.features[0].properties.label) {
                  setCity(response.data.features[0].properties.label)
                }
              }).catch((error) => {
                console.log(error)
              })
          }
    }, [props])

    return (
        <>
            <div className="col-12 col-md-6 col-lg-4">
                <Link className='text-decoration-none' onClick={() => props.setBlockChild(<UpdateProduct setBlockChild={props.setBlockChild} product={props.item} />)}>
                    <div className="card card-product">
                        <div className="card-product-pic">
                        <img src={props.item.image && JSON.parse(props.item.image).length != 0 ?
                            process.env.REACT_APP_API_URL + JSON.parse(props.item.image)[0] : defaultTruck}
                            alt={props.item.name} />
                        </div>
                        <div className="card-product-body">
                            <h5 className="card-product-name">{props.item.name}</h5>
                            <p className="card-product-category">
                                {props.item.category_name}
                            </p>
                            <p className="card-product-localisation">
                                <FontAwesomeIcon icon={faLocationDot} /> {city}
                            </p>
                            <div className="card-product-separation">
                                <p className="card-product-presentation">À partir de <br /><span
                                    className="card-product-price">{props.item.price}€/j</span></p>
                                <Link onClick={() => props.setBlockChild(<UpdateProduct setBlockChild={props.setBlockChild} product={props.item} />)}
                                        className="btn btn-sm btn-primary">Je modifie</Link>
                            </div>
                        </div>
                        {props.item.enabled ? '' :
                            <div className='card-product-unpublished'>
                                <div>
                                    <Link onClick={() => props.setBlockChild(<UpdateProduct setBlockChild={props.setBlockChild} product={props.item} />)}
                                            className="btn btn-secondary">Non publié</Link>
                                </div>
                                <div>
                                    <Link onClick={() => props.setBlockChild(<UpdateProduct setBlockChild={props.setBlockChild} product={props.item} />)}
                                            className="btn btn-sm btn-primary">Je modifie</Link>
                                </div>
                            </div>
                        }
                    </div>
                </Link>
            </div>
        </>
    );
}

export default ProductCard;
