import React, { useState } from "react";
import { getDistance } from "../../services/CalculDistance";
import { Col } from "react-bootstrap";
import { faCalendar, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import defaultWorksite from '../../images/default-worksite/default-worksite.png';
import { useAuth } from "../../ReactApp";

const WorksiteList = (props) => {
    const { worksites, selectedCity, filterDistance, cityCoordinates, blur, filterDate, handleDisplayedWorksite } = props;
    let flagNbWorksite = 0;
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        handleDisplayedWorksite(flagNbWorksite);
    }, [worksites, filterDate, selectedCity, filterDistance])

    function datesAreBetween(datesWorksite, filterDate) {
        if (filterDate[0].startDate && filterDate[0].endDate) {
            const startDateMs = new Date((datesWorksite[0] / 1000) * 1000).getTime();
            const endDateMs = new Date((datesWorksite[1] / 1000) * 1000).getTime();
            const date1Ms = filterDate[0].startDate.getTime();
            const date2Ms = filterDate[0].endDate.getTime();

            return startDateMs >= date1Ms && startDateMs <= date2Ms &&
                endDateMs >= date1Ms && endDateMs <= date2Ms;
        } else {
            return true;
        }
    }

    const renderWorksites = worksites.map(({ name, latitude, longitude, city, image, worksite_path, enabled, dates, trucks }, index) => {
        const datesWorksite = JSON.parse(dates);
        let datesWorksiteStart = new Date((datesWorksite[0] / 1000) * 1000);
        datesWorksiteStart = ('0' + datesWorksiteStart.getDate()).slice(-2) + '/' + ('0' + (datesWorksiteStart.getMonth() + 1)).slice(-2) + '/' + datesWorksiteStart.getFullYear();
        let datesWorksiteEnd = new Date((datesWorksite[1] / 1000) * 1000);
        datesWorksiteEnd = ('0' + datesWorksiteEnd.getDate()).slice(-2) + '/' + ('0' + (datesWorksiteEnd.getMonth() + 1)).slice(-2) + '/' + datesWorksiteEnd.getFullYear();
        const distance = getDistance(cityCoordinates[1], cityCoordinates[0], latitude, longitude);
        const imageWorksite = image && JSON.parse(image).length != 0 ? process.env.REACT_APP_API_URL + JSON.parse(image)[0] : defaultWorksite;

        if (enabled) {
            if (selectedCity == "" || (parseFloat(distance) <= filterDistance)) {
                // Vérifier si une des dates indisponibles est comprise entre filterDate[0].startDate et filterDate[0].endDate
                if (datesAreBetween(datesWorksite, filterDate)) {
                    flagNbWorksite = flagNbWorksite + 1;

                    return (
                        <Col xs={12} sm={12} md={6} lg={4} key={index}>
                           <Link to={blur ? "#" : worksite_path} className='text-decoration-none search-result-page-cardcontainer'>
                                <div className="card card-product">
                                    <div className="card-product-pic">
                                        <img src={blur ? defaultWorksite : imageWorksite} alt={name} />
                                    </div>
                                    <div className="card-product-body">
                                        <h5 className="card-product-name">{blur ? '***********' : name}</h5>
                                        {selectedCity ?
                                            <p className="card-product-localisation">
                                                <FontAwesomeIcon icon={faLocationDot} />
                                                {distance > 0 ? ` ${distance}km de ` : ''} {selectedCity}
                                            </p>
                                            :
                                            <p className="card-product-localisation">
                                                <FontAwesomeIcon icon={faLocationDot} />
                                                {` ${city}`}
                                            </p>
                                        }
                                        <p className="card-product-localisation">
                                            <FontAwesomeIcon icon={faCalendar} /> Du {datesWorksiteStart} au {datesWorksiteEnd}
                                        </p>
                                        <div className="card-product-separation">
                                            {user ?
                                                <Link to={blur ? "#" : worksite_path} className="solu-btn solu-btn-sm solu-btn-primary">Je contacte</Link>
                                                : '' }
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    );
                }
            }

            if (index === worksites.length - 1) {
                handleDisplayedWorksite(flagNbWorksite);
            }
        }

    });

    return <>{renderWorksites}</>;
};

export default WorksiteList;
