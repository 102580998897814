import React from "react";

function TermsOfSalesPage() {
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <h2>Conditions Générales de Vente</h2>
                    <p>Les   présentes   conditions   générales   de   vente   ont   pour   objet   de   définir   les obligations des parties et les conditions de vente des services et abonnements vendus sur le site https://soluspots.fr/</p>
                    <p>Tout achat sur ce site implique, de la part du client, l'adhésion sans réserve aux présentes conditions générales de vente.</p>
                    <p>Si l’une quelconque des dispositions des présentes CGV est annulée, en tout ou en partie, la validité des dispositions restantes n’en sera pas affectée. Dans ce cas, les parties devront, si possible, remplacer la disposition annulée par unedisposition valable correspondant à l’esprit et à l’objet du contrat.</p>
                    <p>Le   fait   que   l’une   des   parties   ne   revendique   pas   l’application   d’une   clausequelconque des présentes CGV ou acquiesce à son inexécution, que ce soit demanière   permanente   ou   temporaire,   ne   pourra   être   interprété   comme   unerenonciation par cette partie au bénéfice de ladite clause. </p>
                    
                    <h3>Services & abonnements vendus</h3>
                    <p>Toutes les informations et caractéristiques des services & abonnements vendus sur le site ainsi que les photographies, illustrations etc. sont communiquées à titre indicatif.</p>
                    <p>Le vendeur peut retirer, à tout un moment, un service et/ou un abonnement affiché sur le site.</p>
                    <p>Le client tient à la disposition du vendeur toutes les informations et documents pouvant contribuer et/ou nécessaires à la bonne réalisation des  services et abonnements.</p>
                    <p>Le délai d’intervention est donné à titre indicatif et ne constitue pas un délai de rigueur. En aucun cas, le vendeur ne pourra voir sa responsabilité engagée à l’égard du client en cas de retard dans la fourniture des commandes.</p>

                    <h3>Processus de commande </h3>
                    <p>Lors de la commande, le client s’engage à fournir des informations exactes et complètes.</p>
                    <p>Afin de pouvoir commander un abonnement ou un service sur le présent site, le client doit se créer un compte.</p>
                    <p>Chaque client ne peut ouvrir qu’un seul compte client. </p>
                    <p>Suite à son inscription, le client recevra un email l’informant que son compte est creé et en attente de sa validation. Dès sa validation, il recevra un email de confirmation d'activation de compte.</p>
                    <p>En aucun cas, le vendeur ne pourra être tenu responsable de toute perte, vol ou de toute utilisation frauduleuse du compte client.  Le client est ensuite invité à suivre et respecter le processus de commande en vigueur sur le site lors de sa commande.</p>
                    <p>Avant de valider sa commande, le client verra apparaître une page récapitulative reprenant   les   services   et   abonnements   sélectionnés,   les   références,   les quantités, le prix unitaire et le prix de la commande. Il s’engage à vérifier l’exactitude de la sélection avant de confirmer et de payer sa commande.</p>
                    <p>Suite à son règlement, il recevra un email de confirmation de la part du vendeur.</p>

                    <h3>Prix</h3>
                    <p>Les prix facturés sont ceux en vigueur sur le site au jour de la commande. Ils sont libellés en € et calculés hors taxes. Ils seront, par conséquent, majorés du taux de   TVA   applicable   en   vigueur.</p>

                    <h3>Factures - modalités de paiement & retard de paiement</h3>
                    <p>Les factures sont adressées au client par voie dématérialisée à l’adresse qui aura été communiquée par le client lors de la commande.</p>
                    <p>Le prix de la ou des commande(s) est payable mensuellement, en totalité, le dernier jour du mois concerné. Tout paiement ne sera considéré comme définitif qu’après encaissement effectif et complet des sommes par le vendeur. </p>
                    <p>Aucun escompte ne sera accordé en cas de paiement anticipé d’une facture. </p>
                    <p>Le moyen de paiement accepté pour régler les commandes est le prélèvement automatique. </p>
                    <p>Le vendeur ne prend pas en charge les frais afférents à des paiements en provenance de pays autre que la France.</p>
                    <p>Conformément à l’article L441-10 du Code de commerce, en cas de retard de règlement, les sommes échues porteront intérêt à un taux égal au taux d'intérêt pratiqué par la Banque Centrale Européenne à son opération de refinancement la plus récente, majorée de 10 points de pourcentage. </p>
                    <p>Les pénalités de retard courent de plein droit dès le jour suivant la date de règlement figurant sur la facture et sont exigibles sans qu'un rappel soit nécessaire ni aucune mise en demeure préalable.</p>
                    <p>De même, le professionnel, en situation de retard de règlement, est de plein droit, débiteur à l'égard du créancier, d'une indemnité forfaitaire de 40 €, pour frais de recouvrement. Lorsque les frais de recouvrement exposés sont supérieurs au montant de cette indemnité forfaitaire, le créancier peut demander une indemnisation complémentaire, sur justification. </p>
                    <p>De convention expresse entre les parties, le défaut de paiement d’une quelconque somme due par le client au vendeur entraîne le paiement, à titre de clause pénale, d’une indemnité forfaitaire de 20 % sur le montant total des sommes dues par le client.</p>
                    <p>Au surplus, le vendeur se réserve le droit de facturer les frais bancaires qu’il aurait à supporter du fait des démarches rendues nécessaires pour le recouvrement des sommes qui lui sont dues par le client.</p>
                    <p>De même, le vendeur s’autorise à surseoir à l’exécution des services ou abonnements si le client ne lui règle pas les sommes dues dans les conditions et selon les modalités fixées.</p>

                    <h3>Livraison des services & des abonnements</h3>
                    <p>Les services & abonnements commandés sont fournis au client dans les délais prévus lors de la commande et du paiement effectif des sommes dues. A défaut, le délai d’intervention du vendeur sera reporté d’autant. </p>
                    <p>Le client peut à tout moment contacter le service commercial du vendeur, par e-mail à l’adresse suivante contact@soluspots.fr afin d’être informé du suivi du traitement de sa commande. </p>
                    <p>En cas de force majeure, le vendeur est libéré de son obligation de livraison. En plus des situations considérées comme « cas de force majeure » par la jurisprudence, sont notamment considérés comme cas de force majeure les grèves totales ou partielles, les inondations, les incendies, les pandémies etc. ou tout autre événement de nature exceptionnelle ralentissant ou empêchant la bonne exécution des commandes.</p>
                    <p>Il appartient au client de vérifier la conformité de sa commande dès sa réception et, le cas échéant, de formuler immédiatement toutes les réserves au vendeur, par e-mail à l’adresse suivante contact@soluspots.fr, en fournissant tout justificatif.</p>
                    <p>Au-delà de ce délai, toute livraison n’ayant pas fait l’objet de réserves sera réputée conforme à la commande et considérée acceptée par le client, qui sera dès lors privé de tout recours contre le vendeur. </p>

                    <h3>Données personnelles</h3>
                    <p>L’éditeur du site s'engage à ce que la collecte et le traitement des données personnelles, effectués à partir du présent site, soient conformes au RGPD et à la loi Informatique et Libertés. Il s’engage notamment à assurer la sécurité et la confidentialité de ces données. A ce titre, seules les personnes habilitées y ont accès.</p>
                    <p>Le recueil de ces données, qui a pour base légale l’intérêt légitime du responsable de traitement, a pour finalité les opérations de gestion de la relation client, des impayés et du contentieux. </p>
                    <p>Les données traitées sont celles issues de l’enregistrement, par la personne elle-même, lors de sa commande sur notre site.</p>
                    <p>Les données personnelles collectées via notre site web sont conservées pendant une durée de 3 ans à compter du jour de leur collecte.</p>
                    <p>Il n’y a pas de transfert des données personnelles des clients hors de l’Union Européenne.</p>
                    <p>Conformément à la législation sur la protection des données personnelles, toute personne physique bénéficie d’un droit d’accès, de rectification, de suppression, de portabilité aux informations qui la concernent, que vous pouvez exercer en nous adressant un e-mail, accompagné d’une copie de votre pièce d’identité, à l’adresse contact@soluspots.fr.</p>
                    <p>Vous pouvez consulter le site www.cnil.fr pour plus d’informations sur vos droits.</p>
                    <p>Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant. Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés », ne sont pas respectés, vous pouvez introduire une réclamation auprès de la CNIL.</p>

                    <h3>Responsabilité & assurance</h3>
                    <p>Le vendeur décline toute responsabilité quant à l’utilisation détournée ou la mauvaise utilisation des services et abonnements achetés sur le site.</p>
                    <p>Le vendeur ne pourra être tenu pour responsable des retards d’exécution si ceux-ci résultent d’une communication tardive des informations par le client. Il en sera de même, dans le cas où le client lui communique des données erronées.</p>
                    <p>De même, la responsabilité du vendeur ne pourra en aucun cas être engagée en cas de retard ou de suspension de la fourniture du service ou de l’abonnement imputable au client ou en cas de force majeure. En plus des situations considérées comme « cas de force majeure » par la jurisprudence, sont notamment considérés comme cas de force majeure les grèves totales ou partielles, les inondations, les incendies, les pandémies etc. ou tout autre événement de nature exceptionnelle ralentissant ou empêchant la livraison des commandes.</p>
                    <p>Le vendeur certifie avoir souscrit, auprès d’une compagnie d’assurance notoirement solvable, une police d’assurance responsabilité civile professionnelle la couvrant contre les risques pouvant découler de son activité dans le cadre du présent contrat.</p>

                    <h3>Droit applicable & juridiction compétente</h3>
                    <p>Toute commande passée sur le présent site ainsi que les présentes CGV sont soumises au droit français. En cas de litige de toute nature pouvant intervenir entre les parties, découlant de l’interprétation et/ou de l’exécution d’une commande passée sur ce site et des CGV, qui n’aurait pu être réglé par voie amiable, les parties donnent compétence exclusive aux tribunaux du lieu du siège social du vendeur.</p>
                </div>
            </div>
        </div>
    );
}

export default TermsOfSalesPage;
