import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faBookmark, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { useAuth } from '../../ReactApp';
import Loader from '../Loader';
import axios from 'axios';
import { Link } from 'react-router-dom';

function AccountDetails() {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [accountDetails, setAccountDetails] = useState(true);

    const [showPopupAccountDetails, setShowPopupAccountDetails] = useState(false);
    const handlePopupEditAccountClose = () => setShowPopupAccountDetails(false);
    const handlePopupEditAccountShow = () => {
        setShowPopupAccountDetails(true);
    }

    useEffect(() => {
        if (user) {
            getAccountDetails(user._id)
        }
    }, [user])

    const getAccountDetails = (id) => {

        let params = {
            'user_id': id
        };

        axios.post(process.env.REACT_APP_API_URL + "/v1/user/details", params)
            .then((response) => {
                if (response.data) {
                    setAccountDetails(response.data)
                }
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            })
    }

    return (
        <div className="account-details">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-10">
                        <div>
                            <h2>Informations personnelles</h2>

                            {loading ? (<Loader />) :
                                <>
                                    <h3>Type de profil : {accountDetails.role == 'user' ? 'Utilisateur' : 'Loueur'}</h3>

                                    {accountDetails.user.enabled == 1 ?
                                        <p className='profile-menu-top-validation'>Profil vérifié &#x2714;</p>
                                        : ''}

                                    <p className='account-details-since'><FontAwesomeIcon icon={faBookmark}  /> Membre depuis {new Date(accountDetails.user.created_at).toLocaleString('default', { month: 'long' })} {new Date(accountDetails.user.created_at).getFullYear()}</p>

                                    <button onClick={handlePopupEditAccountShow} className='btn btn-sm btn-outline-primary'><FontAwesomeIcon icon={faPenToSquare} />  Modifier</button>

                                    <hr />

                                    {accountDetails.company ?
                                        <>
                                            <h3>Entreprise</h3>
                                            <p>Nom de l'entreprise : {accountDetails.company.name}</p>
                                            {/*
                                            <p>Adresse : 1 r de soz 8776 BR</p>
                                            <p>Téléphone : 03 00 00 00 00</p>
                                            */}
                                            <p>SIRET :{accountDetails.company.siren}</p>

                                            <hr />
                                        </>
                                        : ''}

                                    <h3>Contact</h3>
                                    <p>Nom : {accountDetails.user.lastname}</p>
                                    <p>Prénom : {accountDetails.user.firstname}</p>
                                    <p>Téléphone : {accountDetails.user.phone}</p>

                                    <hr />

                                    <h3>Connexion</h3>
                                    <p>Mail : {accountDetails.user.email}</p>
                                    <p>Mot de passe : ************</p>

                                    <hr />

                                    <h3>Moyens de paiement</h3>
                                    <p><FontAwesomeIcon icon={faBuildingColumns} /> Société X IBAN ***********567</p>

                                    <hr />

                                    {accountDetails.role != 'user' &&
                                        <div className='account-details-formule'>
                                            <p className='account-details-formule-type'>Abonnement : <span>{accountDetails.role}</span></p>
                                            <p className='account-details-formule-activity'><FontAwesomeIcon icon={faCircleCheck} /> ACTIF</p>
                                            {/* <button className='btn btn-outline-primary'>Voir mon abonnement</button> */}
                                        </div>
                                    }

                                    <Modal className="account-details-modal modal-lg" show={showPopupAccountDetails} onHide={handlePopupEditAccountClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title><h2>Modifier les informations<br/>de mon entreprise</h2></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {/* <p>Nom de l'entreprise : {accountDetails.company.name}</p> */}
                                            <p>La modification n'est pas encore active.<br/>Contactez-nous pour mettre à jour vos informations.</p>
                                            <Link to={'/contact'} className="btn btn-outline-primary">Contactez-nous</Link>
                                        </Modal.Body>
                                        {/* <Modal.Footer>
                                            <Button variant="danger" onClick={handlePopupEditAccountClose}>
                                                Annuler
                                            </Button>
                                            <Button type="submit" variant="primary">
                                                Envoyer
                                            </Button>
                                        </Modal.Footer> */}
                                    </Modal>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AccountDetails;
