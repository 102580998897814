import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { useAuth } from '../../ReactApp';
import ChatBox from './chat/ChatBox/ChatBox';
import axios from 'axios';
import headerBackground from '../../images/header-page.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import defaultTruck from '../../images/default-trucks/defaut-camion-malaxeur.png';
import defaultWorksite from '../../images/default-worksite/default-worksite.png';
import btnBackMessagerie from '../../images/btn-fleche-retour-fond-vert.jpg';
import Footer from '../Footer';
import Menu from '../Menu';
import { Link } from 'react-router-dom';

const Messagerie = (props) => {
  const { user } = useAuth();
  const [loadUser, setLoadUser] = useState(true);
  const [verif, setVerif] = useState(true);
  const [loading, setLoading] = useState(true);

  const [conversations, setConversations] = useState([]);
  const [currentConv, setCurrentConv] = useState({});

  const [displayConversation, setDisplayConversation] = useState(true);
  const [displayMessage, setDisplayMessage] = useState(false);

  const [messages, setMessages] = useState([{
    author: {
      username: '',
      id: 1,
      avatarUrl: 'https://via.placeholder.com/100/808080/FFFFFF/',
    },
    text: 'Chargement des messages...',
    type: 'text',
    timestamp: +new Date(),
  }]);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (displayMessage && isSmallScreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [displayMessage])

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getConversations()

    const intervalId = setInterval(() => {
      getConversations()
      if (currentConv.id_conv != 0) {
        getMessages(currentConv)
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [currentConv])

  let params = {
    'id_user': user._id,
  };

  const getMessages = async (currentConv) => {
    if (currentConv.id_conv) {
      await axios.post(process.env.REACT_APP_API_URL + `/v1/conversation/${currentConv.id_conv}`, params)
        .then((response) => {
          if (response.data['messages']) {
            setMessages(response.data.messages);
          } else {
            setMessages([{
              text: 'Démarrer la conversation.',
              type: 'notification',
            }])
          }
        }).catch((error) => {
          console.log(error);
        })
    }
  }

  const getConversations = async () => {
    await axios.post(process.env.REACT_APP_API_URL + "/v1/conversations", params)
      .then((response) => {
        setConversations(response.data);

        if (response.data.length > 0) {
          if (props.conv) {
            setCurrentConv(props.conv);
          } else {
            if (Object.keys(currentConv).length === 0) {
              setCurrentConv(response.data[0])
            }
          }

          getMessages(currentConv)
        }

        setLoading(false)
      }).catch((error) => {
        console.log(error);
      })
  }

  const handleOnSendMessage = async (message, files = []) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('id_conversation', currentConv.id_conv);
    formData.append('id_user', user._id);
    formData.append('content', message);
    formData.append('type_message', 'message');

    const newMessage = {
      author: {
        username: '',
        id: 1,
        avatarUrl: 'https://via.placeholder.com/100/808080/FFFFFF/?text=' + user.firstname.charAt(0),
      },
      text: message,
      type: 'text',
      timestamp: +new Date(),
    }

    axios.post(process.env.REACT_APP_API_URL + "/v1/conversation/1/add-message", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      setMessages([...messages, newMessage]);
    }).catch((error) => {
      // newMessage['hasError'] = true;
      // setMessages([...messages, newMessage]);
      console.log(error);
    })
  };

  const handleOnMessageButtonClick = (payload) => {
    console.log(`Clicked: ${payload}`);
  }

  const handleChangeConversation = (conv) => {
    setLoading(true);
    setCurrentConv(conv);
    getMessages(conv.id_conv);
    setDisplayMessage(true);
    setDisplayConversation(false);
  }

  const handleShowConversations = () => {
    setDisplayMessage(false);
    setDisplayConversation(true);
  }

  useEffect(() => {
    setLoadUser(true);

    if (user && user.enabled == 0) {
      setVerif(false);
    }
  }, [user])

  return (
    <>
    {(displayConversation || !isSmallScreen) && 
    <>
    <Menu activeItem={props.activeItem} setActiveItem={props.setActiveItem} />

    {loadUser &&
        <>
          <div className='profile-top' style={{ backgroundImage: `url(` + headerBackground + `)` }}>
            <div className='mediumcontainer profile-top-content'>
              <img className='profile-top-content-image' src='https://via.placeholder.com/90' />
              <div className='profile-top-content-info'>
                <p className='profile-top-content-info-name'>
                  Bonjour {user ? user.firstname : ''} !
                </p>
                <p className='profile-top-content-info-description'>Bienvenue sur votre profil -  {user ? user.role : ''}</p>
              </div>
            </div>
          </div>
          {(verif) ? '' :
            (<div className='profile-verif'>
              <p className='profile-verif-text'>Votre profil est en cours de vérification.</p>
            </div>)
          }
        </>
      }
    </>
    }

    <div className={`messagerie ${(displayMessage && isSmallScreen) && "pt-0"}`}>
      {!isSmallScreen ? (
        <div className="mediumcontainer">
          <div className="row">
            <h2>Messagerie</h2>
            <div className="col-12 col-md-5">
              <div className={`messagerie-conversation ${displayConversation && "active"}`}>
                <h3>Conversations</h3>
                <div className='messagerie-conversation-listing'>
                  {conversations.length > 0 ?
                    conversations.map((conv, key) => (
                      (conv.type == 'product' ?
                        <div className={currentConv.id_conv == conv.id_conv ? 'messagerie-conversation-listing-item active' :
                          'messagerie-conversation-listing-item'} key={key} onClick={() => handleChangeConversation(conv)}>
                          {!conv.read && <div className='status'></div>}
                          <div className='profil'><img src={conv.product_image && JSON.parse(conv.product_image).length != 0 ? process.env.REACT_APP_API_URL + JSON.parse(conv.product_image)[0] : defaultTruck} /></div>
                          <div className='content'>
                            <p className='company'>{conv.company_name}</p>
                            <p className='product'>{conv.product_name} - {conv.category_name}<br />{conv.product_immat}</p>
                            <p className='date'>
                              {(() => {
                                const lastUpdate = new Date(conv.last_update);
                                const formattedUpdateDate = `${lastUpdate.getDate()}/${lastUpdate.getMonth() + 1}/${lastUpdate.getFullYear()} ${lastUpdate.getHours()}:${(lastUpdate.getMinutes()<10?'0':'') + lastUpdate.getMinutes()}`;

                                return formattedUpdateDate;
                              })()}
                            </p>
                            <p className='excerpt'>{conv.last_message}</p>
                          </div>
                        </div>
                      :
                        <div className={currentConv.id_conv == conv.id_conv ? 'messagerie-conversation-listing-item active' :
                          'messagerie-conversation-listing-item'} key={key} onClick={() => handleChangeConversation(conv)}>
                          {!conv.read && <div className='status'></div>}
                          <div className='profil'><img src={conv.product_image && JSON.parse(conv.product_image).length != 0 ? process.env.REACT_APP_API_URL + JSON.parse(conv.product_image)[0] : defaultWorksite} /></div>
                          <div className='content'>
                            <p className='company'>{conv.company_name}</p>
                            <p className='product'>Annonce {conv.worksite_name}</p>
                            <p className='date'>
                              {(() => {
                                const lastUpdate = new Date(conv.last_update);
                                const formattedUpdateDate = `${lastUpdate.getDate()}/${lastUpdate.getMonth() + 1}/${lastUpdate.getFullYear()} ${lastUpdate.getHours()}:${(lastUpdate.getMinutes()<10?'0':'') + lastUpdate.getMinutes()}`;

                                return formattedUpdateDate;
                              })()}
                            </p>
                            <p className='excerpt'>{conv.last_message}</p>
                          </div>
                        </div>
                      )
                    ))
                    :
                    <div className='messagerie-conversation-listing-item'>
                      <span>Aucune conversation</span>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7">
              {!loading ?
                (currentConv.id_conv) ?
                 (currentConv.type == 'product' ?
                    <div className={`messagerie-message ${displayMessage && "active"}`}>
                      <div className='messagerie-top-area-buttons'>
                        <img src={btnBackMessagerie} alt='' onClick={handleShowConversations} />
                      </div>
                      <div className='messagerie-informations'>
                        <div className='general'>
                          <div className='general-company'>
                            {currentConv.company_photo ?
                              <div className='company_icon_logo'><img src={process.env.REACT_APP_API_URL + currentConv.company_photo} /></div>
                            :
                              <div className='company_icon'><FontAwesomeIcon icon={faCircleUser} /></div>
                            }
                            <Link to={'/entreprise/' + currentConv.company_id} className='company_name'>{currentConv.company_name}</Link>
                          </div>
                          <div className='general-buttons'>
                            {currentConv.id_renter != user._id &&
                              <>
                                <a href={currentConv.product_path} className='btn btn-sm btn-outline-primary'>Faire une offre</a>
                                <a href={currentConv.product_path} className='btn btn-sm btn-primary'>Réserver</a>
                              </>
                            }
                          </div>
                        </div>

                        <div className='details'>
                          <div className='details-bloc'>
                            <img src={currentConv.product_image && JSON.parse(currentConv.product_image).length != 0 ? process.env.REACT_APP_API_URL + JSON.parse(currentConv.product_image)[0] : defaultTruck} alt='' />
                          </div>
                          <div className='details-bloc'>
                            <p><Link className='details-bloc-name' to={currentConv.product_path} ><strong>{currentConv.product_name}</strong> - {currentConv.category_name}</Link></p>
                            <p>À partir de</p>
                            <p><strong>{currentConv.product_price}€/j</strong></p>
                          </div>
                        </div>
                      </div>

                      <ChatBox
                        messages={messages}
                        userId={1}
                        onSendMessage={handleOnSendMessage}
                        onMessageButtonClick={handleOnMessageButtonClick}
                        width={'100%'}
                        height={'100%'}
                        fileSelectMode='MULTIPLE'
                      />
                    </div>
                  :
                  <div className={`messagerie-message ${displayMessage && "active"}`}>
                    <div className='messagerie-top-area-buttons'>
                      <img src={btnBackMessagerie} alt='' onClick={handleShowConversations} />
                    </div>
                    <div className='messagerie-informations'>
                      <div className='general'>
                        <div className='general-company'>
                          {currentConv.company_photo ?
                            <div className='company_icon_logo'><img src={process.env.REACT_APP_API_URL + currentConv.company_photo} /></div>
                          :
                            <div className='company_icon'><FontAwesomeIcon icon={faCircleUser} /></div>
                          }
                          <Link to={'/entreprise/' + currentConv.company_id} className='company_name'>{currentConv.company_name}</Link>
                        </div>
                        <div className='general-buttons'>
                      
                        </div>
                      </div>

                      <div className='details'>
                        <div className='details-bloc'>
                          <img src={currentConv.product_image && JSON.parse(currentConv.product_image).length != 0 ? process.env.REACT_APP_API_URL + JSON.parse(currentConv.product_image)[0] : defaultTruck} alt='' />
                        </div>
                        <div className='details-bloc'>
                          <p><Link className='details-bloc-name' to={currentConv.worksite_path} ><strong>{currentConv.worksite_name}</strong></Link></p>
                        </div>
                      </div>
                    </div>

                    <ChatBox
                      messages={messages}
                      userId={1}
                      onSendMessage={handleOnSendMessage}
                      onMessageButtonClick={handleOnMessageButtonClick}
                      width={'100%'}
                      height={'100%'}
                      fileSelectMode='MULTIPLE'
                    />
                  </div>
                  )
                  : 'Aucune conversation trouvée.'
                : <Loader />
              }
            </div>
          </div>
        </div>
      ) : (
        <>
          {displayConversation && <h2>Messagerie</h2>}
          <div className={`messagerie-conversation ${displayConversation && "active"}`}>
            <h3>Conversations</h3>
            <div className='messagerie-conversation-listing'>
              {conversations.length > 0 ?
                conversations.map((conv, key) => (
                  <div className={currentConv.id_conv == conv.id_conv ? 'messagerie-conversation-listing-item active' :
                    'messagerie-conversation-listing-item'} key={key} onClick={() => handleChangeConversation(conv)}>
                    {!conv.read && <div className='status'></div>}
                    <div className='profil'><FontAwesomeIcon icon={faCircleUser} /></div>
                    <div className='content'>
                      <p className='company'>{conv.company_name}</p>
                      <p className='product'>{conv.product_name} - {conv.category_name}<br />{conv.product_immat}</p>
                      <p className='date'>
                        {(() => {
                          const lastUpdate = new Date(conv.last_update);
                          const formattedUpdateDate = `${lastUpdate.getDate()}/${lastUpdate.getMonth() + 1}/${lastUpdate.getFullYear()} ${lastUpdate.getHours()}:${(lastUpdate.getMinutes()<10?'0':'') + lastUpdate.getMinutes()}`;

                          return formattedUpdateDate;
                        })()}
                      </p>
                      <p className='excerpt'>{conv.last_message}</p>
                    </div>
                  </div>
                ))
                :
                <div className='messagerie-conversation-listing-item'>
                  <span>Aucune conversation</span>
                </div>
              }
            </div>
          </div>
          {!loading ?
            (currentConv.id_conv) ?
              <div className={`messagerie-message ${displayMessage && "active"}`}>
                <div className='messagerie-top-area-buttons'>
                  <img src={btnBackMessagerie} alt='' onClick={handleShowConversations} />
                </div>
                <div className='messagerie-informations'>
                  <div className='general'>
                    <div className='general-company'>
                      <div className='company_icon'><FontAwesomeIcon icon={faCircleUser} /></div>
                      <div className='company_name'>{currentConv.company_name}</div>
                    </div>
                    <div className='general-buttons'>
                      {currentConv.id_renter != user._id &&
                        <>
                          <a href={currentConv.product_path} className='btn btn-sm btn-outline-primary'>Faire une offre</a>
                          <a href={currentConv.product_path} className='btn btn-sm btn-primary'>Réserver</a>
                        </>
                      }
                    </div>
                  </div>

                  <div className='details'>
                    <div className='details-bloc'>
                      <img src={currentConv.product_image && JSON.parse(currentConv.product_image).length != 0 ? process.env.REACT_APP_API_URL + JSON.parse(currentConv.product_image)[0] : defaultTruck} alt='' />
                    </div>
                    <div className='details-bloc'>
                      <p><Link to={currentConv.product_path} ><strong>{currentConv.product_name}</strong> - {currentConv.category_name}</Link></p>
                      <p>À partir de</p>
                      <p><strong>{currentConv.product_price}€/j</strong></p>
                    </div>
                  </div>
                </div>

                <ChatBox
                  messages={messages}
                  userId={1}
                  onSendMessage={handleOnSendMessage}
                  onMessageButtonClick={handleOnMessageButtonClick}
                  width={'100%'}
                  height={'100%'}
                  fileSelectMode='MULTIPLE'
                />
              </div>
              : 'Aucune conversation trouvée.'
            : <Loader />
          }
        </>
      )}
    </div>

    {(displayConversation || !isSmallScreen) && <Footer />}
    </>
  );
}

export default Messagerie;
