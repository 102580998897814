import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from 'react'
import Loader from "../components/Loader";
import { useAuth } from '../ReactApp';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Accordion, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { getDistance } from "../services/CalculDistance";
import axios from 'axios';
import Banner from "../components/Banner";
import ProductList from "../components/cards/ProductList";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { DateRange } from "react-date-range";
import locale from 'date-fns/locale/fr';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from 'moment';
import Autocomplete from "../components/Autocomplete";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders } from '@fortawesome/free-solid-svg-icons'
import btnClose from '../images/btn-close.jpg';

const PolitiqueConfidentialite = () => {

    return (
        <>
            <div className="container">
                <h1 className="contact-title">
                    Politique de <span className="contact-title-colored">confidentialité</span>
                </h1>

                <p>La politique de confidentialité suivante explique comment Soluspot.fr collecte, utilise, divulgue et protège les informations personnelles des utilisateurs de notre site web. Nous attachons une grande importance à la confidentialité de vos informations et nous nous engageons à les protéger. Veuillez lire attentivement cette politique de confidentialité pour comprendre nos pratiques concernant vos informations personnelles et la manière dont nous les traitons.</p>

                <h3>Collecte des informations personnelles</h3>
                <p>1.1 Informations fournies volontairement par l'utilisateur :
                Lorsque vous utilisez notre site web, il se peut que nous vous demandions de fournir certaines informations personnelles, telles que votre nom, votre adresse e-mail, votre numéro de téléphone, votre adresse postale, etc. Ces informations sont collectées lorsque vous remplissez des formulaires sur notre site web, vous abonnez à notre newsletter, passez une commande ou interagissez d'une autre manière avec notre site.</p>

                <p>1.2 Informations collectées automatiquement :
                Nous pouvons également collecter automatiquement certaines informations lorsque vous naviguez sur notre site web. Ces informations peuvent inclure votre adresse IP, le type de navigateur que vous utilisez, les pages que vous consultez et d'autres données de ce type. Nous utilisons des cookies et d'autres technologies similaires pour collecter ces informations.</p>

                <h3>Utilisation des informations personnelles</h3>
                Nous utilisons les informations personnelles que nous collectons dans les buts suivants :

                <p>2.1 Fournir les services demandés :</p>
                <p>Nous utilisons vos informations personnelles pour répondre à vos demandes, traiter vos commandes, vous fournir les services que vous avez demandés et vous envoyer des notifications importantes concernant votre utilisation de notre site web.</p>

                <p>2.2 Personnaliser votre expérience :</p>
                <p>Nous pouvons utiliser vos informations personnelles pour personnaliser votre expérience sur notre site web, afficher du contenu et des offres qui correspondent à vos intérêts et améliorer notre site web en fonction de vos préférences.</p>

                <p>2.3 Communiquer avec vous :</p>
                <p>Nous pouvons utiliser vos informations personnelles pour vous envoyer des communications marketing, des newsletters, des informations sur nos produits et services, des mises à jour importantes et d'autres communications nécessaires à votre utilisation de notre site web.</p>

                <h3>Divulgation des informations personnelles</h3>
                Nous nous engageons à ne pas vendre, louer ou divulguer vos informations personnelles à des tiers, sauf dans les cas suivants :

                <p>3.1 Partenaires de confiance :</p>
                <p>Nous pouvons partager vos informations personnelles avec nos partenaires de confiance, tels que des prestataires de services tiers, dans le but de fournir les services que vous avez demandés et d'améliorer votre expérience utilisateur.</p>

                <p>3.2 Conformité légale :</p>
                <p>Nous pouvons divulguer vos informations personnelles si nous sommes légalement tenus de le faire ou si nous pensons de bonne foi que cette divulgation est nécessaire pour (i) nous conformer à une obligation légale, (ii) protéger et défendre nos droits ou notre propriété, (iii) prévenir une activité frauduleuse ou illégale, ou (iv) protéger la sécurité personnelle des utilisateurs de notre site web ou du public.</p>

                <h3>Sécurité des informations personnelles</h3>
                <p>Nous mettons en place des mesures de sécurité appropriées pour protéger vos informations personnelles contre tout accès non autorisé, toute divulgation, toute altération ou toute destruction. Nous utilisons des protocoles de sécurité standards de l'industrie pour assurer la confidentialité et l'intégrité de vos informations personnelles.</p>

                <h3>Vos droits et choix</h3>
                <p>Vous pouvez exercer certains droits concernant vos informations personnelles, tels que le droit d'accéder, de corriger, de mettre à jour, de supprimer ou de limiter l'utilisation de vos informations personnelles. Vous pouvez également vous désabonner de nos communications marketing à tout moment.</p>

                <h3>Modifications de la politique de confidentialité</h3>
                <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications apportées à la politique de confidentialité seront publiées sur cette page avec une nouvelle date de mise à jour. Veuillez consulter régulièrement cette politique de confidentialité pour rester informé de tout changement.</p>

                <p>Nous nous efforcerons de répondre à vos demandes et préoccupations dans les plus brefs délais.</p>
            </div>
        </>
    );
};

export default PolitiqueConfidentialite;
