import React from 'react';
import {Col, Row} from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import SubCategoryCard from '../components/cards/SubCategoryCard';
import Banner from '../components/Banner';
import {useLocation, useParams} from 'react-router-dom';
import Loader from '../components/Loader';
import {useAxios} from '../components/tools/useAxios';

function CategoriesPage() {
    //Permet de récupérer le state passé dans useNavigate du composant CategoryCard
    const location = useLocation();

    //Permet d'accéder au params de ReactApp (lorsque l'on n'a pas de state)
    const params = useParams();

    //Permet de transformer le slug en titre (lorsque l'on n'a pas de state)
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
    const capitalizeWords = arr => arr.map((word) => {  return word[0].toUpperCase() + word.substring(1); }).join(" ");
    const getTitleByPathname = pathname => {
        let pagename = getLastItem(pathname);
        pagename = pagename.replace(/-et-/g, "-&-");
        pagename = pagename.replace(/-/g, " ");
        pagename = capitalizeWords(pagename.split(" "));

        return pagename;
    }

    let axios_params = {'slug_categ' : params.categ};

    if (location.state) {
        axios_params = {'category_id' : location.state.subcateg.id};
    }

    const [loading, categories] = useAxios(process.env.REACT_APP_API_URL + "/v1/categories", 'post', axios_params);
    const titleBanner = `Location de matériel \n ${location.state ? location.state.subcateg.name : getTitleByPathname(location.pathname)}`;

    return (
        <>
            <Banner color="#000"
                    background="#e6efee"
                    titleBanner={titleBanner}
                    textBanner=""
            >
            </Banner>
            <div className="bigcontainer">
                <div className="catalogue-section-truck">
                    <Container>
                        <div className="catalogue-section-truck-content">
                            <Row>
                            {loading ? (<Loader />) :
                                (categories.map((item, index) =>
                                    <Col key={index} xs={12} sm={6} md={6} lg={4}>
                                        <SubCategoryCard key={index} item={item} categ={location.state ? location.state.subcateg.slug : params.categ} />
                                    </Col>
                                    )
                                )
                            }
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CategoriesPage;
