import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../ReactApp';
import { useParams, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import { Calendar } from 'react-multi-date-picker';
import lang_fr from "./../components/calendar/lang_fr";
import ImageGallery from 'react-image-gallery';
import '../sass/pages/product.scss';
import '../sass/components/Calendar.scss';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faCircleUser, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import Banner from "../components/Banner";
import { Link } from 'react-router-dom';
import defaultTruck from '../images/default-trucks/defaut-camion-malaxeur.png';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function ProductPage() {
    const params = useParams();
    const productId = params.product;

    const location = useLocation();
    const [datesChecked, setDatesChecked] = useState([])

    const { user } = useAuth();

    const [position, setPosition] = useState([48.52, 2.19]);
    const [city, setCity] = useState('');
    const [toggleInfos, setToggleInfos] = useState(true);
    const [toggleLocation, setToggleLocation] = useState(true);
    const [iconInfos, setIconInfos] = useState(faChevronDown);
    const [iconLocation, setIconLocation] = useState(faChevronDown);
    const [dateReservation, setDateReservation] = useState([]);

    useEffect(() => {
        if (position) {
            axios.get("https://api-adresse.data.gouv.fr/reverse/?lon=" + position[1] + "&lat=" + position[0] + "&type=municipality")
              .then((response) => {
                if (response.data.features[0].properties.label) {
                  setCity(response.data.features[0].properties.label)
                }
              }).catch((error) => {
                console.log(error)
              })
          }
    }, [position])


    const toggleFunc = useCallback(() => {
        (iconInfos === faChevronDown) ? setIconInfos(faChevronUp) : setIconInfos(faChevronDown);
        setToggleInfos(!toggleInfos);
    });
    const toggleFuncLocation = useCallback(() => {
        (iconLocation === faChevronDown) ? setIconLocation(faChevronUp) : setIconLocation(faChevronDown);
        setToggleLocation(!toggleLocation);
    });

    const [showResa, setShowResa] = useState(false);
    const handleClose = () => setShowResa(false);
    const handleShow = (dateReservation) => {
        setErrDateReservation(false)

        if (dateReservation.length == 0) {
            setErrDateReservation('Pour pouvoir envoyer votre demande, veuillez sélectionner une ou plusieurs dates.')
            return;
        }

        setShowResa(true);
        setOpenStickyResponsiveResa(false);
    }

    const [showOffer, setShowOffer] = useState(false);
    const handleOfferClose = () => setShowOffer(false);
    const handleOfferShow = (dateReservation) => {
        setErrDateReservation(false)

        if (dateReservation.length == 0) {
            setErrDateReservation('Pour pouvoir faire une offre veuillez sélectionner une ou plusieurs dates.')
            return;
        }

        setShowOffer(true);
    }
    const [priceOffer, setPriceOffer] = useState('');
    const [errPriceOffer, setErrPriceOffer] = useState(false);
    const [errDateReservation, setErrDateReservation] = useState(false);
    const handleChangePriceOffer = (event) => {
        if (event.target.value >= (product.price * multiplicator)) {
            setPriceOffer(product.price * multiplicator)
        } else if (event.target.value < 1) {
            setPriceOffer(1);
        } else {
            setPriceOffer(event.target.value);
        }
    }

    const [showMessageCard, setShowMessageCard] = useState(false);
    const handleMessageCardClose = () => setShowMessageCard(false);
    const handleMessageCardShow = () => { setShowMessageCard(true); }
    const [errMessage, setErrMessage] = useState(false);
    const [messageOffer, setMessageOffer] = useState('');
    const handleChangeMessage = (event) => setMessageOffer(event.target.value);

    const [openStickyResponsiveResa, setOpenStickyResponsiveResa] = useState(false);

    document.body.addEventListener('click', (e) => {
        let container = document.getElementsByClassName('product-reservation');

        if (container[0] && !container[0].contains(e.target)) {
            if (openStickyResponsiveResa) {
                setOpenStickyResponsiveResa(false);
            }
        }
    }, true);

    const handleCloseResponsiveResa = () => setOpenStickyResponsiveResa(false);

    const handleShowReservation = () => {
        setOpenStickyResponsiveResa(true);
    }

    const images = [
        {
            original: defaultTruck,
            thumbnail: defaultTruck,
        },
        {
            original: defaultTruck,
            thumbnail: defaultTruck,
        },
        {
            original: defaultTruck,
            thumbnail: defaultTruck,
        },
    ];

    function ClearErrorWithTimeout(error, setError) {
        useEffect(() => {
            if (error !== false) {
                const timeoutId = setTimeout(() => {
                    setError(false)
                }, 5000)
                return () => clearTimeout(timeoutId)
            }
        }, [error, setError])
    }

    ClearErrorWithTimeout(errPriceOffer, setErrPriceOffer);
    ClearErrorWithTimeout(errDateReservation, setErrDateReservation);
    ClearErrorWithTimeout(errMessage, setErrMessage);

    useEffect(() => {
        getProduct(params.product);
    }, []);

    useEffect(() => {
        getProductsBySlugParent('camion-nacelle');
        getDatesReservation(productId);

        if (location.state !== null && location.state.date && location.state.date[0].startDate) {
            if (location.state.date[0].startDate && location.state.date[0].endDate) {
                const startDate = location.state.date[0].startDate;
                const endDate = location.state.date[0].endDate;

                datesChecked.push(startDate);

                for (let d = startDate; d < endDate; d.setDate(d.getDate() + 1)) {
                    datesChecked.push(new Date(d));
                }

                setDatesChecked(datesChecked);
                setMultiplicator(datesChecked.length);
            }
        }
    }, [dateReservation]);

    //supprime l'overflow du body lorsque le sticky réservation est affiché
    useEffect(() => {
        if (openStickyResponsiveResa) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

    }, [openStickyResponsiveResa])

    /* INFOS DU PRODUIT */
    const [productRequest, setProductRequest] = useState({ product: null, loading: true });
    const [company, setCompany] = useState({});
    const [companyPhoto, setCompanyPhoto] = useState('');
    const [productImages, setProductImages] = useState([]);
    const { product, loading } = productRequest;

    const getProduct = async (product_slug) => {
        //Suppression de l'id dans le slug
        product_slug = product_slug.substring(product_slug.indexOf("-") + 1);

        await axios.get(process.env.REACT_APP_API_URL + "/v1/products/" + params.product)
            .then((response) => {
                if (response.data) {
                    setProductRequest({
                        product: response.data.product,
                        loading: false
                    });

                    setCompany(response.data.company)
                    setCompanyPhoto(response.data.company_image)

                    setPosition([response.data.product.latitude, response.data.product.longitude])

                    if (JSON.parse(response.data.product.image).length > 0) {
                        JSON.parse(response.data.product.image).forEach((item) => {
                            setProductImages(productImages => [...productImages, { 'original': process.env.REACT_APP_API_URL + item, 'thumbnail': process.env.REACT_APP_API_URL + item }]);
                        })
                    } else {
                        setProductImages(images)
                    }

                    { getCategoryName(response.data.product.category_id) }
                    { getCategoryParentName(response.data.product.category_id) }
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    /* RÉCUPÉRATION DU SLUG */
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
    const slugPage = getLastItem(location.pathname);

    /* LISTE DES AUTRES PRODUITS */
    const [otherProductsSubCateg, setOtherProductsSubCateg] = useState([]);
    const getProductsBySlugParent = (subcateg) => {
        let params = { params: { 'slug_categ': subcateg, 'limit': 3 } };

        axios.post(process.env.REACT_APP_API_URL + "/v1/products", null, params)
            .then((response) => {

                //On supprime le produit courant du tableau
                response.data.forEach((item, index, arr) => {
                    if (item.slug === slugPage) {
                        arr.splice(index, 1);
                    }
                });

                if (response.data && response.data.length > 0) {
                    setOtherProductsSubCateg(response.data);
                } else {
                    setOtherProductsSubCateg(null);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    /* NOM DE LA CATÉGORIE PARENTE DU PRODUIT */
    const [categoryParentName, setCategoryParentName] = useState([]);
    const getCategoryParentName = async (category_id) => {
        await axios.get(process.env.REACT_APP_API_URL + "/v1/categories-parent/" + category_id)
            .then((response) => {
                if (response.data) {
                    setCategoryParentName(response.data);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    /* NOM DE LA CATÉGORIE DU PRODUIT */
    const [categoryName, setCategoryName] = useState([]);
    const getCategoryName = async (category_id) => {
        await axios.get(process.env.REACT_APP_API_URL + "/v1/categories/" + category_id)
            .then((response) => {
                if (response.data) {
                    setCategoryName(response.data);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    /* GESTION DE LA RÉSERVATION */
    const [multiplicator, setMultiplicator] = useState(0);
    const handleDateChange = (values) => {
        let date_reservation = [];

        values.forEach((item, index) => {
            item = item.year + '-' + ('0' + item.month).slice(-2) + '-' + ('0' + item.day).slice(-2);
            date_reservation.push(item);
        });

        //Pour modifier le prix total
        setMultiplicator(date_reservation.length);

        setDateReservation(date_reservation);
    }
    const handleSubmit = (user, product) => (event) => {
        event.preventDefault();

        let axios_params = {
            'user_id': user._id,
            'product_id': product.id,
            'dates_reservation': dateReservation,
            'status': 'pending',
        };

        axios.post(process.env.REACT_APP_API_URL + "/v1/reservations/store", axios_params)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    toast.success('Votre demande de réservation a bien été envoyée !', {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    getDatesReservation(productId);
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        handleClose();
    }

    const handleOfferSubmit = (user, product, priceOffer, dateReservation) => (event) => {
        event.preventDefault();

        setErrPriceOffer(false);
        setErrDateReservation(false);

        if (!priceOffer) {
            setErrPriceOffer('Veuillez renseigner un tarif.')
            return;
        }

        if (dateReservation.length == 0) {
            setErrDateReservation('Pour pouvoir faire une offre veuillez sélectionner une ou plusieurs dates.')
            return;
        }

        let axios_params = {
            'user_id': user._id,
            'product_id': product.id,
            'price_offer': priceOffer,
            'dates_reservation': dateReservation
        };

        axios.post(process.env.REACT_APP_API_URL + "/v1/make-offer", axios_params)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    toast.success('Votre offre a bien été envoyée !', {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        setPriceOffer('');

        handleOfferClose();
    }

    const handleSendMessage = (user, product, messageOffer) => (event) => {
        event.preventDefault();

        setErrMessage(false);

        if (!messageOffer) {
            setErrMessage('Veuillez renseigner un message.')
            return;
        }

        let axios_params = {
            'user_id': user._id,
            'product_id': product.id,
            'message': messageOffer
        };

        axios.post(process.env.REACT_APP_API_URL + "/v1/send-message-product", axios_params)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    toast.success('Votre message a bien été envoyé ! Retrouvez la conversation dans votre espace messagerie.', {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        handleMessageCardClose();
    }

    /* DATES DÉJÀ RÉSERVÉES */
    const [disabledDates, setDisabledDates] = useState([]);
    const getDatesReservation = async (productId) => {
        await axios.get(process.env.REACT_APP_API_URL + "/v1/products/reservations/" + productId)
            .then((response) => {
                if (response.data) {

                    let dates = [];

                    response.data.forEach((item, index, row) => {
                        dates = dates.concat(item.dates);
                    })

                    setDisabledDates(dates);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    /* POSITION DU MARKER SUR LA MAP (EMPLACEMENT DU PRODUIT) */
    const icon = L.icon({
        iconUrl: process.env.REACT_APP_API_URL + "/images/vendor/leaflet/dist/marker-icon.svg",
        iconSize: [26, 37], // size of the icon
        shadowSize: [30, 40], // size of the shadow
        iconAnchor: [26, 37], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 35],  // the same for the shadow
        popupAnchor: [-3, -50] // point from which the popup should open relative to the iconAnchor
    });

    const titleBanner = "Besoin d'un camion malaxeur électrique pour demain&nbsp;? <br/> <span class='banner-title-colored'>SoluSpots a déjà trouvé&nbsp;!</span>";

    return (
        <>
            <Banner titleBanner={titleBanner}></Banner>
            {loading ? (<Loader />) :
                (
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>
                                <h1 className="product-title"><strong>{product.name}</strong> - {categoryParentName}</h1>
                                <p className="product-title-city"><FontAwesomeIcon icon={faLocationDot} /> {city}</p>
                                <hr />
                            </div>

                            {/* Visuel produit */}
                            <div className="col-12 col-md-6 col-lg-8">
                                <div className="row">
                                    <div className='col-12 d-none d-lg-block'>
                                        <ImageGallery
                                            items={productImages}
                                            showNav={false}
                                            showFullscreenButton={false}
                                            showBullets={false}
                                            showPlayButton={false}
                                            thumbnailPosition='right'
                                            additionalClass='product-gallery'
                                        />
                                    </div>
                                    <div className='col-12 d-block d-lg-none'>
                                        <ImageGallery
                                            items={productImages}
                                            showNav={false}
                                            showFullscreenButton={false}
                                            showBullets={false}
                                            showPlayButton={false}
                                            thumbnailPosition='bottom'
                                            additionalClass='product-gallery'
                                        />
                                    </div>

                                    {/* Informations */}
                                    <h2 onClick={toggleFunc} className='product-h2 product-h2-icon'>Informations <span><FontAwesomeIcon icon={iconInfos} /></span></h2>
                                    <Collapse in={toggleInfos}>
                                        <div>
                                            <table className="table-informations">
                                                <tbody>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td colSpan="2">{categoryParentName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Marque</td>
                                                        <td colSpan="2">{product.brand}</td>
                                                    </tr>
                                                    {categoryName != categoryParentName ?
                                                        <tr>
                                                            <td>Spécificité</td>
                                                            <td colSpan="2">{categoryName}</td>
                                                        </tr>
                                                    : ''}
                                                    <tr>
                                                        <td>Carburant</td>
                                                        <td colSpan="2">{product.fuel}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Localisation</td>
                                                        <td colSpan="2">{city}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Collapse>
                                    <h2 onClick={toggleFuncLocation} className='product-h2 product-h2-icon'>Localisation <span><FontAwesomeIcon icon={iconLocation} /></span></h2>
                                    <Collapse in={toggleLocation}>
                                        <div>
                                            <MapContainer className='product-map' style={{ height: "500px" }} center={position} zoom={13} scrollWheelZoom={false}>
                                                <TileLayer
                                                    // url="http://tiles.lyrk.org/ls/{z}/{x}/{y}?apikey=982c82cc765f42cf950a57de0d891076"
                                                    url="http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                                                    attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    minZoom="0"
                                                    maxZoom="20"
                                                />

                                                <Marker position={position} icon={icon}></Marker>
                                            </MapContainer>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>

                            {/* Formulaire de réservation */}
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className={`${openStickyResponsiveResa ? "active" : ""} product-reservation`}>
                                    <button type="button" className="btn-close close-reservation" aria-label="Close" onClick={handleCloseResponsiveResa}></button>
                                    <form onSubmit={handleSubmit(user, product)}>
                                        <div className="row">
                                            <div className='col-8'>
                                                <p className='product-reservation-name'>Location<br /><span>{categoryParentName} <br />{product.name}</span></p>
                                            </div>
                                            <div className='col-4 d-flex align-items-end'>
                                                <p className='product-reservation-price'>À partir de <br/><span>{product.price}€/j</span></p>
                                            </div>
                                            <div className="col-12">
                                                <div className='product-reservation-company'>
                                                    {companyPhoto ?
                                                        <img src={companyPhoto ? process.env.REACT_APP_API_URL + companyPhoto : 'https://via.placeholder.com/60'} className='product-reservation-company-img' />
                                                    :
                                                        <FontAwesomeIcon icon={faCircleUser} />
                                                    }
                                                    {company ?
                                                        <Link to={`/entreprise/` + company.id} className='product-reservation-company-name'>{company ? company.name : ''}</Link>
                                                    : ''}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className='product-reservation-calendar'>
                                                    {disabledDates &&
                                                        <Calendar multiple
                                                            locale={lang_fr}
                                                            className="green"
                                                            numberOfMonths={1}
                                                            weekStartDayIndex={1}
                                                            minDate={new Date()}
                                                            value={datesChecked}
                                                            onChange={(value) => handleDateChange(value)}
                                                            mapDays={({ date }) => {

                                                                let today = new Date();
                                                                today = today.getFullYear() + '' + ('0' + (today.getMonth() + 1)).slice(-2) + '' + ('0' + today.getDate()).slice(-2);

                                                                let dateCompare = date.year + '' + ('0' + date.month).slice(-2) + '' + ('0' + date.day).slice(-2);

                                                                date = date.year + '-' + ('0' + date.month).slice(-2) + '-' + ('0' + date.day).slice(-2);

                                                                if (parseInt(today) === parseInt(dateCompare)) {
                                                                    return {
                                                                        disabled: true,
                                                                        style: {
                                                                            color: "#ccc",
                                                                            textDecoration: "line-through"
                                                                        }
                                                                    }
                                                                } else if (disabledDates.includes(date) || (parseInt(today) > parseInt(dateCompare))) {
                                                                    return {
                                                                        disabled: true,
                                                                        style: {
                                                                            color: "#ccc",
                                                                            textDecoration: "line-through"
                                                                        }
                                                                    }
                                                                } else {
                                                                    return {
                                                                        disabled: false,
                                                                        style: { color: "#000", borderRadius: '0', width: "100%" }
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <table className='product-reservation-resume-cart'>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan='2'>Location {categoryParentName}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* <tr>
                                                            <td colSpan='2'>Du 1 sept. 2022 - 15 sept. 2022</td>
                                                        </tr> */}
                                                        <tr>
                                                            <td>{product.price}€ x {multiplicator} jours</td>
                                                            <td>{product.price * multiplicator} €</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th>Total</th>
                                                            <td>{product.price * multiplicator} €</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="col-12">
                                                <div className='product-reservation-request'>
                                                    {user && user.role == 'user' ?
                                                        <>
                                                            {(errDateReservation && !showOffer) &&
                                                                <span style={{ color: 'red', textAlign: 'center', display: 'block', marginBottom: '10px' }}>{errDateReservation}</span>
                                                            }

                                                            <span className='btn btn-primary' onClick={() => handleShow(dateReservation)}>Réserver</span>

                                                            {(!showOffer) &&
                                                                <span className='btn btn-outline-primary' onClick={() => handleOfferShow(dateReservation)}>Faire une offre</span>
                                                            }

                                                            {showOffer &&
                                                                <div className="product-reservation-request-offer">
                                                                    <p>
                                                                        Vous pouvez accepter le tarif indiqué en cliquant sur le bouton Réserver,<br/>ou vous pouvez effectuer une contre-proposition en notant un nouveau tarif.
                                                                    </p>
                                                                    <div className="input-group mb-3">
                                                                        <input className="form-control input-price-offer" type="number" min="1" max={product.price * multiplicator} name="price-offer" value={priceOffer} onChange={handleChangePriceOffer} />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">€</span>
                                                                        </div>
                                                                    </div>

                                                                    {errPriceOffer &&
                                                                        <span style={{ color: 'red', textAlign: 'center', display: 'block' }}>{errPriceOffer}</span>
                                                                    }
                                                                    {errDateReservation &&
                                                                        <span style={{ color: 'red', textAlign: 'center', display: 'block' }}>{errDateReservation}</span>
                                                                    }
                                                                    <p>
                                                                        En confirmant, votre offre sera envoyée.
                                                                    </p>

                                                                    <div className="product-reservation-request-offer-buttons">
                                                                        <Button
                                                                            className='btn-sm'
                                                                            variant="outline-danger"
                                                                            onClick={handleOfferClose}>
                                                                            Annuler
                                                                        </Button>
                                                                        <Button
                                                                            className='btn-sm'
                                                                            variant="primary"
                                                                            onClick={handleOfferSubmit(user, product, priceOffer, dateReservation)}>
                                                                            Envoyer une offre
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                        : ''}

                                                    {user && product.user_id != user._id &&
                                                        <span className='btn btn-outline-primary' onClick={handleMessageCardShow}>Envoyer un message</span>
                                                    }

                                                    {showMessageCard &&
                                                        <div className="product-reservation-request-message">
                                                            <p>
                                                                Vous pouvez poser vos questions au loueur pour obtenir des précisions.
                                                            </p>
                                                            <textarea rows={3} className="form-control input-message" name="message" onChange={handleChangeMessage}>
                                                                {messageOffer}
                                                            </textarea>

                                                            {errMessage &&
                                                                <span style={{ color: 'red', textAlign: 'center', display: 'block', marginBottom: '20px' }}>{errMessage}</span>
                                                            }

                                                            <div className="product-reservation-request-message-buttons">
                                                                <Button
                                                                    className='btn-sm'
                                                                    variant="outline-danger"
                                                                    onClick={handleMessageCardClose}>
                                                                    Annuler
                                                                </Button>
                                                                <Button
                                                                    className='btn-sm'
                                                                    variant="primary"
                                                                    onClick={handleSendMessage(user, product, messageOffer)}>
                                                                    Envoyer
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <Modal show={showResa} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Confirmation de réservation</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Votre réservation est bientôt terminée.<br />Êtes-vous sûr de vouloir réserver ?</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="outline-secondary" onClick={handleClose}>
                                                    Annuler
                                                </Button>
                                                <Button disabled={multiplicator > 0 ? false : true} variant="primary" onClick={handleSubmit(user, product)}>
                                                    Confirmer
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="bigcontainer hide-mobile">
                {loading ? (<Loader />)
                    : (otherProductsSubCateg && (<section className="product-other-offers">
                        <div className="mediumcontainer">
                            <h2 className="product-other-offers-h2">Ceci peut également vous intéresser</h2>
                            <div className="row">
                                <Splide hasTrack={false}
                                    options={{
                                        perPage: 3,
                                        heightRatio: 1,
                                        loop: false,
                                        pagination: false,
                                        arrows: false,
                                        gap: '10px',
                                        breakpoints: {
                                            1200: {
                                                arrows: true,
                                                drag: true,
                                                perPage: 2,
                                                perMove: 1,
                                                loop: true,
                                                gap: '70px',
                                                padding: { left: 20, right: 100 },
                                            },
                                            768: {
                                                perPage: 1,
                                            },
                                            430: {
                                                gap: '10px',
                                                padding: { left: 10, right: 50 },
                                            },
                                        }
                                    }}
                                >
                                    <SplideTrack>
                                        {(otherProductsSubCateg.map((item, index) =>
                                            <SplideSlide key={index}>
                                                <a className='text-decoration-none' href={item.product_path} state={{ date: datesChecked }}>
                                                    <div className="card card-product mx-3 pb-5">
                                                        <div className='card-product-img'>
                                                            <img className="card-product-pic" src={item.image && JSON.parse(item.image).length != 0 ? process.env.REACT_APP_API_URL + JSON.parse(item.image)[0] : defaultTruck} alt='' />
                                                        </div>
                                                        <div className="card-product-body">
                                                            <h5 className="card-product-name">{item.name}</h5>
                                                            <p className="card-product-localisation">
                                                                <FontAwesomeIcon icon={faLocationDot} /> {city}
                                                            </p>
                                                            <div className="card-product-separation">
                                                                <p className="card-product-presentation">À partir de <br /><span
                                                                    className="card-product-price">{item.price}€/j</span></p>
                                                                {user && user.role == 'user' ?
                                                                    <a href={item.product_path} className="solu-btn solu-btn-primary solu-btn-sm">Je réserve</a>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </SplideSlide>
                                        ))}
                                    </SplideTrack>
                                    <div className="splide__arrows">
                                        <button className="splide__arrow splide__arrow--prev"><FontAwesomeIcon icon={faChevronRight} /></button>
                                        <button className="splide__arrow splide__arrow--next"><FontAwesomeIcon icon={faChevronRight} /></button>
                                    </div>
                                </Splide>
                            </div>
                        </div>
                    </section>)
                    )
                }
            </div>

            {loading ? (<Loader />) :
                <div className="sticky-reservation row">
                    <div className='col-8'>
                        <p className="sticky-reservation-text"><b>Location {product.name}</b><br/>{categoryParentName}</p>
                        <p className="sticky-reservation-text-price">À partir de <br /> <b>{product.price}€/j</b></p>
                    </div>
                    <div className='col-4 sticky-reservation-reserve'>
                        <span className='btn sticky-reservation-reserve-btn' onClick={handleShowReservation}>Réserver</span>
                    </div>
                </div>
            }
        </>
    );
}

export default ProductPage;
