import React from "react";

function LegalNoticePage() {
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <h2>Mentions légales</h2>
                    <h3>Editeur du site</h3>
                    <p>SASU SOLUSPOTS<br/>
                    Montant du capital social : 1 000€<br/>
                    RCS : 919 256 198 - Compiègne<br/>
                    Numéro de SIREN : 919 256 198 00012<br/>
                    Numéro de TVA : FR 18 919 256 198<br/>
                    Représentant légal : Nicolas GUESNET - Président<br/>
                    Siège social : 62 rue Louis Blanchet – Aumont-en-Halatte</p>
                    
                    <h3>Pour entrer en contact facilement avec l’éditeur du site</h3>
                    <p>Téléphone : 03 44 71 43 84<br/>
                    Adresse email : nicolas.guesnet@gmail.com</p>
                    
                    <h3>Responsable de la rédaction</h3>
                    <p>Le responsable de la rédaction est Monsieur Nicolas GUESNET.</p>
                    
                    <h3>Directeur de publication</h3>
                    <p>Le directeur de publication est Monsieur Nicolas GUESNET.</p>
                    
                    <h3>Développeur du site</h3>
                    <p>Ce site a été développé par la SAS MozArtsduWeb – https://mozartsduweb.com<br/>
                    Email :  contact@mozartsduweb.com  - Téléphone : +33 (0)3 44 21 05 70 -</p>
                    
                    <h3>Hébergeur du site</h3>
                    <p>Ce site est hébergé par la SAS OVH - 2 rue Kellerman – 59100 Roubaix (France)<br/>
                    Numéro de téléphone : +33 (0)9.72.10.10.07</p>
                </div>
            </div>
        </div>
    );
}

export default LegalNoticePage;
