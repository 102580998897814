import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResetPasswordForm = (props) => {
    const [errorLogin, setErrorLogin] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    let { token, email } = useParams();

    const submitForm = (values, action) => {
        axios.post(process.env.REACT_APP_API_URL + '/v1/password/reset', {
            email: values['email'],
            password: values['password'],
            token: token
          })
        .then((response) => {
            if (response.data.error) {
                setErrorLogin(response.data.error)
            }

            toast.success("Mot de passe réinitialisé !", {
                position: toast.POSITION.BOTTOM_RIGHT
            })

            // Go to homepage
            navigate('/connexion/')
        }).catch((error) => {
            this.setState({
                errorLogin : 'Erreur : Votre e-mail et/ou votre mot de passe est invalide.'
            });
        })
    }

    return (
        <div className='login-form'>
                {props.create ?
                    <>
                        <h1 className='login-title'>Créer votre mot de passe</h1>
                        <p className='login-text'>Choisissez votre mot de passe.</p>
                    </>
                :
                    <>
                        <h1 className='login-title'>Mot de passe oublié</h1>
                        <p className='login-text'>Pas de souci, nous allons vous envoyer un mail pour le réinitialiser&nbsp;!</p>
                    </>
                }
            <Formik initialValues={{
                email: email ? email : '',
                password: "",
                confirmPassword: ""
                }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('L\'e-mail est invalide.')
                            .required('L\'e-mail est requis.'),
                        password: Yup.string().required("Requis"),
                        confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Les mots de passe ne correspondent pas").required("Requis"),
                    })}
                    onSubmit={(values, action) => {submitForm(values, action)}}
            >
                {
                    ({ errors, status, touched, setFieldValue }) => {
                        return <Form>
                                {errorLogin && <div className="text-danger"><small>{errorLogin}</small></div>}
                                <div className="form-group mb-3">
                                    <Field id="email" name="email" type="text" placeholder="Votre adresse mail" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group input-password mb-3">
                                    <Field id="password" name="password" type={showPassword ? 'text' : 'password'} placeholder="Nouveau mot de passe" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                    <button className='button-hide-password' type="button" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </button>
                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group input-password mb-3">
                                    <Field id="confirmPassword" name="confirmPassword" type={showConfirmPassword ? 'text' : 'password'} placeholder="Confirmer le mot de passe" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                    <button className='button-hide-password' type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                        {showConfirmPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </button>
                                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group mt-3">
                                    {props.create ?
                                        <button type="submit" className="btn btn-primary w-100">Confirmer le mot de passe</button>
                                    :
                                        <button type="submit" className="btn btn-primary w-100">Réinitialiser le mot de passe</button>
                                    }
                                </div>
                            </Form>
                    }
                }
            </Formik>
        </div>
    );
}

export default ResetPasswordForm;
