import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../ReactApp';
import { useParams, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import ImageGallery from 'react-image-gallery';
// import '../sass/pages/worksite.scss';
import '../sass/components/Calendar.scss';
import { toast } from 'react-toastify';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faChevronUp, faCircleUser, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import Banner from "../components/Banner";
import { Link } from 'react-router-dom';
import defaultWorksite from '../images/default-worksite/default-worksite.png';
import '@splidejs/react-splide/css';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';

function WorksitePage() {
    const params = useParams();
    const worksiteId = params.worksite;
    const { user } = useAuth();

    const [position, setPosition] = useState([48.52, 2.19]);
    const [dates, setDates] = useState(['', '']);
    const [city, setCity] = useState('');
    const [toggleInfos, setToggleInfos] = useState(true);
    const [toggleDesc, setToggleDesc] = useState(true);
    const [toggleNeed, setToggleNeed] = useState(true);
    const [toggleLocation, setToggleLocation] = useState(true);
    const [iconInfos, setIconInfos] = useState(faChevronDown);
    const [iconDesc, setIconDesc] = useState(faChevronDown);
    const [iconNeed, setIconNeed] = useState(faChevronDown);
    const [iconLocation, setIconLocation] = useState(faChevronDown);

    useEffect(() => {
        if (position) {
            axios.get("https://api-adresse.data.gouv.fr/reverse/?lon=" + position[1] + "&lat=" + position[0] + "&type=municipality")
              .then((response) => {
                if (response.data.features[0].properties.label) {
                  setCity(response.data.features[0].properties.label)
                }
              }).catch((error) => {
                console.log(error)
              })
          }
    }, [position])


    const toggleFunc = useCallback(() => {
        (iconInfos === faChevronDown) ? setIconInfos(faChevronUp) : setIconInfos(faChevronDown);
        setToggleInfos(!toggleInfos);
    });
    const toggleFuncNeed = useCallback(() => {
        (iconNeed === faChevronDown) ? setIconNeed(faChevronUp) : setIconNeed(faChevronDown);
        setToggleNeed(!toggleNeed);
    });
    const toggleFuncDesc = useCallback(() => {
        (iconDesc === faChevronDown) ? setIconDesc(faChevronUp) : setIconDesc(faChevronDown);
        setToggleDesc(!toggleDesc);
    });
    const toggleFuncLocation = useCallback(() => {
        (iconLocation === faChevronDown) ? setIconLocation(faChevronUp) : setIconLocation(faChevronDown);
        setToggleLocation(!toggleLocation);
    });

    const [showMessageCard, setShowMessageCard] = useState(false);
    const handleMessageCardClose = () => setShowMessageCard(false);
    const handleMessageCardShow = () => { setShowMessageCard(true); }
    const [errMessage, setErrMessage] = useState(false);
    const [messageOffer, setMessageOffer] = useState('');
    const handleChangeMessage = (event) => setMessageOffer(event.target.value);

    const [openStickyResponsiveResa, setOpenStickyResponsiveResa] = useState(false);

    document.body.addEventListener('click', (e) => {
        let container = document.getElementsByClassName('worksite-reservation');

        if (container[0] && !container[0].contains(e.target)) {
            if (openStickyResponsiveResa) {
                setOpenStickyResponsiveResa(false);
            }
        }
    }, true);

    const handleCloseResponsiveResa = () => setOpenStickyResponsiveResa(false);

    const images = [
        {
            original: defaultWorksite,
            thumbnail: defaultWorksite,
        },
        {
            original: defaultWorksite,
            thumbnail: defaultWorksite,
        },
        {
            original: defaultWorksite,
            thumbnail: defaultWorksite,
        },
    ];

    function ClearErrorWithTimeout(error, setError) {
        useEffect(() => {
            if (error !== false) {
                const timeoutId = setTimeout(() => {
                    setError(false)
                }, 5000)
                return () => clearTimeout(timeoutId)
            }
        }, [error, setError])
    }

    ClearErrorWithTimeout(errMessage, setErrMessage);

        useEffect(() => {
            getWorksite(params.worksite);
        }, []);

    //supprime l'overflow du body lorsque le sticky réservation est affiché
    useEffect(() => {
        if (openStickyResponsiveResa) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

    }, [openStickyResponsiveResa])

    /* INFOS DU PRODUIT */
    const [worksiteRequest, setWorksiteRequest] = useState({ worksite: null, need_trucks: null, loading: true });
    const [company, setCompany] = useState({});
    const [companyPhoto, setCompanyPhoto] = useState('');
    const [worksiteImages, setWorksiteImages] = useState([]);
    const { worksite, need_trucks, loading } = worksiteRequest;

    const getWorksite = async (worksite_slug) => {
        //Suppression de l'id dans le slug
        worksite_slug = worksite_slug.substring(worksite_slug.indexOf("-") + 1);

        await axios.get(process.env.REACT_APP_API_URL + "/v1/worksites/" + params.worksite)
            .then((response) => {
                if (response.data) {
                    setWorksiteRequest({
                        worksite: response.data.worksite,
                        need_trucks: response.data.need_trucks,
                        loading: false
                    });
                    
                    setCompany(response.data.company)
                    setCompanyPhoto(response.data.company_image)
                    
                    const datesWorksite = JSON.parse(response.data.worksite.dates);
                    let datesWorksiteStart = new Date((datesWorksite[0] / 1000) * 1000);
                    datesWorksiteStart = ('0' + datesWorksiteStart.getDate()).slice(-2) + '/' + ('0' + (datesWorksiteStart.getMonth() + 1)).slice(-2) + '/' + datesWorksiteStart.getFullYear();
                    let datesWorksiteEnd = new Date((datesWorksite[1] / 1000) * 1000);
                    datesWorksiteEnd = ('0' + datesWorksiteEnd.getDate()).slice(-2) + '/' + ('0' + (datesWorksiteEnd.getMonth() + 1)).slice(-2) + '/' + datesWorksiteEnd.getFullYear();
                    setDates([datesWorksiteStart, datesWorksiteEnd])

                    setPosition([response.data.worksite.latitude, response.data.worksite.longitude])

                    if (JSON.parse(response.data.worksite.image).length > 0) {
                        JSON.parse(response.data.worksite.image).forEach((item) => {
                            setWorksiteImages(worksiteImages => [...worksiteImages, { 'original': process.env.REACT_APP_API_URL + item, 'thumbnail': process.env.REACT_APP_API_URL + item }]);
                        })
                    } else {
                        setWorksiteImages(images)
                    }
                }
            }).catch((error) => {
                console.log(error);
            })
    }


    const handleSendMessage = (user, worksite, messageOffer) => (event) => {
        event.preventDefault();

        setErrMessage(false);

        if (!messageOffer) {
            setErrMessage('Veuillez renseigner un message.')
            return;
        }

        let axios_params = {
            'user_id': user._id,
            'worksite_id': worksite.id,
            'message': messageOffer
        };

        axios.post(process.env.REACT_APP_API_URL + "/v1/send-message-worksite", axios_params)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    toast.success('Votre message a bien été envoyée ! Retrouvez la conversation dans votre espace messagerie.', {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        handleMessageCardClose();
    }

    /* POSITION DU MARKER SUR LA MAP (EMPLACEMENT DU PRODUIT) */
    const icon = L.icon({
        iconUrl: process.env.REACT_APP_API_URL + "/images/vendor/leaflet/dist/marker-icon.svg",
        iconSize: [26, 37], // size of the icon
        shadowSize: [30, 40], // size of the shadow
        iconAnchor: [26, 37], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 35],  // the same for the shadow
        popupAnchor: [-3, -50] // point from which the popup should open relative to the iconAnchor
    });

    return (
        <>
            {loading ? (<Loader />) :
                (
                    <>
                        <div className="container">
                            <div className="row">
                                <div className='col-12'>
                                    <h1 className="product-title"><strong>{worksite.name}</strong></h1>
                                    <p className="product-title-city">
                                        <FontAwesomeIcon icon={faLocationDot} /> {city} <span className='product-title-city-dot'></span> <FontAwesomeIcon icon={faCalendar} /> Du {dates[0]} au {dates[1]}
                                        </p>
                                    <hr />
                                </div>

                                {/* Visuel produit */}
                                <div className="col-12 col-md-6 col-lg-8">
                                    <div className="row">
                                        <div className='col-12'>
                                            <ImageGallery
                                                items={worksiteImages}
                                                showNav={false}
                                                showFullscreenButton={false}
                                                showBullets={false}
                                                showPlayButton={false}
                                                thumbnailPosition='right'
                                                additionalClass='product-gallery'
                                            />
                                        
                                            <h2 onClick={toggleFuncDesc} className='product-h2 product-h2-icon'>Descriptif <span><FontAwesomeIcon icon={iconDesc} /></span></h2>
                                            <Collapse in={toggleDesc}>
                                                <div>
                                                    {worksite.description}
                                                </div>
                                            </Collapse>

                                            <h2 onClick={toggleFuncNeed} className='product-h2 product-h2-icon'>Besoins du chantier <span><FontAwesomeIcon icon={iconNeed} /></span></h2>
                                            <Collapse in={toggleNeed}>
                                                <div className='row worksite-need'>
                                                    {Object.keys(need_trucks).map(function(key, index) {
                                                        return (
                                                        <div className='col-6 col-md-3' key={index}>
                                                            <div className='worksite-need-block'>
                                                                <img src={need_trucks[key].image} />
                                                                <span className='worksite-need-block-nb'>{need_trucks[key].nb}</span>
                                                                <p className='worksite-need-block-text'>{need_trucks[key].name}</p>
                                                            </div>
                                                        </div>
                                                        );
                                                    })}
                                                </div>

                                            </Collapse>

                                            {/* Informations */}
                                            <h2 onClick={toggleFunc} className='product-h2 product-h2-icon'>Informations <span><FontAwesomeIcon icon={iconInfos} /></span></h2>
                                            <Collapse in={toggleInfos}>
                                                <div>
                                                    <table className="table-informations">
                                                        <tbody>
                                                            <tr>
                                                                <td>Localisation</td>
                                                                <td colSpan="2">{city}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Date</td>
                                                                <td colSpan="2">Du {dates[0]} au {dates[1]}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Collapse>
                                            <h2 onClick={toggleFuncLocation} className='product-h2 product-h2-icon'>Localisation <span><FontAwesomeIcon icon={iconLocation} /></span></h2>
                                            <Collapse in={toggleLocation}>
                                                <div>
                                                    <MapContainer className='product-map' style={{ height: "500px" }} center={position} zoom={13} scrollWheelZoom={false}>
                                                        <TileLayer
                                                            // url="http://tiles.lyrk.org/ls/{z}/{x}/{y}?apikey=982c82cc765f42cf950a57de0d891076"
                                                            url="http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                                                            attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                            minZoom="0"
                                                            maxZoom="20"
                                                        />

                                                        <Marker position={position} icon={icon}></Marker>
                                                    </MapContainer>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>

                                {/* Formulaire de réservation */}
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className='product-reservation'>
                                        <button type="button" className="btn-close close-reservation" aria-label="Close" onClick={handleCloseResponsiveResa}></button>
                                        <form onSubmit={handleSendMessage(user, worksite)}>
                                            <div className="row">
                                                <div className='col-8'>
                                                    <p className='product-reservation-name'>{worksite.name}</p>
                                                </div>
                                                <div className="col-12">
                                                    <div className='product-reservation-company'>
                                                        {companyPhoto ?
                                                            <img src={companyPhoto ? process.env.REACT_APP_API_URL + companyPhoto : 'https://via.placeholder.com/60'} className='product-reservation-company-img' />
                                                        :
                                                            <FontAwesomeIcon icon={faCircleUser} />
                                                        }
                                                        {company ?
                                                            <Link to={`/entreprise/` + company.id} className='product-reservation-company-name'>{company ? company.name : ''}</Link>
                                                        : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className='product-reservation-request'>
                                                        {user &&
                                                            <span className='btn btn-outline-primary' onClick={handleMessageCardShow}>Envoyer un message</span>
                                                        }

                                                        {showMessageCard &&
                                                            <div className="product-reservation-request-message">
                                                                <p>
                                                                    Vous pouvez poser vos questions au loueur pour obtenir des précisions.
                                                                </p>
                                                                <textarea rows={3} className="form-control input-message" name="message" onChange={handleChangeMessage}>
                                                                    {messageOffer}
                                                                </textarea>

                                                                {errMessage &&
                                                                    <span style={{ color: 'red', textAlign: 'center', display: 'block', marginBottom: '20px' }}>{errMessage}</span>
                                                                }

                                                                <div className="product-reservation-request-message-buttons">
                                                                    <Button
                                                                        className='btn-sm'
                                                                        variant="outline-danger"
                                                                        onClick={handleMessageCardClose}>
                                                                        Annuler
                                                                    </Button>
                                                                    <Button
                                                                        className='btn-sm'
                                                                        variant="primary"
                                                                        onClick={handleSendMessage(user, worksite, messageOffer)}>
                                                                        Envoyer
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="bigcontainer hide-mobile">
                            {loading ? (<Loader />)
                                : (otherWorksiteSubCateg && (<section className="product-other-offers">
                                    <div className="mediumcontainer">
                                        <h2 className="product-other-offers-h2">Ceci peut également vous intéresser</h2>
                                        <div className="row">
                                            <Splide hasTrack={false} aria-label="..."
                                                options={{
                                                    perPage: 3,
                                                    heightRatio: 1,
                                                    loop: false,
                                                    pagination: false,
                                                    arrows: false,
                                                    gap: '10px',
                                                    breakpoints: {
                                                        1200: {
                                                            arrows: true,
                                                            drag: true,
                                                            perPage: 2,
                                                            perMove: 1,
                                                            loop: true,
                                                            gap: '70px',
                                                            padding: { left: 20, right: 100 },
                                                        },
                                                        768: {
                                                            perPage: 1,
                                                        },
                                                        430: {
                                                            gap: '10px',
                                                            padding: { left: 10, right: 50 },
                                                        },
                                                    }
                                                }}
                                            >
                                                <SplideTrack>
                                                    {(otherWorksiteSubCateg.map((item, index) =>
                                                        <SplideSlide key={index}>
                                                            <Link className='text-decoration-none' to={item.product_path}>
                                                                <div className="card card-product mx-3 pb-5">
                                                                    <div className='card-product-img'>
                                                                        <img className="card-product-pic" src={item.image && JSON.parse(item.image).length != 0 ? process.env.REACT_APP_API_URL + JSON.parse(item.image)[0] : defaultWorksite} alt='' />
                                                                    </div>
                                                                    <div className="card-product-body">
                                                                        <h5 className="card-product-name">{item.name}</h5>
                                                                        <p className="card-product-localisation">
                                                                            <FontAwesomeIcon icon={faLocationDot} /> {city}
                                                                        </p>
                                                                        <div className="card-product-separation">
                                                                            <p className="card-product-presentation">À partir de <br /><span
                                                                                className="card-product-price">{item.price}€/j</span></p>
                                                                            {user && user.role == 'user' ?
                                                                                <Link to={item.product_path} className="solu-btn solu-btn-primary solu-btn-sm">Je réserve</Link>
                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </SplideSlide>
                                                    ))}
                                                </SplideTrack>
                                                <div className="splide__arrows">
                                                    <button className="splide__arrow splide__arrow--prev"><FontAwesomeIcon icon={faChevronRight} /></button>
                                                    <button className="splide__arrow splide__arrow--next"><FontAwesomeIcon icon={faChevronRight} /></button>
                                                </div>
                                            </Splide>
                                        </div>
                                    </div>
                                </section>)
                                )
                            }
                        </div> */}
                    </>
                )
            }
        </>
    );
}

export default WorksitePage;
