import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import ReservationRow from './cards/ReservationRow';
import ReservationRowCardMobile from './cards/ReservationRowCardMobile';
import ReservationRowAccepted from './cards/ReservationRowAccepted';
import ReservationRowAcceptedCardMobile from './cards/ReservationRowAcceptedCardMobile';
import { useAuth } from '../../ReactApp';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import defaultTruck from '../../images/default-trucks/defaut-camion-malaxeur.png';

const Reservations = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [pendingReservations, setPendingReservations] = useState([]);
    const [validateReservation, setValidateReservation] = useState([]);
    const [historyReservation, setHistoryReservation] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [reloadComponent, setReloadComponent] = useState(false);
    const [loadingOffer, setLoadingOffer] = useState(false);

    const getReservations = (status) => {
        let params = {
            'user_id': user._id,
            'status': status
        };

        axios.post(process.env.REACT_APP_API_URL + "/v1/reservations", params)
        .then((response) => {
            if (response.data && response.data.length > 0) {
                if (status === 'pending') {
                    setPendingReservations(groupBy(response.data, 'product_id'));
                } else if (status === 'accepted') {
                    setValidateReservation(groupBy(response.data, 'product_id'));
                } else if (status === 'history') {
                    setHistoryReservation(groupBy(response.data, 'product_id'));
                }
            } else {
                if (status === 'pending') {
                    setPendingReservations([]);
                } else if (status === 'accepted') {
                    setValidateReservation([]);
                }
            }

            setLoading(false);
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        getReservations('pending');
        getReservations('accepted');
        getReservations('history');

    }, [reloadComponent])

    function afficher_dates_consecutives(dates) {
        let result = "";

        const nbDates = dates.length;
        dates.sort(); // Tri pour faciliter le parcours
        let dateStart = dates[0];
        let dateEnd = dates[0];

        for (let i = 1; i < nbDates; i++) {
            if (dates[i] === new Date(new Date(dateEnd).setDate(new Date(dateEnd).getDate() + 1)).toISOString().substring(0, 10)) {
                dateEnd = dates[i];
            } else {
                if (dateStart === dateEnd) {
                    const formattedDateStart = new Date(dateStart);
                    result += formattedDateStart.toLocaleDateString("fr") + ", ";
                } else {
                    const formattedDateStart = new Date(dateStart);
                    const formattedDateEnd = new Date(dateEnd);
                    result += "Du " + formattedDateStart.toLocaleDateString("fr") + " au " + formattedDateEnd.toLocaleDateString("fr") + ", ";
                }
                dateStart = dates[i];
                dateEnd = dates[i];
            }
        }

        if (dateStart === dateEnd) {
            const formattedDateStart = new Date(dateStart);
            result += formattedDateStart.toLocaleDateString("fr") + ", ";
        } else {
            const formattedDateStart = new Date(dateStart);
            const formattedDateEnd = new Date(dateEnd);
            result += "Du " + formattedDateStart.toLocaleDateString("fr") + " au " + formattedDateEnd.toLocaleDateString("fr") + ", ";
        }
        
        if (result.endsWith(', ')) {
            result = result.slice(0, -2); // Suppression de la virgule et de l'espace
        }
      
        return result;
    }

    // Accepts the array and key
    const groupBy = (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {}); // empty object is the initial value for result object
    };

    const updateStatusReservation = (id, status) => {
        setLoadingOffer(true)

        axios.post(process.env.REACT_APP_API_URL + '/v1/reservations/' + id, {
            status: status
        }).then(function () {
            setReloadComponent(!reloadComponent)
        }).catch(function (error) {
            console.log(error);
        });

        setLoadingOffer(false)
    }

    return (
        <div className="own_reservations">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Mes réservations</h2>
                        <Tabs
                            defaultActiveKey="waiting"
                            className="profile-nav-tabs"
                        >
                            <Tab eventKey="waiting" 
                                title={Object.keys(pendingReservations).length > 0 ? 
                                    (Object.keys(pendingReservations).length > 9) ?
                                    <span>En attente <div className='profile-nav-tabs-counter-double'><span>{Object.keys(pendingReservations).length}</span></div></span>
                                    : <span>En attente <div className='profile-nav-tabs-counter'><span>{Object.keys(pendingReservations).length}</span></div></span>
                                : <span>En attente</span>}
                                tabClassName="btn">
                                <div className="own_reservations_waiting hide-mobile-xs">
                                    {loading ? (<Loader />)
                                        :
                                        (Object.keys(pendingReservations).length > 0) ?
                                            pendingReservations && Object.keys(pendingReservations).map((keyName, i) => (
                                                <div className="own_reservations_waiting_array" key={i}>
                                                    <div className="own_reservations_waiting_titre">
                                                        <img width="53" height="53" src={pendingReservations[keyName][0]['product_image']
                                                            ? process.env.REACT_APP_API_URL + pendingReservations[keyName][0]['product_image']
                                                            : defaultTruck} alt={pendingReservations[keyName][0]['product_name']} />
                                                        <div>
                                                            {pendingReservations[keyName][0]['product_name']}
                                                            <span> - {pendingReservations[keyName][0]['category_name']} - {pendingReservations[keyName][0]['registration']}</span>
                                                        </div>
                                                    </div>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Date de la demande</th>
                                                                <th scope="col">{user.role === 'user' ? 'Loueur' : 'Client'}</th>
                                                                <th scope="col">Date</th>
                                                                <th scope="col">Offre</th>
                                                                <th scope="col">Statut</th>
                                                                <th scope="col">Message</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {loadingOffer ?
                                                                <Loader />
                                                            :
                                                                pendingReservations[keyName].map((item, index, row) =>
                                                                    <ReservationRow key={index} item={item} updateStatusReservation={updateStatusReservation} />
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ))
                                            : 'Aucune réservation trouvée.'
                                    }
                                </div>
                                <div className="own_reservations_waiting hide-desktop hide-tablet">
                                    {loading ? (<Loader />)
                                        :
                                        (Object.keys(pendingReservations).length > 0) ?
                                            pendingReservations && Object.keys(pendingReservations).map((keyName, i) => (
                                                <div className="own_reservations_waiting_mobilecard" key={i}>
                                                    <div className="own_reservations_waiting_titre">
                                                        <img width="53" height="53" src={pendingReservations[keyName][0]['product_image']
                                                            ? process.env.REACT_APP_API_URL + pendingReservations[keyName][0]['product_image']
                                                            : defaultTruck} alt={pendingReservations[keyName][0]['product_name']} />
                                                        <div>
                                                            {pendingReservations[keyName][0]['product_name']}
                                                            <span> - {pendingReservations[keyName][0]['category_name']} - {pendingReservations[keyName][0]['registration']}</span>
                                                        </div>
                                                    </div>
                                                    {pendingReservations[keyName].map((item, index, row) =>
                                                        <ReservationRowCardMobile key={index} item={item} updateStatusReservation={updateStatusReservation} />
                                                    )}
                                                </div>
                                            ))
                                            : 'Aucune réservation trouvée.'
                                    }
                                </div>
                            </Tab>

                            <Tab eventKey="in-progress" 
                                title={Object.keys(validateReservation).length > 0 ? 
                                    (Object.keys(validateReservation).length > 9) ?
                                    <span>Validés <div className='profile-nav-tabs-counter-double'><span>{Object.keys(validateReservation).length}</span></div></span>
                                    : <span>Validés <div className='profile-nav-tabs-counter'><span>{Object.keys(validateReservation).length}</span></div></span>
                                : <span>Validés</span>}
                                tabClassName="btn">
                                <div className="own_reservations_waiting hide-mobile-xs">
                                    {loading ? (<Loader />)
                                        :
                                        (Object.keys(validateReservation).length > 0) ? (
                                            validateReservation && Object.keys(validateReservation).map((keyName, i) => (
                                                <div className="own_reservations_waiting_array" key={i}>
                                                    <div className="own_reservations_waiting_titre">
                                                        <img width="53" height="53" src={validateReservation[keyName][0]['product_image']
                                                            ? process.env.REACT_APP_API_URL + validateReservation[keyName][0]['product_image']
                                                            : defaultTruck} alt={validateReservation[keyName][0]['product_name']} />
                                                        <div>
                                                            {validateReservation[keyName][0]['product_name']}
                                                            <span> - {validateReservation[keyName][0]['category_name']} - {validateReservation[keyName][0]['registration']}</span>
                                                        </div>
                                                    </div>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Client</th>
                                                                <th scope="col">Date</th>
                                                                <th scope="col">Prix</th>
                                                                <th scope="col">Statut</th>
                                                                <th scope="col">Message</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {validateReservation[keyName].map((item, index, row) =>
                                                                <ReservationRowAccepted key={index} item={item} updateStatusReservation={updateStatusReservation} />
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )))
                                        :
                                            'Aucune réservation trouvée.'
                                    }
                                </div>
                                <div className="own_reservations_waiting hide-desktop hide-tablet">
                                    {loading ? (<Loader />)
                                        :
                                        (Object.keys(validateReservation).length > 0) ? (
                                            validateReservation && Object.keys(validateReservation).map((keyName, i) => (
                                                <div className="own_reservations_waiting_mobilecard" key={i}>
                                                    <div className="own_reservations_waiting_titre">
                                                        <img width="53" height="53" src={validateReservation[keyName][0]['product_image']
                                                            ? process.env.REACT_APP_API_URL + validateReservation[keyName][0]['product_image']
                                                            : defaultTruck} alt={validateReservation[keyName][0]['product_name']} />
                                                        <div>
                                                            {validateReservation[keyName][0]['product_name']}
                                                            <span> - {validateReservation[keyName][0]['category_name']} - {validateReservation[keyName][0]['registration']}</span>
                                                        </div>
                                                    </div>
                                                    {validateReservation[keyName].map((item, index, row) =>
                                                        <ReservationRowAcceptedCardMobile key={index} item={item} updateStatusReservation={updateStatusReservation} />
                                                    )}
                                                </div>
                                            )))
                                        : 'Aucune réservation trouvée.'
                                    }
                                </div>
                            </Tab>

                            <Tab eventKey="history" title="Historique" tabClassName="btn">
                                <div className="row no-gutters hide-mobile-xs">

                                {loading ?
                                    (<Loader />)
                                :
                                (Object.keys(historyReservation).length > 0) ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Camion</th>
                                                <th scope="col">Client</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Prix</th>
                                                <th scope="col">Statut</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                             {historyReservation && Object.keys(historyReservation).map((keyName, i) => (

                                                    (historyReservation[keyName].map((item, index, row) => (
                                                        <tr key={index}>
                                                            <td>{historyReservation[keyName][index]['product_name']} - {historyReservation[keyName][index]['registration']}</td>
                                                            <td>{historyReservation[keyName][index]['renter']}</td>
                                                            <td>
                                                                {historyReservation[keyName][index]['dates'].map((item, index, row) => {

                                                                    const date = new Date(item); // créer un objet Date à partir de la date au format texte
                                                                    const jour = date.getDate().toString().padStart(2, "0"); // obtenir le jour et ajouter un zéro devant si nécessaire
                                                                    const mois = (date.getMonth() + 1).toString().padStart(2, "0"); // obtenir le mois et ajouter un zéro devant si nécessaire
                                                                    const annee = date.getFullYear(); // obtenir l'année

                                                                    const dateFormatee = `${jour}/${mois}/${annee}`; // formater la date en "dd/mm/yyyy"
                                                                    return (
                                                                        <span key={index}>
                                                                            {dateFormatee}
                                                                            {(index + 1 !== row.length) && ', '}
                                                                        </span>
                                                                    );
                                                                })}
                                                            </td>
                                                            {historyReservation[keyName][index]['price_offer'] != null ?
                                                                <td>
                                                                    <span className={'offre-history-' + historyReservation[keyName][index]['status']}>{historyReservation[keyName][index]['price']}€</span>
                                                                    <strike className='offre-strike'>{historyReservation[keyName][index]['price_offer']}€</strike>
                                                                </td>
                                                                :
                                                                <td><span className={'offre-' + historyReservation[keyName][index]['status']}>{historyReservation[keyName][index]['price']}€</span></td>
                                                            }

                                                            {historyReservation[keyName][index]['status'] == 'pending' || historyReservation[keyName][index]['status'] == 'refused' ?
                                                                <td><div className={'btn-statut btn-statut-refused'}>Refusé</div></td>
                                                            : 
                                                                <td><div className={'btn-statut btn-statut-finish'}>Terminé</div></td>
                                                            }

                                                        </tr>
                                                    )))
                                                ))}
                                        </tbody>
                                    </table>) : 'Aucune réservation trouvée dans l\'historique.'}
                                </div>

                                <div className="row no-gutters hide-desktop hide-tablet">
                                {loading ?
                                    (<Loader />)
                                :
                                (historyReservation.length > 0) ? (
                                    <>
                                    {historyReservation && Object.keys(historyReservation).map((keyName, i) => (
                                        (historyReservation[keyName].map((item, index, row) => (
                                            <div className='card card-reservation'>
                                                <div className='card-reservation-left'>
                                                    <div className='card-reservation-title'>{historyReservation[keyName][index]['product_name']} - {historyReservation[keyName][index]['registration']}</div>
                                                    <div>{historyReservation[keyName][index]['renter']}</div>
                                                    <div>
                                                        {afficher_dates_consecutives(historyReservation[keyName][index]['dates'])}
                                                    </div>
                                                    {historyReservation[keyName][index]['price_offer'] != null ?
                                                        <div>
                                                            <span className={'offre-' + historyReservation[keyName][index]['status']}>{historyReservation[keyName][index]['price']}€</span>
                                                            <strike className='offre-strike'>{historyReservation[keyName][index]['price_offer']}€</strike>
                                                        </div>
                                                        :
                                                        <div><span className={'offre-' + historyReservation[keyName][index]['status']}>{historyReservation[keyName][index]['price']}€</span></div>
                                                    }
                                                </div>
                                                <div className='card-reservation-right'>
                                                    <div></div>
                                                    <div className='card-reservation-actionzone'>
                                                        <div className={'btn-statut btn-statut-' + historyReservation[keyName][index]['status']}>
                                                            {(() => {
                                                                switch (historyReservation[keyName][index]['status']) {
                                                                    case 'accepted':
                                                                    return 'Accepté';
                                                                    case 'refused':
                                                                    return 'Refusé';
                                                                    case 'pending':
                                                                    return 'En attente';
                                                                    default:
                                                                    return 'Statut inconnu';
                                                                }
                                                            })()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )))
                                    ))}
                                    </>
                                )
                                : 'Aucune réservation trouvée dans l\'historique.'}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reservations;
