import { useState, useEffect } from "react";
import { AuthContext } from '../ReactApp';
import {useLocation, useNavigate} from "react-router-dom";
import axios from 'axios';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const loadAuth = async () => {
      const storedToken = window.localStorage.getItem("soluspot-token");

      if (storedToken) {
        try {
          const response = await axios.get(process.env.REACT_APP_API_URL + "/v1/check-auth/" + storedToken);
          setUser(response.data.content);
        } catch (error) {
          // Gérer les erreurs de requête, par exemple si le jeton est invalide
          console.error("Erreur lors de la vérification de l'authentification :", error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
    };

    loadAuth(); // Appel de la fonction au montage du composant
  }, []);

  const logIn = userData => {
    window.localStorage.setItem("soluspot-token", userData.token);

    setUser(userData);
  };

  const logOut = () => {
    window.localStorage.removeItem("soluspot-token")

    setUser(null)

    if (location.pathname == "/") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };


  const value = {
      user,
      onLogIn: logIn,
      onLogout: logOut,
    };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
