import React from "react";

function TermsOfServicePage() {
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <h2>Conditions Générales d'Utilisation</h2>
                    <p>Les présentes conditions générales d’utilisation ont pour objet de définir les modalités et conditions d’utilisation du site https://soluspots.fr/</p>
                    
                    <h3>Objectif et qualité des contenus</h3>
                    <p>Ce site a pour objectif de communiquer du contenu informatif sur les services proposés par l’éditeur du site. Le site s’efforce de fournir une information de qualité   et   vérifiée.   Si   vous   constatez   une   erreur   typographique   ou   si   une information vous semble inexacte, merci de nous en informer en nous adressant un email. Le site est susceptible d’être complété ou modifié, à tout moment, notamment en fonction des évolutions technologiques.</p>

                    <h3>Accès & Utilisation du site</h3>
                    <p>Tout abus dans l’accès ou l’utilisation du site, ou tout autre usage que celui auquel il est destiné, sont interdits. Il est également strictement interdit de mettre en péril l’intégrité, l’utilisation ou les conditions de fonctionnement du serveur ou d’interférer ou de tenter d’interférer avec le bon fonctionnement du site.</p>
                    <p>L’accès au site peut être suspendu pour des raisons de maintenance ou de mise à jour, pour une cause due à la survenance d’un cas fortuit ou de force majeure habituellement reconnu par la jurisprudence française.</p>

                    <h3>Propriété intellectuelle</h3>
                    <p>Le site internet ainsi que son contenu est protégé au titre des droits de propriété intellectuelle au profit de l’éditeur du site. Il est interdit de copier, reproduire, extraire, diffuser, tout ou partie, du site ou de ses éléments, sur quelque support que ce soit sans l’accord écrit et préalable de l’éditeur du site. Le non-respect de ces droits peut engager les responsabilités civile et pénale du contrevenant.</p>

                    <h3>Crédit photographies</h3>
                    <p>Les photographies, images, illustrations ainsi que tous les éléments graphique du site sont la propriété pleine et entière de l’éditeur du site pour les avoir achetées à l’Agence Sister (Chantilly – 60) ou sur le site Adobestock en licence standard.</p>

                    <h3>Données personnelles</h3>
                    <p>L’éditeur du site s'engage à ce que la collecte et le traitement des données personnelles effectués à partir du présent site soient conformes au RGPD et à la loi Informatique et Libertés.</p>
                    <p>Il vous informe que les données personnelles recueillies via les formulaires de contact et d’inscription des profils font l’objet d’un traitement informatique par l’éditeur du site destiné à la gestion des demandes des internautes et/ou à l’inscription des profils. La base légale de ce traitement est l’intérêt légitime l’éditeur du site. Les données traitées, qui sont nom, prénom, e-mail, entreprise, numéro   de   téléphone,   photographie   sont   issues   de   l’enregistrement   des différents champs remplis sur le(s) formulaire(s) par l’utilisateur du site.</p>
                    <p>Les données personnelles collectées ne sont pas transférées dans un pays hors de l’Union Européenne.</p>
                    <p>Les données personnelles collectées via notre site web sont conservées pendant une durée de 3 ans à compter du jour de leur collecte ou bien tant que la personne concernée ne se désinscrit pas ou en nous adressant une demande par email à l'adresse : nicolas.guesnet@gmail.com</p>
                    <p>Conformément à la législation sur la protection des données à caractère personnel, vous bénéficiez d’un droit d’accès, de rectification, de suppression, de portabilité aux informations qui vous concernent, que vous pouvez exercer en nous adressant un e-mail à l’adresse : nicolas.guesnet@gmail.com</p>
                    <p>Vous pouvez consulter le site cnil.fr pour plus d’informations sur vos droits.</p>
                    <p>Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant. Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés», ne sont pas respectés, vous pouvez introduire une réclamation auprès de la CNIL.</p>
                    
                    <h3>Connexion et sécurité</h3>
                    <p>L’accès et l’utilisation du site sont sécurisés par un antivirus, pare-feu, sauvegardes journalières, permettant d’assurer la sécurité et l’intégrité des données échangées de bout en bout, entre le poste de l’internaute et notre site. Les   données   personnelles   recueillies   sont   protégées   grâce   au   chiffrement SSL/TLS,   au   protocole   HTTPS   et   une   protection   contre   les   attaques   CSRF</p>
                    
                    <h3>Les liens hypertextes</h3>
                    <p>Il est interdit de mettre en place un lien hypertexte vers le présent site sans l’accord écrit et préalable de l’éditeur. En aucun cas, ce dernier ne peut être tenu pour responsable du contenu des sites qui font l’objet d’un lien hypertexte à partir du présent site.</p>
                    
                    <h3>Droit applicable & Juridiction compétente</h3>
                    <p>Le présent site est régi par le droit français et relève de la compétence exclusive des tribunaux du lieu du siège social de l’éditeur du site.</p>
                </div>
            </div>
        </div>
    );
}

export default TermsOfServicePage;
