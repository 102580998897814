import React from 'react';
import {useNavigate} from 'react-router-dom';
import Loader from '../../components/Loader';
import {useAxios} from '../tools/useAxios';

function CategoryCard(props) {
    //Liste des sous-catégories + loader
    const [loading, subcategories] = useAxios(process.env.REACT_APP_API_URL + "/v1/categories", 'post', {'category_id': props.item.id});

    //Permet de rediriger (ici avec la sous-catégorie en state)
    const navigate = useNavigate();

    //Redirection vers la (catégorie ou sous-catégorie) en la passant en params
    const redirectWithParams = (item = false) => {
        navigate('/resultat', {state: { dataId: item.id, categoryProduct: item.name }});
    }

    return (
        <>
            {loading ? (<Loader/>) :
                (
                    <div className="category-card-content" onClick={() => redirectWithParams(props.item, true)}>
                        <div className="category-card-content-block">
                            <a onClick={() => redirectWithParams(props.item)}>
                                <img className="category-card-content-block-img" src={props.item.image}
                                     alt={props.item.name}/>
                                <p className="category-card-content-block-text">{props.item.name}</p>
                            </a>
                            <ul className="category-card-content-block-list">
                                {subcategories ?
                                    (subcategories.map((item, index) => (
                                            <li key={index} className="category-card-content-block-list-item">
                                                <a>{item.name}</a></li>
                                        ))
                                    ) : ''}
                            </ul>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default CategoryCard;
