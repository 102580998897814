import React from "react";

function PrivacyPolicyPage() {
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <h2>Politique de confidentialité</h2>
                    <p>Un "cookie" est un fichier de taille limitée, généralement constitué de lettres et de chiffres, envoyé par le serveur internet au fichier cookie du navigateur situé sur le disque dur de votre ordinateur.</p>
                    <p>Le présent site utilise différents cookies ayant pour finalités de mesurer et d’analyser l’audience du site, d’optimiser le site et ses services, d’améliorer l’expérience des utilisateurs et la performance du site.</p>
                    <p>Conformément à la législation, les données de fréquentation du site ainsi que les cookies permettant la traçabilité des internautes et les adresses IP ne sont pas conservés au-delà de 13 mois à compter de la première visite.</p>
                    <p>Le site utilise des cookies dits « nécessaires » qui sont absolument essentiels au bon fonctionnement du site. Cette catégorie de cookies comprend uniquement les cookies qui garantissent les fonctionnalités de base et les fonctionnalités de sécurité du site. Ces cookies ne stockent aucune donnée personnelle et par conséquent, ne requièrent pas le consentement des internautes.</p>
                    <p>Le site utilise également des cookies dits « non nécessaires », à savoir :</p>
                    <ul>
                        <li>Google Analytics (Collecte des Informations sur le comportement des visiteurs)</li>
                        <li>FACEBOOK PIXEL (suivi des conversions, le ciblage publicitaire et l'analyse du comportement des visiteurs sur un site web)</li>
                        <li>HOTJAR (collecte des informations, telles que les mouvements de souris, les clics, les enregistrements de sessions, les sondages et les retours des utilisateurs.)</li>
                        <li>YSC : Ce cookie est défini par YouTube pour suivre les vues des vidéos intégrées.</li>
                        <li>VISITOR_INFO1_LIVE : Ce cookie est défini par Youtube pour suivre les préférences de l'utilisateur concernant les vidéos Youtube intégrées dans les sites ; il peut également déterminer si le visiteur du site Web utilise la nouvelle ou l'ancienne version de l'interface Youtube.</li>
                    </ul>

                    <p> Ils ne sont pas particulièrement nécessaires au fonctionnement du site et qui sont utilisés spécifiquement pour collecter des données personnelles des utilisateurs, pour lesquels Il est obligatoire d'obtenir le consentement des utilisateurs avant d'exécuter ces cookies sur le site.</p>
                    
                    <h4>Safari 8+</h4>
                    <ul>
                        <li>Ouvrez le menu Safari et cliquez sur Effacer l’historique.</li>
                        <li>Sélectionnez tout l'historique puis cliquez sur Effacer l’historique.</li>
                        <li>Redémarrez votre navigateur.</li>
                    </ul>
                    <h4>Safari 7</h4>
                    <ul>
                        <li>Ouvrez le menu Safari et cliquez sur Réinitialiser Safari.</li>
                        <li>Assurez-vous d'avoir coché la case Supprimer toutes les données de sites web puis cliquez sur Réinitialiser.</li>
                        <li>Redémarrez votre navigateur.</li>
                    </ul>
                    <h4>Internet Explorer</h4>
                    <ul>
                        <li>Cliquez sur le rouage en haut à droite de la fenêtre du navigateur, passez la souris sur Sécurité et sélectionnez Supprimer l’historique de navigation.</li>
                        <li>Décochez Conserver les données des sites Web favoris en haut du menu.</li>
                        <li>Assurez-vous que les cases Fichiers Internet temporaires et Cookies sont cochées, puis cliquez sur le bouton Supprimer.</li>
                        <li>Cliquez sur OK pour fermer le menu, puis fermez et relancez votre navigateur.</li>
                    </ul>
                    <h4>Edge</h4>
                    <ul>
                        <li>Ouvrez le menu Safari et cliquez sur Effacer l’historique.</li>
                        <li>Sélectionnez tout l'historique puis cliquez sur Effacer l’historique.</li>
                        <li>Redémarrez votre navigateur.</li>
                    </ul>
                    <h4>Google Chrome</h4>
                    <ul>
                        <li>Cliquez sur le menu en haut à droite.</li>
                        <li>Passez la souris sur Plus d'outils puis sur Effacer les données de navigation.</li>
                        <li>Pour Effacer les éléments : sélectionnez tous.</li>
                        <li>Assurez-vous que les cases Images et fichiers en cache et Cookies et autres données de site et de plug-in soient cochées puis cliquez sur Effacer les données de navigation.</li>
                        <li>Redémarrez votre navigateur.</li>
                    </ul>
                    <h4>Pour Firefox</h4>
                    <ul>
                        <li>Sélectionnez le menu en haut à droite (≡) et cliquez sur Historique, Effacer l'historique récent puis cochez la case Cookies.</li>
                        <li>En face d'Intervalle à effacer, sélectionnez tout.</li>
                        <li>Assurez-vous que la case Cache soit cochée dans la section Détails, puis cliquez sur Effacer Maintenant.</li>
                        <li>Redémarrez votre navigateur.</li>
                    </ul>
                    
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
