import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Col, Row } from 'react-bootstrap';

const ContactForm = (props) => {
    const [errorLogin, setErrorLogin] = useState();

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                company: '',
                siren: '',
                message: ''
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string()
                    .required('Le nom complet est requis'),
                email: Yup.string()
                    .email('L\'email est invalide')
                    .required('L\'email est requis'),
                company: Yup.string()
                    .required('Le nom de votre entreprise est requis'),
                siren: Yup.string()
                    .required('Le siren est requis'),
                message: Yup.string()
                    .min(8, 'Le message doit contenir au moins 8 caractères.')
                    .required('Le message est requis.')
            })}
            onSubmit={(values, {resetForm}) => {
                axios.post(process.env.REACT_APP_API_URL + '/v1/send-message', values).then(response => {
                    if (response.status === 200) {

                        toast.success("Votre message a bien été envoyé.", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        })

                        resetForm({values: ''})
                    }
                }).catch(function (error) {
                    console.log(error);
                    setErrorLogin('Une erreur s\'est produite, vérifiez vos informations.');
                })
            }}
            render={({ errors, status, touched }) => (
                <Form>
                    {errorLogin && <div className="text-danger"><small>{errorLogin}</small></div>}
                    <div className="form-group">
                        <label htmlFor="name">Votre nom *</label>
                        <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Votre adresse mail *</label>
                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <Row>
                        <Col sm={12} md={6}>
                            <div className="form-group">
                                <label htmlFor="message">Le nom de votre entreprise *</label>
                                <Field name="company" type="text" className={'form-control' + (errors.company && touched.company ? ' is-invalid' : '')} />
                                <ErrorMessage name="company" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className="form-group">
                                <label htmlFor="message">Siren *</label>
                                <Field name="siren" type="text" className={'form-control' + (errors.siren && touched.siren ? ' is-invalid' : '')} />
                                <ErrorMessage name="siren" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group">
                    <label htmlFor="message">Votre message *</label>
                        <Field name="message" as="textarea" rows="5" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                        <ErrorMessage name="message" component="div" className="invalid-feedback" />
                    </div>
                    <button type="submit" className="btn btn-primary mr-2 my-5">Envoyer</button>
                </Form>
            )}
        />
    );
}

export default ContactForm;
