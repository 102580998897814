import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import VisuelPlusPopulaire from "../images/discover/VISUEL_LE_PLUS_POPULAIRE_PROMO.png";

function Prices() {
    return (
        <Row className="mt-5" style={{alignContent: "stretch"}}>
            <Col xs={12} md={6} xl={3} className="my-4">
                <div className="discover-section-price-cards">
                    <h2 className="discover-section-price-cards-title">Basic</h2>
                    <hr className="discover-section-price-cards-separator"/>
                    <p className="discover-section-price-cards-number"><span
                        className="discover-section-price-cards-number-special">149 €</span> <br/> /mois</p>
                    <p className="discover-section-price-cards-promo"><span
                        className="discover-section-price-cards-promo-special">99 €</span> <br/> /mois</p>
                    <ul className="discover-section-price-cards-list">
                        <li className="discover-section-price-cards-list-item">1 véhicule*</li>
                        <li className="discover-section-price-cards-list-item">Espace client personnalisé</li>
                        <li className="discover-section-price-cards-list-item">Service d'assistance</li>
                    </ul>
                    <div className="discover-section-price-cards-button">
                        <Link to="/inscription" className="solu-btn-outline">Je profite de cette offre&nbsp;!</Link>
                        <small>*simultanément en ligne</small>
                    </div>
                </div>
            </Col>

            <Col xs={12} md={6} xl={3} className="my-4">
                <div className="discover-section-price-cards">
                    <div className="discover-section-price-cards-shape">
                        <img src={VisuelPlusPopulaire} alt="" /> 
                        <span className="discover-section-price-cards-shape-title">Le plus populaire</span>
                    </div>
                    <h2 className="discover-section-price-cards-title discover-section-price-cards-title-popular">Premium</h2>
                    <hr className="discover-section-price-cards-separator"/>
                    <p className="discover-section-price-cards-number"><span
                        className="discover-section-price-cards-number-special">249 €</span> <br/> /mois</p>
                    <p className="discover-section-price-cards-promo"><span
                        className="discover-section-price-cards-promo-special">199 €</span> <br/> /mois</p>
                    <ul className="discover-section-price-cards-list">
                        <li className="discover-section-price-cards-list-item">3 véhicules*</li>
                        <li className="discover-section-price-cards-list-item">Espace client personnalisé</li>
                        <li className="discover-section-price-cards-list-item">Service d'assistance</li>
                        <li className="discover-section-price-cards-list-item">Avantage partenaire avec GPS Geocoyote</li>
                    </ul>
                    <div className="discover-section-price-cards-button">
                        <Link to="/inscription" className="solu-btn-outline">Je profite de cette offre&nbsp;!</Link>
                        <small>*simultanément en ligne</small>
                    </div>
                </div>
            </Col>
            <Col xs={12} md={6} xl={3} className="my-4">
                <div className="discover-section-price-cards">
                    <h2 className="discover-section-price-cards-title">Exclusive</h2>
                    <hr className="discover-section-price-cards-separator"/>
                    <p className="discover-section-price-cards-number"><span
                        className="discover-section-price-cards-number-special">349 €</span> <br/> /mois</p>
                    <p className="discover-section-price-cards-promo"><span
                        className="discover-section-price-cards-promo-special">299 €</span> <br/> /mois</p>
                    <ul className="discover-section-price-cards-list">
                        <li className="discover-section-price-cards-list-item">6 véhicules*</li>
                        <li className="discover-section-price-cards-list-item">Espace client personnalisé</li>
                        <li className="discover-section-price-cards-list-item">Service d'assistance</li>
                        <li className="discover-section-price-cards-list-item">Nombreux avantages partenaires<br/>dont GPS Geocoyote</li>
                    </ul>
                    <div className="discover-section-price-cards-button">
                        <Link to="/inscription" className="solu-btn-outline">Je profite de cette offre&nbsp;!</Link>
                        <small>*simultanément en ligne</small>
                    </div>
                </div>
            </Col>
            <Col xs={12} md={6} xl={3} className="my-4">
                <div className="discover-section-price-cards">
                    <br/>
                    <p className="discover-section-price-cards-number" style={{fontSize: 21}} >Au delà de 6 véhicules,<br/>contacter le service commercial.</p>
                    <ul className="discover-section-price-cards-list">
                        <li className="discover-section-price-cards-list-item">Véhicules illimités*</li>
                        <li className="discover-section-price-cards-list-item">Espace client personnalisé</li>
                        <li className="discover-section-price-cards-list-item">Service d'assistance</li>
                        <li className="discover-section-price-cards-list-item">Nombreux avantages partenaires<br/>dont GPS Geocoyote</li>
                    </ul>
                    <div className="discover-section-price-cards-button">
                        <Link to="/contact" className="solu-btn-outline">Je contacte le service commercial</Link>
                        <small>*simultanément en ligne</small>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default Prices;