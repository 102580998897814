import React, { useState, useEffect } from 'react';
import AccountDetails from '../components/profile/AccountDetails';
import CreateProduct from '../components/profile/CreateProduct';
import CreateWorksite from '../components/profile/CreateWorksite';
import Products from '../components/profile/Products';
import headerBackground from '../images/header-page.png';
import Loader from '../components/Loader';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../ReactApp';
import Messagerie from '../components/profile/Messagerie';
import Reservations from '../components/profile/Reservations';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Worksites from '../components/profile/Worksites';

function ProfilePage({ activeItem, setActiveItem }) {
  const location = useLocation();
  const { user, onLogout } = useAuth();
  const [block, setBlock] = useState((user && user.role) == 'user' ? <Worksites /> : <Reservations />);
  const [loadUser, setLoadUser] = useState(false);
  const [verif, setVerif] = useState(true);
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  useEffect(() => {
    if (location.state && location.state.blockToDisplay) {
      switch (location.state.blockToDisplay) {
        case 'products':
          setShowHeaderFooter(true);
          setBlock(<Products setBlockChild={setBlock} />)
          break;
        case 'messagerie':
          setShowHeaderFooter(false);
          setBlock(<Messagerie conv={location.state.conv} activeItem={activeItem} setActiveItem={setActiveItem}/>)
          break;
        case 'reservations':
          setShowHeaderFooter(true);
          setBlock(<Reservations />)
          break;
        case 'create_product':
          setShowHeaderFooter(true);
          setBlock(<CreateProduct />)
          break;
        case 'worksites':
          setShowHeaderFooter(true);
          setBlock(<Worksites setBlockChild={setBlock}/>)
          break;
        case 'create_worksite':
          setShowHeaderFooter(true);
          setBlock(<CreateWorksite setBlockChild={setBlock}/>)
          break;
        case 'account_details':
          setShowHeaderFooter(true);
          setBlock(<AccountDetails />)
          break;
        default:
          setShowHeaderFooter(true);
          if (user && user.role == 'user') {
            setBlock(<Worksites setBlockChild={setBlock}/>)
          } else {
            setBlock(<Products setBlockChild={setBlock} />)
          }
      }
    } else if (user) {
      user.role == 'user' ? setActiveItem('Mes annonces') : setActiveItem('Mes réservations')
    }
  }, [location.state]);

  useEffect(() => {
    setLoadUser(true);

    if (user && user.enabled == 0) {
      setVerif(false);
    }
  }, [user])

  return (
    <>
      {showHeaderFooter &&
        <>
          <Menu activeItem={activeItem} setActiveItem={setActiveItem} />

          {loadUser &&
            <>
              <div className='profile-top' style={{ backgroundImage: `url(` + headerBackground + `)` }}>
                <div className='mediumcontainer profile-top-content'>
                  <img className='profile-top-content-image' src='https://via.placeholder.com/90' />
                  <div className='profile-top-content-info'>
                    <p className='profile-top-content-info-name'>
                      Bonjour {user ? user.firstname : ''} !
                    </p>
                    <p className='profile-top-content-info-description'>Bienvenue sur votre profil -  {user ? user.role : ''}</p>
                  </div>
                </div>
              </div>
              {(verif) ? '' :
                (<div className='profile-verif'>
                  <p className='profile-verif-text'>Votre profil est en cours de vérification.</p>
                </div>)
              }
            </>
          }
        </>
      }

      {!user ? <Loader /> :
        <>
          {block}
        </>
      }

      {showHeaderFooter && <Footer />}
    </>
  );
}

export default ProfilePage;