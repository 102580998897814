import React, { useState } from 'react';
import { Formik, Field, Form  } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ImageUploading from 'react-images-uploading';
import BannerHeader from '../images/register/BANDEAU_INSCRIPTION.png';
import IconLoueur from '../images/register/INSCRIPTION_METTRE_CAMION_A_LOUER.png';
import IconUser from '../images/register/INSCRIPTION_CHERCHE_CAMION_A_LOUER.png';
import IconWarning from '../images/register/PICTO_ATTENTION.svg';
import { Col, Row } from 'react-bootstrap';
import Autocomplete from '../components/Autocomplete';
import AddFile from '../images/add-file.png';
import AddPicture from '../images/add-picture.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Le prénom est requis."),
  lastName: Yup.string().required("Le nom est requis."),
  password: Yup.string()
    .required('Veuillez saisir un mot de passe.')
    .min(6, 'Le mot de passe doit contenir au moins 6 caractères.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas.')
    .required('Veuillez confirmer votre mot de passe.'),
    mail: Yup.string()
    .email("Email invalide")
    .required("L'email est requis."),
  phone: Yup.string().required("Le téléphone est requis.").min(10, "Le téléphone doit comporter au moins 10 chiffres."),
});

function RegisterPage() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [emailExist, setEmailExist] = useState('');
  const [totalStep, setTotalStep] = useState(0);
  const [type, setType] = useState("");
  const [nbVehicles, setNbVehicles] = useState(0);
  const [offer, setOffer] = useState('Basic');
  const [subscription, setSubscription] = useState('user');
  const [showPassword, setShowPassword] = useState(false);

  function handleEditNbVehicle(event) {
    setNbVehicles(event.target.value)

    if (event.target.value <= 1) {
      setOffer('Basic')
      setSubscription('basic')
    } else if (event.target.value > 1 && event.target.value <= 3) {
      setOffer('Premium')
      setSubscription('premium')
    } else if (event.target.value > 3  && event.target.value <= 10) {
      setOffer('Exclusive')
      setSubscription('exclusive')
    } else if (event.target.value > 10) {
      setOffer('VIP')
      setSubscription('vip')
    }
  }

  function handleSelectType(profile) {
    setType(profile);

    if (profile === "utilisateur") {
      setTotalStep(5);
    } else {
      setTotalStep(6);
    }
  }

  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
      setImages(imageList);
  };

  const [kbis, setKbis] = useState([]);
  const maxNumberKbis = 69;
  const onChangeKbis = (kbisList, addUpdateIndex) => {
    setKbis(kbisList);
  };
  const [rib, setRib] = useState([]);
  const maxNumberRib = 69;
  const onChangeRib = (ribList, addUpdateIndex) => {
    setRib(ribList);
  };
  const [sepa, setSepa] = useState([]);
  const maxNumberSepa = 69;
  const onChangeSepa = (sepaList, addUpdateIndex) => {
    setSepa(sepaList);
  };

  return (
    <div className='register-form'>
      <Link to='/'>
        <img src={BannerHeader} alt="SoluSpots - La solution en béton" />
      </Link>

      <div className='register-form-content'>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            companyName: "",
            mail: "",
            password: '',
            confirmPassword: '',
            images: {},
            phone: "",
            cities: {},
            siren: "",
            subscription: "",
            kbis: {},
            sepa: {},
            rib: {}
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {

            values.type = type;
            values.coordinates = document.getElementsByName("cities")[0].getAttribute('coordinates');

            let data = new FormData();

            const images_to_send = new Array();
            images.map((image) => (
              images_to_send.push(image.file ? image.file : image)
            ));

            const kbis_to_send = new Array();
            kbis.map((kbis) => (
              kbis_to_send.push(kbis.file ? kbis.file : kbis)
            ));

            const rib_to_send = new Array();
            rib.map((rib) => (
              rib_to_send.push(rib.file ? rib.file : rib)
            ));

            const sepa_to_send = new Array();
            sepa.map((sepa) => (
              sepa_to_send.push(sepa.file ? sepa.file : sepa)
            ));

            Object.keys(values).map(function (key, index) {
                data.append(key, values[key])
            });

            data.append('images', images_to_send[0])
            data.append('kbis', kbis_to_send[0])
            data.append('rib', rib_to_send[0])
            data.append('sepa', sepa_to_send[0])

            data.append('subscription', subscription)

            let config = {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }

             axios.post(process.env.REACT_APP_API_URL + "/v1/user/create", data, config)
               .then((response) => {
                 if (response.status == 200) {
                  if (totalStep == 6) {
                    setStep(6);
                  } else {
                    setStep(5);
                  }

                   toast.success('Demande d\'inscription envoyée avec succés !', {
                     position: "top-center",
                     autoClose: 7000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                   });
                 } else {
                   toast.warning("Erreur lors de votre inscription.", {
                     position: "top-center",
                     autoClose: 7000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                   });
                 }
               });
          }}

        >
          {({ values, isValid, touched, errors }) => (
            <Form>
              <div className='register-form-content-items' style={{ display: step === 1 ? "block" : "none" }}>
                <Row>
                  <Col md={1} lg={3}></Col>
                  <Col md={10} lg={6}>
                    <div>
                      <h2>Inscription</h2>
                      <p>Créez votre profil et accédez à notre communauté dédiee au transport de<br />béton.</p>

                      <h3 className='register-form-content-text'>Parlez-nous de vous !</h3>

                      <div className='register-form-content-profile'>
                        <div onClick={() => handleSelectType('loueur')} className={`register-form-content-block type-block ${type === "loueur" ? "selected" : ""}`}>
                          <img className='type-block-image' src={IconLoueur} />
                          <p className='type-block-title green'>Je suis transporteur</p>
                          <p className='type-block-title'>Je mets mon véhicule<br />en location</p>
                        </div>
                        <div onClick={() => handleSelectType('utilisateur')} className={`register-form-content-block type-block ${type === "utilisateur" ? "selected" : ""}`}>
                          <img className='type-block-image' src={IconUser} />
                          <p className='type-block-title green'>Je suis bétonnier</p>
                          <p className='type-block-title'>Je cherche un véhicule<br />pour le béton</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={1} lg={3}></Col>
                </Row>

                <div className='register-footer'>
                  <div className="register-progress-container hide-tablet hide-mobile">
                    {totalStep == 5 ?
                    <ul>
                      <li className='three-steps stepwithtruck'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                    </ul>
                    :
                      <ul>
                        <li className='five-steps stepwithtruck'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                      </ul>
                    }

                    {totalStep == 5 ? 
                      <ProgressBar className='register-progress-barre' now={(step / 6) * 100} />
                    : 
                      <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                    }
                  </div>

                  <div className="register-progress-mobile hide-desktop">
                    {totalStep == 5 ?
                      <ul>
                        <li className='three-steps active'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                      :
                        <ul>
                          <li className='five-steps active'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                        </ul>
                      }
                  </div>

                  <div className='register-button'>
                    <button className='register-button-back' type="button" onClick={() => navigate('/')}>Retour</button>
                    <button className='register-button-next' type="button"
                    onClick={() => { setStep(step + 1); }}
                    disabled={type === ""}>
                      Suivant
                    </button>
                  </div>
                </div>
              </div>

              <div className='register-form-content-items' style={{ display: step === 2 ? "block" : "none" }}>
                <Row>
                  <Col md={1} lg={3}></Col>
                  <Col md={10} lg={6}>
                    <div>
                      <h2>Complétez votre profil</h2>
                      <div className='register-form-content-items-warningblock'>
                        <img src={IconWarning} />
                        <p>Pensez à vous munir des documents suivants avant de commencer votre inscription : Kbis, rib.</p>
                      </div>

                      <Row className='gy-2'>
                        <Col md={6}>
                          <Field name="lastName">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Nom *</label>
                                <input type="text" {...field} />
                                {touched.lastName && errors.lastName && <div className='error'>{errors.lastName}</div>}
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}>
                          <Field name="firstName">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Prénom *</label>
                                <input type="text" {...field} />
                                {touched.firstName && errors.firstName && <div className='error'>{errors.firstName}</div>}
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}>
                          <Field name="password">
                            {({ field, form }) => (
                              <>
                                <div className='register-form-field'>
                                  <label>Mot de passe *</label>
                                  <div className='register-form-field-password'>
                                    <input type={showPassword ? 'text' : 'password'} {...field} />
                                    <button className='button-hide-password' type="button" onClick={() => setShowPassword(!showPassword)}>
                                      {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </button>
                                  </div>
                                  {touched.password && errors.password && <div className='error'>{errors.password}</div>}
                                </div>
                              </>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}>
                          <Field name="confirmPassword">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Confirmation de mot de passe *</label>
                                <div className='register-form-field-password'>
                                  <input type={showPassword ? 'text' : 'password'}{...field} />
                                  <button className='button-hide-password' type="button" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                  </button>
                                </div>
                                {touched.confirmPassword && errors.confirmPassword && <div className='error'>{errors.confirmPassword}</div>}
                              </div>
                            )}
                          </Field>
                        </Col>

                        {type == "loueur" ?
                          <>
                            <Col md={6}>
                              <Field name="companyName">
                                {({ field, form }) => (
                                  <div className='register-form-field'>
                                    <label>Nom de l'entreprise</label>
                                    <input type="text" {...field} />
                                    {touched.companyName && errors.companyName && <div className='error'>{errors.companyName}</div>}
                                  </div>
                                )}
                              </Field>
                            </Col>
                            <Col md={6}>
                            </Col>
                          </>
                          : ''}

                        <Col md={6}>
                          <Field name="mail">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Mail *</label>
                                <input type="email" {...field} />
                                {touched.mail && errors.mail && <div className='error'>{errors.mail}</div>}
                                {emailExist == true && <div className='error'>Un compte existe déjà avec cet e-mail. <a href="/connexion">Connectez-vous.</a></div>}
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}>
                          <Field name="phone">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Téléphone *</label>
                                <input type="text" {...field} />
                                {touched.phone && errors.phone && <div className='error'>{errors.phone}</div>}
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col>
                          <Field name="images">
                            {({ form, field }) => (
                              <div className='register-form-field'>
                                <label htmlFor="images">Ajouter une photo de profil</label>
                                <ImageUploading
                                  multiple
                                  value={images}
                                  onChange={onChange}
                                  maxNumber={maxNumber}
                                  dataURLKey="data_url"
                                  className='location-form-upload'
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps
                                    }) => (
                                        // write your building UI
                                        <div className="register-form-field-image">
                                            {imageList.length > 0 ?
                                                ''
                                              :
                                                <>
                                                  <button
                                                      className='btnActionUpload'
                                                      type="button" style={isDragging ? { color: "red" } : null}
                                                      onClick={onImageUpload}
                                                      {...dragProps}
                                                      >
                                                      <img src={AddPicture} alt="" width="50" />
                                                  </button>
                                                  <p>Cela peut-être le logo de votre entreprise.</p>
                                                </>
                                              }
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image.data_url ? image.data_url : process.env.REACT_APP_API_URL + image} alt="" />
                                                    <div className="image-item__btn-wrapper">
                                                        <button className='btn btn-outline-secondary btn-sm' type="button" onClick={() => onImageUpdate(index)}>Modifier</button>
                                                        <button className='btn btn-outline-danger btn-sm' type="button" onClick={() => onImageRemove(index)}>Supprimer</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ImageUploading>
                              </div>
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={1} lg={3}></Col>
                </Row>

                <div className='register-footer'>
                  <div className="register-progress-container hide-tablet hide-mobile">
                    {totalStep == 5 ?
                      <ul>
                        <li className='three-steps'></li>
                        <li className='three-steps stepwithtruck'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                      :
                        <ul>
                          <li className='five-steps'></li>
                          <li className='five-steps stepwithtruck'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                        </ul>
                      }

                      {totalStep == 5 ? 
                        <ProgressBar className='register-progress-barre' now={(step / 5) * 100} />
                      : 
                        <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                      }
                    </div>

                    <div className="register-progress-mobile hide-desktop">
                      {totalStep == 5 ?
                        <ul>
                          <li className='three-steps'></li>
                          <li className='three-steps active'></li>
                          <li className='three-steps'></li>
                          <li className='three-steps'></li>
                          <li className='three-steps'></li>
                        </ul>
                        :
                          <ul>
                            <li className='five-steps'></li>
                            <li className='five-steps active'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                          </ul>
                        }
                    </div>

                    <div className='register-button'>
                    <button className='register-button-back' type="button" onClick={() => {
                      setStep(step - 1);
                    }}>Retour</button>
                    <button className='register-button-next' type="button"
                      onClick={async (event) => {
                        try {
                          const response = await axios.post(process.env.REACT_APP_API_URL + "/v1/user/check-email", {
                            email: values.mail
                          });

                          if (response.data && response.data == 'exist') {
                            setEmailExist(true);
                          } else {
                            setEmailExist(false);
                            
                            if (isValid && values.lastName !== '' && values.firstName !== '' && values.phone !== '' && values.mail !== '') {
                              setStep(step + 1);
                            }
                          }

                        } catch (error) {
                          // Gérer les erreurs de l'appel API
                          console.error('Erreur lors de l\'appel API :', error);
                        }
                      }}
                      disabled={values.lastName === '' || values.firstName === '' || values.phone === '' || values.mail === ''}
                    >Suivant</button>
                    </div>
                  </div>
                </div>

              <div className='register-form-content-items' style={{ display: step === 3 ? "block" : "none" }}>
                <Row>
                  <Col md={1} lg={3}></Col>
                  <Col md={10} lg={6}>
                    <div>
                      <h2>Complétez votre profil</h2>

                      <Row className='gy-2'>
                        <Col xs={12}>
                          <div className='register-form-field'>
                            <label>Localisation *</label>
                            <span>Où est située votre entreprise ?</span>
                            <Autocomplete name="cities" className={(errors.cities && touched.cities ? 'is-invalid' : '')} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <Field name="siren">
                            {({ field, form }) => (
                              <div className='register-form-field'>
                                <label>Siren *</label>
                                <input type="text" name="siren" {...field} />
                              </div>
                            )}
                          </Field>
                        </Col>
                        <Col md={6}></Col>

                        <Col xs={12}>
                          <Field name="kbis">
                            {({ form, field }) => (
                              <div className='register-form-field'>
                                <label htmlFor="kbis">Extrait de Kbis *</label>
                                <ImageUploading
                                  multiple
                                  value={kbis}
                                  onChange={onChangeKbis}
                                  maxNumber={maxNumberKbis}
                                  dataURLKey="data_url"
                                  className='location-form-upload'
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps
                                    }) => (
                                        // write your building UI
                                        <div className="register-form-field-image">
                                            {imageList.length > 0 ?
                                                ''
                                              :
                                                <>
                                                  <button
                                                      className='btnActionUpload'
                                                      type="button" style={isDragging ? { color: "red" } : null}
                                                      onClick={onImageUpload}
                                                      {...dragProps}
                                                      >
                                                      <img src={AddFile} alt="" width="50" />
                                                  </button>
                                                  <p>Document de moins de 3 mois</p>
                                                </>
                                              }
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image.data_url ? image.data_url : process.env.REACT_APP_API_URL + image} alt="" />
                                                    <div className="image-item__btn-wrapper">
                                                        <button className='btn btn-outline-secondary btn-sm' type="button" onClick={() => onImageUpdate(index)}>Modifier</button>
                                                        <button className='btn btn-outline-danger btn-sm' type="button" onClick={() => onImageRemove(index)}>Supprimer</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ImageUploading>
                              </div>
                            )}
                          </Field>
                        </Col>

                        {totalStep != 5 &&
                          <Col xs={12}>
                            <div className='register-form-field register-form-field-nbvehicles'>
                              <label>Combien de véhicules avez-vous à louer ?</label>
                              <input type="number" min="0" name="nbVehicles" onChange={handleEditNbVehicle} />
                            </div>
                          </Col>
                        }

                        <div className='register-form-field-checkbox'>
                          <Field id="checkbox-agree" type="checkbox" name="conformitecheck" />
                          <label htmlFor="checkbox-agree">
                            En continuant mon inscription, je certifie la conformité de mes véhicules ainsi que de mes chauffeurs<br />
                            <a href="/charte" target="_blank">Lire la charte SoluSpots</a>
                          </label>
                        </div>
                      </Row>
                    </div>
                  </Col>
                  <Col md={1} lg={3}></Col>
                </Row>

                <div className='register-footer'>
                <div className="register-progress-container hide-tablet hide-mobile">
                    {totalStep == 5 ?
                    <ul>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps stepwithtruck'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                    </ul>
                    :
                      <ul>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps stepwithtruck'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                      </ul>
                    }
                    {totalStep == 5 ? 
                      <ProgressBar className='register-progress-barre' now={(step / 5) * 100} />
                    : 
                      <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                    }
                  </div>

                  <div className="register-progress-mobile hide-desktop">
                    {totalStep == 5 ?
                      <ul>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps active'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                      :
                        <ul>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps active'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                        </ul>
                      }
                  </div>

                  <div className='register-button'>
                    <button className='register-button-back' type="button" onClick={() => {
                      setStep(step - 1);
                    }}>Retour</button>
                    <button className='register-button-next' type="button" onClick={() => {
                      setStep(step + 1);
                    }} disabled={!isValid || values.cities == '' || values.siren == '' || !values.conformitecheck || kbis.length == 0}>
                        Suivant
                      </button>
                  </div>
                </div>
              </div>

              <div className='register-form-content-items' style={{ display: step === 4 && totalStep != 5 ? "block" : "none" }}>
                <Row>
                  <Col md={12} lg={12}>
                      <h2>Abonnement</h2>
                      <p>SoluSpots vous propose 4 offres. Pour {nbVehicles} véhicules à louer, l'offre {offer} est la plus adaptée pour vous. <br/> Celle-ci est modifiable à tout moment dans votre espace personnel.</p>

                      <Row className="mt-5" style={{alignContent: "stretch"}}>
                        <Col sm={12} md={6} xl={3} className="mb-4">
                          <div className={subscription === 'basic' ? "register-price-cards register-price-cards-active" : "register-price-cards"}>
                            <h2 className="register-price-cards-title">Basic</h2>
                            <hr className="register-price-cards-separator"/>
                            <p className="register-price-cards-number"><span
                                className="register-price-cards-number-special">149 €</span> <br/> /mois</p>
                            <p className="register-price-cards-promo"><span
                                className="register-price-cards-promo-special">99 €</span> <br/> /mois</p>
                            <ul className="register-price-cards-list">
                              <li className="register-price-cards-list-item">1 véhicule maximum</li>
                              <li className="register-price-cards-list-item">Espace client personnalisé</li>
                              <li className="register-price-cards-list-item">Service d'assistance</li>
                              <br/>
                              <br/>
                            </ul>
                            <div className="register-price-cards-button">
                                {subscription === 'basic' ? 
                                  <Link to="#" className="register-button-promo-selected">Offre sélectionnée</Link>
                                  : 
                                  <Link onClick={() => setSubscription('basic')} className="register-button-promo">Je choisis cette offre</Link>
                                }
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6} xl={3} className="mb-4">
                          <div className={subscription === 'premium' ? "register-price-cards register-price-cards-active" : "register-price-cards"}>
                            <h2 className="register-price-cards-title">Premium</h2>
                            <hr className="register-price-cards-separator register-price-cards-separator-active"/>
                            <p className="register-price-cards-number"><span
                                className="register-price-cards-number-special">249 €</span> <br/> /mois</p>
                            <p className="register-price-cards-promo"><span
                                className="register-price-cards-promo-special">199 €</span> <br/> /mois</p>
                            <ul className="register-price-cards-list">
                              <li className="register-price-cards-list-item">3 véhicules maximum</li>
                              <li className="register-price-cards-list-item">Espace client personnalisé</li>
                              <li className="register-price-cards-list-item">Service d'assistance</li>
                              <li className="register-price-cards-list-item">Avantage partenaire avec GPS Geocoyote</li>
                            </ul>
                            <div className="register-price-cards-button">
                              {subscription === 'premium' ? 
                                <Link to="#" className="register-button-promo-selected">Offre sélectionnée</Link>
                                : 
                                <Link onClick={() => setSubscription('premium')} className="register-button-promo">Je choisis cette offre</Link>
                              }
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6} xl={3} className="mb-4">
                          <div className={subscription === 'exclusive' ? "register-price-cards register-price-cards-active" : "register-price-cards"}>
                            <h2 className="register-price-cards-title">Exclusive</h2>
                            <hr className="register-price-cards-separator"/>
                            <p className="register-price-cards-number"><span
                                className="register-price-cards-number-special">349 €</span> <br/> /mois</p>
                            <p className="register-price-cards-promo"><span
                                className="register-price-cards-promo-special">299 €</span> <br/> /mois</p>
                            <ul className="register-price-cards-list">
                              <li className="register-price-cards-list-item">6 véhicules maximum</li>
                              <li className="register-price-cards-list-item">Espace client personnalisé</li>
                              <li className="register-price-cards-list-item">Service d'assistance</li>
                              <li className="register-price-cards-list-item">Nombreux avantages partenaires dont GPS Geocoyote</li>
                            </ul>
                            <div className="register-price-cards-button">
                                {subscription === 'exclusive' ? 
                                  <Link to="#" className="register-button-promo-selected">Offre sélectionnée</Link>
                                  : 
                                  <Link onClick={() => setSubscription('exclusive')} className="register-button-promo">Je choisis cette offre</Link>
                                }
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6} xl={3} className="mb-4">
                          <div className={subscription === 'vip' ? "register-price-cards register-price-cards-active" : "register-price-cards"}>
                            <br/>
                            <p class="discover-section-price-cards-number" style={{fontSize: 21}}>Au delà de 6 véhicules,<br/>contacter le service commercial.</p>
                            <ul className="register-price-cards-list">
                              <li className="register-price-cards-list-item">Véhicules illimité</li>
                              <li className="register-price-cards-list-item">Espace client personnalisé</li>
                              <li className="register-price-cards-list-item">Service d'assistance</li>
                              <li className="register-price-cards-list-item">Nombreux avantages partenaires dont GPS Geocoyote</li>
                            </ul>
                            <div className="register-price-cards-button">
                                {subscription === 'vip' ? 
                                  <Link to="#" className="register-button-promo-selected">Offre sélectionnée</Link>
                                  : 
                                  <Link to="/contact" className="register-button-promo">Contacter le service commercial</Link>
                                }
                            </div>
                          </div>
                        </Col>
                      </Row>
                  </Col>
                </Row>

                <div className='register-footer'>
                  <div className="register-progress-container hide-tablet hide-mobile">
                      {totalStep == 5 ?
                      <ul>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps stepwithtruck'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                      </ul>
                      :
                        <ul>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps stepwithtruck'></li>
                          <li className='five-steps'></li>
                          <li className='five-steps'></li>
                        </ul>
                      }
                      <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                    </div>

                    <div className="register-progress-mobile hide-desktop">
                      {totalStep == 5 ?
                        <ul>
                          <li className='three-steps'></li>
                          <li className='three-steps'></li>
                          <li className='three-steps active'></li>
                          <li className='three-steps'></li>
                          <li className='three-steps'></li>
                        </ul>
                        :
                          <ul>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps active'></li>
                            <li className='five-steps'></li>
                            <li className='five-steps'></li>
                          </ul>
                        }
                      </div>

                  <div className='register-button'>
                    <button className='register-button-back' type="button" onClick={() => {
                      setStep(step - 1);
                    }} disabled={!isValid}>Retour</button>
                    <button className='register-button-next' type="button" onClick={() => {
                      setStep(step + 1);
                    }} disabled={!isValid}>Suivant</button>
                  </div>
                </div>
              </div>
              <div className='register-form-content-items' style={{ 
                display: step === 4 && totalStep == 5 ||
                step === 5 && totalStep != 5
                ? "block" : "none" }}>

                <Row>
                  <Col md={1} lg={3}></Col>
                  <Col md={10} lg={6}>
                    <div className='register-container-allcenter'>
                      <h2>Dernière étape !</h2>
                      <p>Transmettez-nous vos coordonnées bancaires afin que votre abonnement commence et que vous puissiez profiter au plus vite des avantages de SoluSpots.</p>
                      <Row className='gy-2'>

                        <Col md={6}>
                          <Field name="rib">
                            {({ form, field }) => (
                              <div className='register-form-field'>
                                <ImageUploading
                                  multiple
                                  value={rib}
                                  onChange={onChangeRib}
                                  maxNumber={maxNumberRib}
                                  dataURLKey="data_url"
                                  className='location-form-upload'
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps
                                    }) => (
                                        // write your building UI
                                        <div className="register-form-field-image">
                                            {imageList.length > 0 ?
                                                ''
                                              :
                                                <>
                                                  <button
                                                      className='btnActionUpload'
                                                      type="button" style={isDragging ? { color: "red" } : null}
                                                      onClick={onImageUpload}
                                                      {...dragProps}
                                                      >
                                                      <img src={AddFile} alt="" width="50" />
                                                  </button>
                                                  <p>RIB*<br/>Document de moins de 3 mois</p>
                                                </>
                                              }
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image.data_url ? image.data_url : process.env.REACT_APP_API_URL + image} alt="" />
                                                    <div className="image-item__btn-wrapper">
                                                        <button className='btn btn-outline-secondary btn-sm' type="button" onClick={() => onImageUpdate(index)}>Modifier</button>
                                                        <button className='btn btn-outline-danger btn-sm' type="button" onClick={() => onImageRemove(index)}>Supprimer</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ImageUploading>
                              </div>
                            )}
                          </Field>
                        </Col>

                        <Col md={6}>
                          <Field name="sepa">
                            {({ form, field }) => (
                              <div className='register-form-field'>
                                <ImageUploading
                                  multiple
                                  value={sepa}
                                  onChange={onChangeSepa}
                                  maxNumber={maxNumberSepa}
                                  dataURLKey="data_url"
                                  className='location-form-upload'
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps
                                    }) => (
                                        // write your building UI
                                        <div className="register-form-field-image">
                                            {imageList.length > 0 ?
                                                ''
                                              :
                                                <>
                                                  <button
                                                      className='btnActionUpload'
                                                      type="button" style={isDragging ? { color: "red" } : null}
                                                      onClick={onImageUpload}
                                                      {...dragProps}
                                                      >
                                                      <img src={AddFile} alt="" width="50" />
                                                  </button>
                                                  <p>SEPA*<br/>Document de moins de 3 mois</p>
                                                </>
                                              }
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image.data_url ? image.data_url : process.env.REACT_APP_API_URL + image} alt="" />
                                                    <div className="image-item__btn-wrapper">
                                                        <button className='btn btn-outline-secondary btn-sm' type="button" onClick={() => onImageUpdate(index)}>Modifier</button>
                                                        <button className='btn btn-outline-danger btn-sm' type="button" onClick={() => onImageRemove(index)}>Supprimer</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ImageUploading>
                              </div>
                            )}
                          </Field>
                        </Col>

                      </Row>

                      <div className='register-form-field-checkbox'>
                          <Field id="checkbox-agree" type="checkbox" name="conformitecgv" />
                          <label htmlFor="conformitecgv">
                            J’accepte les <a href="/conditions-generales-de-vente" target="_blank">Conditions Générales de Vente</a> de SoluSpots
                          </label>
                        </div>
                    </div>
                  </Col>
                  <Col md={1} lg={3}></Col>
                </Row>

                <div className='register-footer'>
                <div className="register-progress-container hide-tablet hide-mobile">
                    {totalStep == 5 ?
                    <ul>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps'></li>
                      <li className='three-steps stepwithtruck'></li>
                      <li className='three-steps'></li>
                    </ul>
                    :
                      <ul>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps stepwithtruck'></li>
                        <li className='five-steps'></li>
                      </ul>
                    }
                    {totalStep == 5 ? 
                      <ProgressBar className='register-progress-barre' now={(step / 5) * 100} />
                    : 
                      <ProgressBar className='register-progress-barre' now={(step / totalStep) * 100} />
                    }
                  </div>

                  <div className="register-progress-mobile hide-desktop">
                    {totalStep == 5 ?
                      <ul>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps'></li>
                        <li className='three-steps active'></li>
                        <li className='three-steps'></li>
                      </ul>
                      :
                      <ul>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps'></li>
                        <li className='five-steps active'></li>
                        <li className='five-steps'></li>
                      </ul>
                    }
                  </div>

                  <div className='register-button'>
                    <button className='register-button-back' type="button" onClick={() => {
                      setStep(totalStep == 5 ? step - 2 : step - 1);
                    }} disabled={!isValid}>Retour</button>
                    <button className='register-button-next' type="submit"
                      disabled={!values.conformitecgv || rib.length == 0 || sepa.length == 0}>Valider mon inscription</button>
                  </div>
                </div>
              </div>

              {totalStep == 6 ?
                <div className='register-form-content-items' style={{ display: step === 6 ? "block" : "none" }}>

                  <Row>
                    <Col md={1} lg={3}></Col>
                    <Col md={10} lg={6}>
                      <div className='register-final'>
                        <h2>C'est tout bon !</h2>
                        <img className='type-block-image' src={IconLoueur} />
                        <p><span>Connectez-vous pour mettre vos camions en ligne !</span><br/><small>Votre compte est créé mais doit encore être vérifié par l’équipe Soluspots. Vos camions ajoutés seront enregistrés en brouillon et mis en ligne automatiquement une fois votre compte vérifié.</small></p>
                        <button className='btn btn-primary' onClick={() => navigate('/connexion')}>Je me connecte</button>
                      </div>
                    </Col>
                    <Col md={1} lg={3}></Col>
                  </Row>
                </div>
              :
                <div className='register-form-content-items' style={{ display: step === 5 ? "block" : "none" }}>
                  <Row>
                    <Col md={1} lg={3}></Col>
                    <Col md={10} lg={6}>
                      <div className='register-final'>
                        <h2>C'est tout bon !</h2>
                        <img className='type-block-image' src={IconLoueur} />
                        <p><span>Connectez-vous pour trouver vos camions !</span><br/><small>Votre compte est créé mais doit encore être vérifié par l’équipe Soluspots. Si vous saisissez des annonces pour vos centrales, celles-ci seront enregistrées en brouillon et mises en ligne automatiquement une fois votre compte vérifié.</small></p>
                        <button className='btn btn-primary' onClick={() => navigate('/connexion')}>Je me connecte</button>
                      </div>
                    </Col>
                    <Col md={1} lg={3}></Col>
                  </Row>
                </div>
              }
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default RegisterPage;
