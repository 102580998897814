import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from 'react'
import Loader from "../components/Loader";
import { useAuth } from '../ReactApp';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Accordion, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { getDistance } from "../services/CalculDistance";
import axios from 'axios';
import Banner from "../components/Banner";
import ProductList from "../components/cards/ProductList";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { DateRange } from "react-date-range";
import locale from 'date-fns/locale/fr';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from 'moment';
import Autocomplete from "../components/Autocomplete";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders } from '@fortawesome/free-solid-svg-icons'
import btnClose from '../images/btn-close.jpg';

const ResultPage = () => {

    return (
        <>
            <div className="container">
                <h1 className="contact-title">
                    Charte <span className="contact-title-colored">SoluSpots</span>
                </h1>

                <p>La présente charte éthique établit les engagements mutuels entre SoluSpots.fr et ses clients transporteurs et bétonniers.<br/>Elle vise à promouvoir des relations commerciales respectueuses, équitables et transparentes dans le cadre de notre plateforme de mise en relation pour la location de camions pour le béton.</p>

                <h3>Engagements des Clients Transporteurs :</h3>
                <p>1) Respecter les termes et conditions de la plateforme ainsi que les accords conclus entre les différents acteurs du secteur.</p>
                <p>2) Fournir des informations exactes, complètes et à jour concernant les véhicules, les disponibilités et les conditions de location.</p>
                <p>3) Répondre rapidement aux demandes de réservation et aux communications des bétonniers.</p>
                <p>4) Assurer la sécurité des conducteurs, des véhicules et des chargements lors des opérations de transport de béton.</p>

                <h3>Engagements des Bétonniers :</h3>
                <p>1) Utiliser la plateforme de manière honnête et conforme à son objectif de mise en relation pour la location de camions pour le béton.</p>
                <p>2) Respecter les conditions de réservation et de paiement convenues avec les transporteurs.</p>
                <p>3) Communiquer de manière claire et précise les besoins en termes de volume, de calendrier et de spécifications du béton à transporter.</p>
                <p>4) Payer les services de transport conformément aux accords conclus avec les transporteurs, dans les délais convenus.</p>
                
                <h3>Engagements de SoluSpots :</h3>
                <p>1) Assurer la confidentialité des informations fournies par les clients transporteurs et les bétonniers.</p>
                <p>2) Fournir une plateforme conviviale et sécurisée pour faciliter la mise en relation et la gestion des réservations.</p>
                <p>3) Agir de manière impartiale et équitable dans le traitement des demandes et des litiges entre les clients transporteurs et les bétonniers.</p>
                <p>4) Promouvoir des pratiques commerciales transparentes et éthiques dans toutes nos interactions avec nos clients.</p>

                <h3>Engagements de SoluSpots :</h3>
                <p>Tous les clients transporteurs et bétonniers s'engagent à respecter les principes énoncés dans cette charte éthique.</p>
            </div>
        </>
    );
};

export default ResultPage;
