
// The translation should be handled by the clients of this package.
// The client should pass the translated `messages` to this package.
// The package simply prints the text by using the `keys` of the `messages`
export const labels = {
    placeholder: "Écrivez votre message ici ...",
    disabledPlaceholder: "",
    clearFiles: "Tout supprimer",
    everyone: "Tout le monde",
    to: "À",
    private: "private"
};