import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

//Icons + css custom
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Autocomplete from "./Autocomplete";

//Gestion des dates
import {DateRange} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {format} from "date-fns";
import locale from 'date-fns/locale/fr';
import {useAxios} from './tools/useAxios';

const SearchFilters = () => {
    const navigate = useNavigate();

    //Sélection des catégories de produit
    const [loading, categoriesProductOptions] = useAxios(process.env.REACT_APP_API_URL + "/v1/categories", 'post');

    //Sélection de la date
    const [date, setDate] = useState([
        {
          startDate: new Date(),
          endDate: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000),
          key: "selection",
        },
      ]);

    //Boolean pour savoir si le calendrier est ouvert
    const [openDate, setOpenDate] = useState(false);
    const [openDate2, setOpenDate2] = useState(false);

    //Permet de fermer le calendrier lorsque l'on clique sur la page
    document.body.addEventListener('click', (e) => {
        let container = document.getElementsByClassName('rdrCalendarWrapper');

        if (container[0] && !container[0].contains(e.target)) {
            if (openDate) {
                setOpenDate(false);
            }

            if (openDate2) {
                setOpenDate2(false);
            }
        }
    }, true);

    //Tableau qui contient l'id, le nom et l'image de la categ pour l'autocomplete
    let categoriesNamesWithImg = [];
    categoriesNamesWithImg.push([0, "#", 'Un transporteur'])
    categoriesNamesWithImg.push([1, "#", 'Une centrale'])
    categoriesNamesWithImg.push([2, "#", 'Un chantier'])
    // categoriesProductOptions.map((item, index) =>
    //     categoriesNamesWithImg.push([item.id, item.image, item.name])
    // );

    const handleSearch = (event) => {
        event.preventDefault();

        let cityCoordinates = event.target.cities.getAttribute('coordinates').split(",");
        let selectedCity = event.target.cities.value;
        let categoryProduct = event.target.categories.value;
        let dataId = event.target.categories.getAttribute('data-id'); // ID pour catégorie et Longitude,Latitude pour la ville

        if (categoryProduct == "Un transporteur") {
            navigate("/resultat", { state: { date, dataId, categoryProduct, selectedCity, cityCoordinates } });
        } else {
            navigate("/resultat-chantier", { state: { date, dataId, categoryProduct, selectedCity, cityCoordinates } });
        }
    };

    const openFilters = (event) => {
        event.preventDefault();

        const formMobile = document.querySelector('.searchfilters-form-mobile');
        formMobile.style.right = '0';
    }

    const closeFilters = (event) => {
        event.preventDefault();

        const formMobile = document.querySelector('.searchfilters-form-mobile');
        formMobile.style.right = '-100%';
    }

    return (
        <>
            <form className="searchfilters-form searchfilters-form-base hide-mobile hide-tablet" onSubmit={handleSearch}>
                {/* <div className="searchfilters-item">
                    <span className="searchfilters-item-title-primary">Je recherche</span>
                </div> */}
                {/* Selection du type de produit */}
                <div className="searchfilters-item">
                    <p className="searchfilters-item-title">Je recherche</p>

                    <Autocomplete
                        suggestions={categoriesNamesWithImg}
                        name="categories"
                    />
                </div>

                <div className="searchfilters-separator" />

                {/* Selection des villes */}
                <div className="searchfilters-item">
                    <p className="searchfilters-item-title">Où ?</p>

                    <Autocomplete
                        name="cities"
                    />
                </div>

                <div className="searchfilters-separator" />

                {/* Selection de la date */}
                <div className="searchfilters-item">
                    <p className="searchfilters-item-title">Quand ?</p>

                    <span
                    onClick={() => setOpenDate(!openDate)}
                    className="headerSearchText"
                    >
                        {/* Quand ? */}
                        {`Du ${format(date[0].startDate, "dd/MM/yy")} au ${format(date[0].endDate,"dd/MM/yy")}`}
                    </span>
                    {openDate && (
                    <DateRange
                        editableDateInputs={true}
                        onChange={
                            (item) => setDate([item.selection])
                        }
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        locale={locale}
                        className="date"
                        minDate={new Date()}
                    />
                    )}
                </div>

                {/* Bouton de soumission du formulaire*/}
                <button type="submit" className="searchfilters-btn">
                    <FontAwesomeIcon icon={faSearch} />
                </button>
            </form>

            <form className="searchfilters-form searchfilters-form-base hide-desktop" onSubmit={handleSearch}>
                {/* Selection du type de produit */}
                <div className="searchfilters-item mobile" onClick={openFilters}>
                    <p className="searchfilters-item-title">Recherche</p>
                    <span className="searchfilters-item-text">Un camion, un chantier ? Où ? Quand ?</span>
                </div>

                {/* Bouton de soumission du formulaire*/}
                <button type="submit" className="searchfilters-btn">
                    <FontAwesomeIcon icon={faSearch} />
                </button>

                <div className="searchfilters-form searchfilters-form-mobile">
                    <div className="py-4">
                        <div className="cross" onClick={closeFilters}></div>
                        <p className="searchfilters-item-title text-center">Recherche</p>
                    </div>

                    <div id="inputQuoi" className="searchfilters-item open">
                        <p className="searchfilters-item-title">Je recherche</p>

                        <Autocomplete
                            suggestions={categoriesNamesWithImg}
                            name="categories"
                        />
                    </div>

                    <div id="inputOu" className="searchfilters-item open my-5">
                        <p className="searchfilters-item-title">Où ?</p>

                        <Autocomplete
                            name="cities"
                        />
                    </div>

                    <div id="inputQUAND" className="searchfilters-item open">
                        <p className="searchfilters-item-title">Quand</p>

                        <span onClick={() => setOpenDate2(!openDate2)}
                            className="headerSearchText"
                            >
                                {/* Quand ? */}
                                {`Du ${format(date[0].startDate, "dd/MM/yy")} au ${format(date[0].endDate,"dd/MM/yy")}`}
                            </span>
                            {openDate2 && (
                            <DateRange
                                editableDateInputs={true}
                                onChange={
                                    (item) => setDate([item.selection])
                                }
                                moveRangeOnFirstSelection={false}
                                ranges={date}
                                locale={locale}
                                className="date"
                                minDate={new Date()}
                            />
                            )}
                    </div>

                    <div id="">
                        <button type="submit" className="searchfilters-form-mobile-btn">Rechercher</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default SearchFilters;
