import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import 'leaflet/dist/leaflet.css';

import TruckForm from './TruckForm';


function LocationForm(props) {

    return (
        <div className="create-product">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Mes camions - Ajouter un camion</h2>

                        <TruckForm
                            initialValues={{
                                name: '',
                                price: '',
                                images: {},
                                docs: {},
                                cities: {},
                                dates: {},
                                enabled: ''
                            }}
                            validationSchema={Yup.object().shape({
                                price: Yup.number()
                                    .required('Le prix est requis.'),
                                fuel: Yup.string()
                                    .required('Le carburant est requis.'),
                                brand: Yup.string()
                                    .required('La marque est requise.'),
                                category: Yup.string()
                                    .required('La catégorie est requise.'),
                                cities: Yup.object()
                                    .required('La ville du produit est requise.'),
                                registration: Yup.string()
                                    .required('La plaque d\'immatriculation est requise.'),
                            })}
                            props={props}
                            isCreate={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LocationForm;
