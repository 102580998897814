import axios from 'axios';
import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';
import { Calendar } from 'react-multi-date-picker';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../ReactApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

function ReservationRow(props) {
    const {user} = useAuth();
    const navigate = useNavigate();

    useEffect(()=> {
        ReactModal.setAppElement('#root');
    }, [])

    const [datesSorted, setDatesSorted] = useState(false);
    
    useEffect(()=> {
        afficher_dates_consecutives(props);
    }, [props])

    const afficher_dates_consecutives = (props) => {
        let result = "";

        const dates = props.item.dates;
        const nbDates = dates.length;
        dates.sort(); // Tri pour faciliter le parcours
        let dateStart = dates[0];
        let dateEnd = dates[0];

        for (let i = 1; i < nbDates; i++) {
          if (dates[i] === new Date(new Date(dateEnd).setDate(new Date(dateEnd).getDate() + 1)).toISOString().substring(0, 10)) {
            dateEnd = dates[i];
          } else {
            if (dateStart === dateEnd) {
                const formattedDateStart = new Date(dateStart);
                result += formattedDateStart.toLocaleDateString("fr") + ", ";
            } else {
                const formattedDateStart = new Date(dateStart);
                const formattedDateEnd = new Date(dateEnd);
                result += "Du " + formattedDateStart.toLocaleDateString("fr") + " au " + formattedDateEnd.toLocaleDateString("fr") + ", ";
            }
            dateStart = dates[i];
            dateEnd = dates[i];
          }
        }

        if (dateStart === dateEnd) {
            const formattedDateStart = new Date(dateStart);
            result += formattedDateStart.toLocaleDateString("fr") + ", ";
        } else {
            const formattedDateStart = new Date(dateStart);
            const formattedDateEnd = new Date(dateEnd);
            result += "Du " + formattedDateStart.toLocaleDateString("fr") + " au " + formattedDateEnd.toLocaleDateString("fr") + ", ";
        }

        if (result.endsWith(', ')) {
            result = result.slice(0, -2); // Suppression de la virgule et de l'espace
        }

        const date = new Date();
        const formattedDateStart = new Date(props.item.dates[0]);
        const formattedDateEnd = new Date(dateEnd);

        if (date.toLocaleDateString("fr") >= formattedDateStart.toLocaleDateString("fr") &&
                date.toLocaleDateString("fr") <= formattedDateEnd.toLocaleDateString("fr")) {
            props.item.status = 'in-progress';
        }

        setDatesSorted(result);
    }

    const handleCreateConversation = (resa, user) => {
        let params = {
            'id_reservation' : resa.id,
            'id_user' : user._id
        };

        axios.post(process.env.REACT_APP_API_URL + "/v1/create-conversation", params)
        .then((response) => {
            if (response.data) {
                let blockToDisplay = 'messagerie';
                navigate("/profil", { state: { blockToDisplay: blockToDisplay } });
            }
        }).catch((error) => {
           console.log(error);
        })
    }

    return (
        <>
        {(props.item.status === 'accepted' || props.item.status === 'in-progress') && 
                <tr>
                    <td>{props.item.customer}</td>
                    <td>
                        {datesSorted}
                    </td>
                    {props.item.price_offer != null ?
                        <td>{props.item.price_offer}€ <strike>{props.item.price * props.item.dates.length}€</strike></td>
                        :
                        <td>{props.item.price * props.item.dates.length}€</td>
                    }

                    <td><div className={'btn-statut btn-statut-' + (props.item.status == 'in-progress' ? 'inprogress' : props.item.status)}>
                    {(() => {
                        switch (props.item.status) {
                            case 'accepted':
                            return 'À venir';
                            case 'refused':
                            return 'Refusé';
                            case'in-progress':
                            return 'En cours';
                            default:
                            return 'Statut inconnu';
                        }
                    })()}
                    </div></td>

                    <td>
                        <button className="btn btn-message" onClick={() => handleCreateConversation(props.item, user)}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </button>
                    </td>
                </tr>
            }
            </>
    );
}

export default ReservationRow;
