import { useState, useEffect } from 'react';
import axios from 'axios';

export function useAxios(url, method='post', params={}) {
	const [state, setState] = useState({ loading: true,
                                         items: []
                                      })

    if (method === 'get') {
        url = url + '/' + params;
    }

    useEffect(() => {
        const loadItems = async () => {
            const options = {
                url,
                method: method,
                headers: { 'content-type': 'application/json'},
                data: params,
              };

            axios(options)
            .then((response) => {
                if (response.data) {
                    setState({
                        loading: false,
                        items: response.data
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
        }

        loadItems();
    }, []);

     return [
          state.loading,
          state.items
     ]
}
