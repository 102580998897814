import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import 'leaflet/dist/leaflet.css';
import WorksiteForm from './WorksiteForm';
import { useAuth } from '../../ReactApp';
import axios from 'axios';


function UpdateWorksite(props) {
    const {user} = useAuth();

    let initialData = { 
        name: props.worksite.name,
        trucks: props.worksite.trucks,
        dates: props.worksite.dates,
        description: props.worksite.description,
        images: props.worksite.image,
        cities: {},
    }

    useEffect(() => {
        if (props.worksite.longitude && props.worksite.latitude) {
            axios.get("https://api-adresse.data.gouv.fr/reverse/?lon=" + props.worksite.longitude + "&lat=" + props.worksite.latitude + "&type=municipality")
            .then((response) => {
                initialData = { cities: response.data.features[0].properties.label}
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [props.worksite])

    return (
        <>
            <div className="create-worksite">
                <div className="container mt-5">
                    <h2>Mes annonces - Modifier une annonce</h2>

                    <WorksiteForm
                        initialValues={initialData}
                        validationSchema={Yup.object().shape({})}
                        props={props}
                        setBlockChild={props.setBlockChild}
                        isCreate={false}
                    />
                </div>
            </div>
        </>
    );
}

export default UpdateWorksite;
